import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

export default function Processes() {
	const navigate = useNavigate();
	useEffect(() => {
		navigate("/ticketing/on-chain");
	}, [navigate]);
	return <></>;
}
