import { motion } from "framer-motion";

// icons
import { BsBuildingFillAdd } from "react-icons/bs";

// COMPONENTS
import ProcessType from "../../../../Components/Cards/ProcessType";

const processTypes = [
	{
		id: 1,
		type: "New Org",
		title: "New Organizations",
		description: "offChain process",
		icon: <BsBuildingFillAdd />,
		href: "new-organizations",
	},
];

export default function OffChain() {
	return (
		<motion.section
			initial={{ y: 10, opacity: 0.4, scale: 0.7 }}
			animate={{ y: 0, opacity: 1, scale: 1 }}
			transition={{ stiffness: 100 }}
			className="custom_container w-full overflow-hidden flex flex-col gap-4"
		>
			<div className="flex flex-col gap-1">
				<h1 className="text-xl font-semibold">Off-Chain Ticketing</h1>
				<p className="text-sm text-gray-500">
					Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit, labore?
				</p>
			</div>

			<div
				className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 md:gap-x-8 
            lg:gap-x-12 gap-y-4 md:gap-y-8 lg:gap-y-12"
			>
				{processTypes.map((process) => (
					<ProcessType
						key={process.id}
						icon={process.icon}
						type={process.type}
						title={process.title}
						description={process.description}
						href={process.href}
					/>
				))}
			</div>
		</motion.section>
	);
}
