import { useState } from "react";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { IoCheckmarkDone } from "react-icons/io5";
import { MdOutlinePending } from "react-icons/md";
import { MdOutlineTimer, MdKeyboardArrowDown } from "react-icons/md";
import ShowMore from "./ShowMore";

import { AnimatePresence } from "framer-motion";

export default function ProcessCard({ processBlock }) {
	const [showMore, setShowMore] = useState(false);

	const stepsLeft = processBlock.filter((block) => block.endDate !== "").length;
	const lastDoneStep = processBlock.findLastIndex(
		(block) => block.endDate !== "",
	);

	return (
		<div className="bg-gray-100 flex h-max flex-col rounded-lg border border-gray-100 overflow-hidden">
			{/* card header */}
			{showMore ? (
				<aside className="px-4 py-2 bg-primary text-sm rounded-lg text-white font-semibold text-center">
					process creator name
				</aside>
			) : (
				<aside className="flex justify-between gap-4 flex-wrap items-center px-4 py-2">
					<p className="text-sm font-semibold">process creator name</p>
					{processBlock.length - stepsLeft > 0 ? (
						<span className="px-4 py-1 rounded-lg bg-primary text-white text-xs font-semibold">
							{processBlock.length - stepsLeft} steps left
						</span>
					) : (
						<span className="px-4 py-1 rounded-lg bg-green-200 text-green-600 text-xs font-semibold">
							All Steps Has Done
						</span>
					)}
				</aside>
			)}

			{/* card body */}
			<div className="bg-white rounded-t-lg px-4 py-2 flex flex-col gap-4">
				{!showMore && (
					<>
						<div className="flex gap-4">
							<div className="flex flex-col gap-1 w-max">
								<div className="text-center leading-4">
									<p className="text-xs font-semibold">2023/12/12</p>
									<span className="text-[10px]">11:30</span>
								</div>
								<span
									className="bg-secondary text-green-100 px-2 py-1 text-xs font-semibold rounded-lg
                        flex items-center gap-1 text-center"
								>
									<IoIosCheckmarkCircleOutline className="text-sm" />
									Step {lastDoneStep + 1}
								</span>
							</div>

							<div className="flex flex-col gap-1 flex-grow self-center">
								<div className="flex items-center relative">
									<p
										className="absolute -top-4 inset-x-1/2 -translate-x-1/2 text-[10px] flex gap-3 items-center
                             w-max font-semibold"
									>
										1day, 20h, 12m
									</p>
									<span className="w-2 h-2 rounded-full bg-gray-200"></span>
									<hr className="border flex-grow" />
									<span className="w-2 h-2 rounded-full bg-gray-200"></span>
								</div>
							</div>

							<div className="flex flex-col gap-1 w-max">
								<div className="text-center leading-4">
									<p className="text-xs font-semibold">2023/12/12</p>
									<span className="text-[10px]">11:30</span>
								</div>

								{lastDoneStep + 1 === processBlock.length ? (
									<span className="text-secondary text-xl font-semibold w-max mx-auto">
										<IoCheckmarkDone />
									</span>
								) : (
									<span
										className="bg-gray-50 text-gray-500 px-2 py-1 text-xs font-semibold rounded-lg
		flex items-center gap-1 text-center"
									>
										<MdOutlinePending className="text-sm" />
										Step {lastDoneStep + 2}
									</span>
								)}
							</div>
						</div>

						<div className="flex flex-wrap gap-2 items-center">
							<span className="text-center px-2 py-1 rounded-lg text-[10px] border">
								# hashtag 1
							</span>
							<span className="text-center px-2 py-1 rounded-lg text-[10px] border">
								# hashtag 2
							</span>
							<span className="text-center px-2 py-1 rounded-lg text-[10px] border">
								# hashtag 3
							</span>
						</div>

						<div className="flex justify-between gap-4 flex-wrap items-center">
							<p className="flex items-center gap-1 text-system-error text-xs font-semibold">
								<MdOutlineTimer className="text-base" />
								it took a lot of time
							</p>

							<button
								onClick={() => setShowMore(true)}
								className="flex items-center justify-center gap-1 text-xs border px-2 py-1 rounded-lg"
							>
								show more <MdKeyboardArrowDown className="text-base" />
							</button>
						</div>
					</>
				)}

				<AnimatePresence>
					{/* steps */}
					{showMore && (
						<ShowMore setShowMore={setShowMore} processBlocks={processBlock} />
					)}
				</AnimatePresence>
			</div>
		</div>
	);
}
