// ICONS
import { MdAccountBalanceWallet } from "react-icons/md";
import { useGetBalanceQuery } from "../../app/services/api/governance/balance";
// COMPONENTS
import Loading from "../UiElements/Loading";
import ErrorCard from "./ErrorCard";
import { useSelector } from "react-redux";
import { currentUser } from "../../app/services/authSlice";

export default function TokenBalance({ name }) {
	const user = useSelector(currentUser);
	const { data, isLoading, isError, isSuccess, error } = useGetBalanceQuery(
		user?.username,
	);

	if (isError) return <ErrorCard error={error} />;

	return (
		<div
			className="glassmorphism bg-slate-50/30 rounded-lg shadow-sm border border-primary/20 shadow-primary/30 
		w-full h-auto p-2 flex flex-col gap-1"
		>
			{isLoading && <Loading />}

			{isSuccess && (
				<div>
					<p className="text-sm text-center">
						<span className="font-semibold text-xl">{data.balance}</span> GT
					</p>
					<div className="flex items-center gap-2 justify-center">
						<MdAccountBalanceWallet className="text-slate-400 text-2xl" />
						<span className="text-xs text-slate-400">{name}</span>
					</div>
				</div>
			)}
		</div>
	);
}
