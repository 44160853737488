// components
import SidebarItem from "../SidebarItem";
import Logo from "../Logo";

import { IoMdMoon } from "react-icons/io";
import { FaSignOutAlt } from "react-icons/fa";

// data
import {
	sidebarData__ADMIN,
	sidebarData__SUSA__ADMIN,
	sidebarData__USER,
} from "../../Data/SidebarItems";
import Switch from "../Switch";
import { currentUser } from "../../app/services/authSlice";
import { useSelector } from "react-redux";

import cookie from "js-cookie";

export default function Sidebar() {
	const user = useSelector(currentUser);
	return (
		<div
			className="w-16 flex flex-col lg:w-56 lg:p-2 min-h-screen overflow-hidden bg-white border-r-2 
		border-gray-100"
		>
			<Logo />

			<div className="lg:pr-4 pr-2 flex flex-col h-[500px] max-h-max overflow-y-scroll mb-4">
				<p className="text-muted text-xs text-center lg:text-start font-semibold mb-4">
					Menu
				</p>
				<div className="flex flex-col gap-2 pl-2 h-full overflow-y-scroll">
					{/* TODO: based on user.roles === "SusaAdmin"  */}
					{user?.roles === "SusaAdmin"
						? sidebarData__SUSA__ADMIN.map((item) => (
								<SidebarItem
									key={item.id}
									path={item.path}
									subTitle={item?.subTitle}
									icon={item.icon}
									title={item.title}
								/>
						  ))
						: user?.roles === "Admin"
						? sidebarData__ADMIN.map((item) => (
								<SidebarItem
									key={item.id}
									path={item.path}
									subTitle={item?.subTitle}
									icon={item.icon}
									title={item.title}
								/>
						  ))
						: sidebarData__USER.map((item) =>
								user ? (
									user.userKyc === "" ? (
										item.title !== "Forsale Offsets" && (
											<SidebarItem
												key={item.id}
												path={item.path}
												subTitle={item?.subTitle}
												icon={item.icon}
												title={item.title}
											/>
										)
									) : (
										<SidebarItem
											key={item.id}
											path={item.path}
											subTitle={item?.subTitle}
											icon={item.icon}
											title={item.title}
										/>
									)
								) : null,
						  )}
				</div>
			</div>

			<div className="justify-self-end mt-auto flex flex-col gap-1 p-2 text-[13px]">
				<p className="text-muted text-xs text-center lg:text-start font-semibold mb-4">
					Options
				</p>
				<div
					className="flex lg:justify-between justify-center p-2 -mt-2 hover:bg-gray-100 lg:hover:bg-transparent 
				rounded-lg cursor-pointer transition-all duration-300"
				>
					<div className="flex gap-2 items-center justify-center lg:justify-start">
						<IoMdMoon className="text-xl lg:text-base" />
						<span className="hidden lg:block">Dark Mode</span>
					</div>

					<div className="hidden lg:block">
						<Switch />
					</div>
				</div>

				<button
					onClick={() => {
						cookie.remove("accessToken");
						window.location.href = "https://susa6.com/login";
					}}
					type="button"
					className="flex items-center justify-center lg:justify-start gap-2 text-system-error
					 hover:bg-system-error/10 p-2 cursor-pointer rounded-lg"
				>
					<FaSignOutAlt className="lg:text-sm text-xl" />
					<span className="hidden lg:block">Sign Out</span>
				</button>
			</div>
		</div>
	);
}
