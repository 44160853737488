import { useFormik } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useLoginMutation } from "../app/services/api/auth";
import { currentUser, setCredentials } from "../app/services/authSlice";
// COMPONENT
import { toast } from "react-hot-toast";
import Button from "../Components/Button";
import InputField from "../Components/InputField";

import cookie from "js-cookie";
import { sha256 } from "js-sha256";

import * as YUP from "yup";

export default function Login() {
	const [login, { isLoading }] = useLoginMutation();

	const user = useSelector(currentUser);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	useEffect(() => {
		if (user) {
			navigate("/");
		}
	}, [user, navigate]);

	const formik = useFormik({
		initialValues: {
			username: "",
			password: "",
		},
		onSubmit: (values) => {
			const hashedPassword = sha256(values.password);
			login({
				user: values.username,
				pwd: hashedPassword,
			})
				.unwrap()
				.then((res) => {
					dispatch(setCredentials({ ...res }));
					cookie.set("accessToken", res?.accessToken);
					navigate("/");
					toast.success("successfully logged in");
				})
				.catch((err) => {
					console.log(err);

					if (err.originalStatus === 401) {
						toast.error("Username or Password Incorrect");
						return;
					}

					toast.error("login to your account was faild , please try again");
				});
		},
		validationSchema: YUP.object({
			username: YUP.string("this field is String").required(
				"username required",
			),
			password: YUP.string("this field is String").required(
				"please enter password",
			),
		}),
	});

	return (
		<section className="custom_container flex justify-center items-center w-full h-full">
			<div className="flex flex-col items-center gap-4">
				<div>
					<h2 className="text-center text-3xl font-semibold">
						<span className="text-4xl font-bold text-primary">SUSA6</span>{" "}
						Dashboard
					</h2>
				</div>

				<form onSubmit={formik.handleSubmit} className="w-full">
					<div className="flex flex-col xl:flex-row xl:gap-4">
						<InputField
							label="username"
							full
							values={formik.values.username}
							formik={formik}
							options={{ ...formik.getFieldProps("username") }}
						/>

						<InputField
							label="password"
							full
							values={formik.values.password}
							formik={formik}
							type="password"
							options={{ ...formik.getFieldProps("password") }}
						/>
					</div>
					<div className="mt-4">
						<Button
							type="submit"
							text="Login To Dashboard"
							size="lg"
							isLoading={isLoading}
						/>
					</div>
				</form>
			</div>
		</section>
	);
}
