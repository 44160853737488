import { motion } from "framer-motion";
import { CgCloseR } from "react-icons/cg";

export default function DetailsModal({
	title,
	data,
	setModalOpen,
	setTableDataSelect,
}) {
	return (
		<motion.div
			initial={{ opacity: 0.4 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			transition={{ duration: 0.4 }}
			className="fixed gap-2 z-50 flex items-center justify-center
            top-0 left-0 w-screen h-screen overflow-y-scroll p-4 bg-black/40 glassmorphism"
		>
			<div className="p-4 rounded-3xl bg-slate-600 max-h-full md:w-[500px] max-w-full">
				<div className="flex items-center justify-between mb-4">
					<h2 className="text-slate-200 text-2xl lg:text-3xl font-semibold">
						{title}
					</h2>
					<CgCloseR
						className="text-slate-300 text-3xl hover:scale-105 hover:text-white 
            cursor-pointer transition-all duration-300"
						onClick={() => {
							setModalOpen(false);
							setTableDataSelect({});
						}}
					/>
				</div>

				<hr className="mb-4 border border-slate-400/50 mx-2" />

				<ul className="grid xl:grid-cols-3 grid-cols-2 lg:gap-x-2 lg:gap-y-4 md:gap-x-3 gap-y-2 gap-x-2 overflow-y-scroll">
					{Object.entries(data || {}).map((item, index) => (
						<li
							key={index}
							className="flex flex-col flex-wrap justify-start gap-1 transition-all duration-300
                     hover:bg-slate-600/60 px-4 py-1 rounded-lg select-none"
						>
							<p className="text-slate-300 text-sm">{item[0]}</p>
							<p className="text-[11px] lg:text-xs text-slate-100 font-semibold break-all">
								{item[1]}
							</p>
						</li>
					))}
				</ul>
			</div>
		</motion.div>
	);
}
