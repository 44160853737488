import { motion } from "framer-motion";
import IssueCreditForm from "../Components/modules/IssueCreditForm";

export default function IssueCredit() {
	return (
		<motion.section
			className="custom_container flex flex-col items-center justify-center"
			initial={{ y: 10, opacity: 0.4, scale: 0.7 }}
			animate={{ y: 0, opacity: 1, scale: 1 }}
			transition={{ stiffness: 100 }}
		>
			<div className="mb-8 flex flex-col gap-2 items-center justify-center">
				<h2 className="text-3xl font-semibold">Issue Credit</h2>
				<p className="text-xs text-slate-500">
					in this section you can add new credit
				</p>
			</div>

			<IssueCreditForm />
		</motion.section>
	);
}
