export default function InputField({
	label,
	type,
	values,
	full,
	options,
	formik,
}) {
	type = type || "text";

	return (
		<div
			className={`flex flex-col gap-1 my-2 group ${
				full ? "w-full" : "w-full lg:w-60 xl:w-80"
			}`}
		>
			<label
				htmlFor={label}
				className="ml-1 text-xs md:text-sm group-focus-within:text-primary 
				group-focus-within:pl-2 transition-all duration-300"
			>
				{label.split("_").map((item) => (
					<span key={item} className="capitalize mr-1">
						{item}
					</span>
				))}
			</label>
			<input
				value={values}
				type={type}
				name={label}
				id={label}
				{...options}
				className="text-primary-dark outline-none border-2 rounded-lg bg-white px-4 py-2 focus-within:border-primary/40
				 transition-all duration-300"
			/>
			{formik && formik?.touched[label] && formik?.errors[label] ? (
				<span className="text-xs font-semibold text-system-error ml-2">
					{formik?.errors[label]}
				</span>
			) : null}
		</div>
	);
}
