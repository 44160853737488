import { useState } from "react";

import { motion } from "framer-motion";

// COMPONENTS
import AddVotableItemForm from "../../Components/modules/AddVotableItemForm";
import StartElectionForm from "../../Components/modules/StartSingleElectionForm";

// ICONS
import { AiOutlineClose } from "react-icons/ai";

export default function Elections() {
	const [currentVote, setCurrentVote] = useState(null);
	const [votingModalIsOpen, setVotingModalIsOpen] = useState(false);
	const [votable_id_input, setVotable_id_input] = useState("");

	const [votableItems, setVotableItems] = useState([
		{
			votableId: "",
		},
	]);

	const votableItemsHandler = (index) => {
		const newVotableItems = [...votableItems];
		newVotableItems[index].votableId = currentVote?.votableId;
		setVotableItems(newVotableItems);
	};

	return (
		<motion.section
			initial={{ y: 10, opacity: 0.4, scale: 0.7 }}
			animate={{ y: 0, opacity: 1, scale: 1 }}
			transition={{ stiffness: 100 }}
			className={`custom_container grid grid-cols-1 relative overflow-x-hidden overflow-y-auto`}
		>
			<div className="flex flex-col xl:flex-row gap-8 xl:gap-x-20">
				<div className="flex flex-col xl:gap-12 gap-8 w-full">
					<StartElectionForm
						votableItems={votableItems}
						setVotableItems={setVotableItems}
						votableItemsHandler={votableItemsHandler}
						currentVote={currentVote}
						setVotingModalIsOpen={setVotingModalIsOpen}
						setVotable_id_input={setVotable_id_input}
					/>
				</div>

				{votingModalIsOpen && (
					<div
						className="absolute left-2 right-2 top-0 bottom-0 min-h-screen z-50 bg-white p-4 rounded-xl
						"
					>
						<AiOutlineClose
							onClick={() => setVotingModalIsOpen(false)}
							className="text-xl ml-auto"
						/>
						<AddVotableItemForm
							setCurrentVote={setCurrentVote}
							setVotingModalIsOpen={setVotingModalIsOpen}
							votable_id_input={votable_id_input}
						/>
					</div>
				)}
			</div>
		</motion.section>
	);
}
