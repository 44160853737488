import { useEffect, useState } from "react";

import { motion } from "framer-motion";
import { useSelector } from "react-redux";
// ICONS
import { IoIosArrowUp } from "react-icons/io";

import { Bar } from "react-chartjs-2";

import RecentTransactionsDetails from "../Data/recentTransactionsDetails";

// COMPONENTS
import OverviewBalance from "../Components/UiElements/OverviewBalance";
import Table from "../Components/table/Table";
import DetailsModal from "../Components/UiElements/DetailsModal";

import { AnimatePresence } from "framer-motion";
import { data, options } from "../Components/charts/walletTransactions";
import { useGetHistoryQuery } from "../app/services/api/governance/balance";
import Loading from "../Components/UiElements/Loading";
import { currentUser } from "../app/services/authSlice";
import sliceData from "../helpers/sliceData";
import ClientPagination from "../Components/table/ClientPagination";

const rowsPerPage = 8;

export default function Wallet(props) {
	const [tableDataSelect, setTableDataSelect] = useState(null);
	const [transactionDetails, setTransactionDetails] = useState({});
	const [modalOpen, setModalOpen] = useState(false);
	const [page, setPage] = useState(1);
	const [slicedData, setSlicedData] = useState([]);

	const user = useSelector(currentUser);
	const {
		data: history,
		isLoading,
		isError,
		error,
	} = useGetHistoryQuery(user.username);

	useEffect(() => {
		const result = RecentTransactionsDetails.filter(
			(item) => item.id === tableDataSelect?.tx_id,
		)[0];
		if (result) {
			setTransactionDetails(result);
			setModalOpen(true);
		}
	}, [tableDataSelect]);

	useEffect(() => {
		setModalOpen(false);
		const sliced = sliceData(history, page, rowsPerPage);
		setSlicedData(sliced);
	}, [history, page]);

	return (
		<motion.section
			initial={{ y: 10, opacity: 0.4, scale: 0.7 }}
			animate={{ y: 0, opacity: 1, scale: 1 }}
			transition={{ stiffness: 100 }}
			className="custom_container"
		>
			<div className="w-full p-2 lg:p-6 rounded-lg grid xl:grid-cols-12 grid-cols-1 gap-12 lg:gap-20">
				<div className="xl:col-span-5">
					{/* balance manage */}
					<OverviewBalance />
				</div>
				{/* chart */}
				<div className="h-full w-full flex flex-col gap-6 xl:col-span-7">
					<div className="w-full flex items-center justify-between">
						<div>
							<p className="font-bold text-secondary">
								<span className="text-black font-thin">last week: </span>
								$530,420
							</p>
						</div>
						<div className="flex gap-4 items-center">
							<p className="text-2xl">$540,370</p>
							<p className="flex flex-col items-center text-secondary font-bold">
								<IoIosArrowUp className="text-sm" />
								<span>7%</span>
							</p>
						</div>
					</div>
					<div className="w-full h-full hidden lg:block">
						<Bar className="w-full h-full" options={options} data={data} />
					</div>

					<div className="w-full h-full lg:hidden">
						<Bar className="w-full h-full" options={options} data={data} />
					</div>
				</div>
			</div>

			<h2 className="mt-8 text-3xl font-bold mb-6">Recent Transactions</h2>
			<div className="lg:flex flex-wrap gap-8 justify-between">
				<div className="overflow-x-auto">
					{isLoading ? (
						<Loading />
					) : isError ? (
						<p className="px-4 py-3 bg-red-50 border border-red-200 rounded-lg text-sm text-slate-600">
							<span className="text-red-500 uppercase text-base">
								Fetch Error!
							</span>
							<br />
							{error.data.message}
						</p>
					) : (
						<div className="flex flex-col">
							<Table
								emptyHandler
								headers={["date", "from", "to", "description", "amount"]}
								data={slicedData}
								setter={setTableDataSelect}
							/>
							{history?.length > rowsPerPage && (
								<ClientPagination
									data={history}
									slicedData={slicedData}
									rowsPerPage={rowsPerPage}
									page={page}
									setPage={setPage}
								/>
							)}
						</div>
					)}{" "}
				</div>
				<AnimatePresence>
					{/* MODAL */}
					{modalOpen && (
						<DetailsModal
							data={transactionDetails}
							setModalOpen={setModalOpen}
							setTableDataSelect={setTableDataSelect}
						/>
					)}
				</AnimatePresence>
			</div>
		</motion.section>
	);
}
