import { useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
// ICONS
import { BiChevronDown } from "react-icons/bi";

export default function DropDown({ label, name, data, value, setter }) {
	const [optionIsOpen, setOptionIsOpen] = useState(false);
	return (
		<div className="w-full">
			{label && <p className="text-sm mb-2 ml-1 text-slate-900">{label}</p>}
			<div
				onClick={() => setOptionIsOpen((prev) => !prev)}
				className="pl-4 pr-2 py-2 border rounded-lg flex items-center justify-between gap-4 relative cursor-pointer"
			>
				<p className="text-sm">{value}</p>
				<BiChevronDown
					className={`text-xl order-2 ${
						optionIsOpen && "rotate-180"
					} transition-all duration-500`}
				/>

				<AnimatePresence>
					{optionIsOpen && (
						<motion.div
							initial={{ opacity: 0.4, scale: 0.9 }}
							animate={{ opacity: 1, scale: 1 }}
							exit={{ opacity: 0, scale: 0.9 }}
							className="absolute left-0 right-0 top-10 z-50 h-max flex flex-col gap-1 bg-gray-100
							 rounded-lg overflow-hidden text-xs"
						>
							{data.map((opt) => (
								<p
									key={opt}
									onClick={(e) => {
										setter(name, e.target.innerText) ||
											setter(e.target.innerText);
									}}
									className="p-2 hover:bg-gray-200 cursor-pointer transition-all duration-300"
								>
									{opt}
								</p>
							))}
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</div>
	);
}
