import { useEffect, useState } from "react";

import Loading from "../../Components/UiElements/Loading";
import Table from "../../Components/table/Table";
import ResultRevealButton from "../../Components/ResultRevealButton";
import ClientPagination from "../../Components/table/ClientPagination";
import ErrorCard from "../../Components/Cards/ErrorCard";

import sliceData from "../../helpers/sliceData";

import { useGetElectionsQuery } from "../../app/services/api/evote";

export default function ElectionHistory() {
	const { data, isLoading, isError, error } = useGetElectionsQuery();
	const [slicedData, setSlicedData] = useState();
	const [page, setPage] = useState(1);

	useEffect(() => {
		const sliced = sliceData(data, page, 8);
		setSlicedData(sliced);
	}, [page, data]);

	return (
		<section className="custom_container flex flex-col">
			<h2 className="text-2xl font-bold mb-6">Voting History</h2>
			{isLoading ? (
				<Loading />
			) : isError ? (
				<ErrorCard error={error} />
			) : data?.length === 0 ? (
				<p>
					your voting history was empty , please start an vote to see it on the
					history
				</p>
			) : (
				<div className="flex flex-col gap-4">
					<Table
						data={slicedData}
						headers={[
							"electionId",
							"startDate",
							"endDate",
							"type",
							"caseTypeForCasting",
						]}
						additionalCols={["result"]}
						buttons={[
							<ResultRevealButton data={data} key="resultRevealButton" />,
						]}
					/>
					<ClientPagination
						data={data}
						slicedData={slicedData}
						page={page}
						setPage={setPage}
						rowsPerPage={8}
					/>
				</div>
			)}
		</section>
	);
}
