export default function FloatingLabelInput({
	type,
	value,
	label,
	options,
	formik,
	children,
}) {
	// default classes
	const activeInputLabel = `top-1/2 -translate-y-1/2 left-4 group-focus-within:top-0 
    group-focus-within:bg-slate-50 group-focus-within:px-2 group-focus-within:left-1 
    transition-all duration-500 group-focus-within:text-xs text-slate-500
	
	group-focus-within:${
		formik.touched[label] && formik.errors[label] && "text-red-200"
	}`;
	const defaultInputLabel = `-top-2 bg-slate-50 text-xs left-1 px-2 
	
	${formik.touched[label] && formik.errors[label] && "text-red-400"} rounded-lg`;
	return (
		<div className="my-1 flex-1">
			<div className="relative group flex gap-2">
				<input
					value={value}
					name={label}
					type={type || "text"}
					id={label}
					autoComplete="off"
					className={`border-2 bg-transparent rounded-lg outline-none text-sm border-slate-300
					${
						formik.touched[label] && formik.errors[label] && "border-red-100"
					} px-4 py-2 w-full focus-within:${
						formik.touched[label] && formik.errors[label] && "border-red-200"
					}`}
					{...options}
				/>
				{children}

				<label
					htmlFor={label}
					className={`absolute ${
						value ? defaultInputLabel : activeInputLabel
					} `}
				>
					{label}
				</label>
			</div>
			{formik.touched[label] && formik.errors[label] && (
				<span className="ml-1 text-red-500 text-[10px]">
					{formik.errors[label]}
				</span>
			)}
		</div>
	);
}
