const userPurchased = [
	{
		id: 1,
		username: "Admin",
		roles: "SusaAdmin",
		count: 2000,
	},
	{
		id: 2,
		username: "UserTest1",
		roles: "Admin",
		count: 1750,
	},
	{
		id: 3,
		username: "UserTest2",
		roles: "User",
		count: 1300,
	},
	{
		id: 4,
		username: "UserTest3",
		roles: "User",
		count: 1670,
	},
	{
		id: 5,
		username: "Admin",
		roles: "User",
		count: 1800,
	},
	{
		id: 6,
		username: "Admin",
		roles: "User",
		count: 1320,
	},
	{
		id: 7,
		username: "Admin",
		roles: "User",
		count: 1100,
	},
	{
		id: 8,
		username: "Admin",
		roles: "Admin",
		count: 1000,
	},
];

export default userPurchased;
