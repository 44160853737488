import susaText from "../Assets/susa_text.svg";
import susaLogo from "../Assets/susa_logo.svg";

export default function Logo() {
	return (
		<div className="w-full py-4 mb-2 flex items-center justify-center lg:justify-start gap-2">
			<img src={susaLogo} alt="logo" className="w-8 lg:w-8 h-auto" />
			<div className="items-center self-stretch hidden lg:flex">
				<img src={susaText} alt="logo_text" className="-mt-2 h-6 w-auto" />
			</div>
		</div>
	);
}
