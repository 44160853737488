const checkStatus = (status) => {
	let defaultStyle =
		"[&>*]:px-3 [&>*]:text-black [&>*]:capitalize [&>*]:text-[10px] [&>*]:font-semibold [&>*]:py-1 [&>*]:rounded-xl";
	if (status === "listed" || status === "successfully" || status === "ISSUED") {
		return `${defaultStyle} [&>*]:bg-secondary/10 [&>*]:border [&>*]:text-secondary`;
	} else if (
		status === "RETIRED" ||
		status === "failed" ||
		status === "PURCHASED"
	) {
		return `${defaultStyle} [&>*]:bg-system-error/10 [&>*]:text-system-error`;
	} else if (status === "FORSALE") {
		return `${defaultStyle} [&>*]:bg-yellow-100 [&>*]:shadow-lg [&>*]:shadow-yellow-100/50 [&>*]:border [&>*]:border-yellow-100 [&>*]:text-yellow-500`;
	}
};

export default checkStatus;
