import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

export const creditApiSlice = createApi({
	reducerPath: "credit",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://susa6.com/api/credit",
		headers: { Authorization: `Bearer ${Cookies.get("accessToken")}` },
	}),
	tagTypes: ["retired"],
	endpoints: () => ({}),
});
