import { configureStore } from "@reduxjs/toolkit";
import { evoteApiSlice } from "./services/evoteApiSlice";
import { creditApiSlice } from "./services/creditApiSlice";
import { auctionApiSlice } from "./services/auctionApiSlice";
import { apiSlice } from "./services/apiSlice";

import authReducer from "./services/authSlice";
import { balanceApiSlice } from "./services/balanceApiSlice";

const store = configureStore({
	reducer: {
		[apiSlice.reducerPath]: apiSlice.reducer,
		[evoteApiSlice.reducerPath]: evoteApiSlice.reducer,
		[creditApiSlice.reducerPath]: creditApiSlice.reducer,
		[auctionApiSlice.reducerPath]: auctionApiSlice.reducer,
		[balanceApiSlice.reducerPath]: balanceApiSlice.reducer,
		auth: authReducer,
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(
			apiSlice.middleware,
			evoteApiSlice.middleware,
			creditApiSlice.middleware,
			auctionApiSlice.middleware,
			balanceApiSlice.middleware,
		),
});

export default store;
