import { apiSlice } from "../apiSlice";

const authApiSlice = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		login: builder.mutation({
			query: (user) => ({
				url: "/login",
				method: "POST",
				body: user,
			}),
		}),
	}),
});

export const { useLoginMutation } = authApiSlice;
