import { FaLongArrowAltDown, FaComment } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import { IoCheckmarkDone } from "react-icons/io5";

import { MdKeyboardArrowUp, MdOutlinePending } from "react-icons/md";

import { motion } from "framer-motion";

export default function ShowMore({ setShowMore, processBlocks }) {
	return (
		<motion.div
			initial={{ height: 20 }}
			animate={{ height: "auto" }}
			exit={{ height: 0 }}
			transition={{ stiffness: 250, duration: 1.25 }}
			className="flex flex-col gap-2 h-full"
		>
			{/* block 1 */}
			{processBlocks.map((block, index) => {
				if (block.endDate !== "") {
					return (
						<div key={block.id} className="flex flex-col gap-2 mt-4">
							<span className="text-xs font-semibold text-center rounded-lg w-max py-1 px-4 bg-gray-200">
								Step {block.step}
							</span>

							<div className="flex gap-4 flex-1">
								<aside className="flex gap-2">
									<div className="flex flex-col justify-between gap-4 h-full">
										<div className="text-center leading-4">
											<p className="font-semibold text-[10px]">
												{block.startDate}
											</p>
											<span className="text-xs font-bold">
												{block.startHour}
											</span>
										</div>

										<p className="text-center text-[10px] break-words">
											{block.tookTime}
										</p>

										<div className="text-center leading-4">
											<p className="font-semibold text-[10px]">
												{block.endDate}
											</p>
											<span className="text-xs font-bold">{block.endHour}</span>
										</div>
									</div>

									<div className="h-full flex flex-col items-center">
										<span className="w-7 h-7 rounded-full bg-black text-white flex justify-center items-center p-1">
											<FaLongArrowAltDown className="text-sm" />
										</span>
										<vr className="flex-grow border border-black border-dashed" />
										<span className="w-7 h-7 rounded-full bg-black text-white flex justify-center items-center p-1">
											{block.step !== processBlocks.length ? (
												processBlocks.length > index + 1 &&
												processBlocks[index + 1].endDate !== "" ? (
													<TiTick className="text-sm" />
												) : (
													<MdOutlinePending />
												)
											) : (
												<IoCheckmarkDone className="text-sm" />
											)}
										</span>
									</div>
								</aside>

								<div className="flex-grow flex flex-col justify-between gap-4">
									<div className="text-sm font-semibold flex flex-col gap-2">
										<h2>{block.title}</h2>
										<p className="text-[10px] text-gray-500 leading-3">
											{block.description}
										</p>
									</div>

									{/* comment box */}
									<div className="text-xs my-4 px-2 py-2 border rounded-lg flex flex-col gap-2">
										<div className="flex flex-col gap-1">
											<p className="flex items-center gap-2 font-semibold">
												<span
													className="flex items-center justify-center p-1 w-6 h-6 rounded-full 
                                    bg-system-error text-white"
												>
													<FaComment />
												</span>
												comment sender name
											</p>
											<p className="text-[10px]">
												message from{" "}
												<span className="uppercase font-semibold">
													organization
												</span>
											</p>
										</div>

										<hr />

										<p className="italic">
											Lorem ipsum dolor sit amet consectetur adipisicing elit.
											Eius magnam sapiente tenetur commodi, possimus molestiae.
										</p>
									</div>

									<div className="text-sm font-semibold flex flex-col gap-2">
										<h2>
											{block.nextProcessTitle ? block.nextProcessTitle : "DONE"}
										</h2>
										<p className="text-[10px] text-gray-500 leading-3">
											{block.nextProcessDescription
												? block.nextProcessDescription
												: "All Steps Has Done"}
										</p>
									</div>
								</div>
							</div>
						</div>
					);
				} else return null;
			})}

			<button
				onClick={() => setShowMore(false)}
				className="w-max ml-auto px-4 py-1 close text-xs flex gap-1 items-center
                rounded-lg border"
			>
				close
				<MdKeyboardArrowUp className="text-base" />
			</button>
		</motion.div>
	);
}
