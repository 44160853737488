import { Link } from "react-router-dom";
import Logo from "../Logo";
import { ImSpinner9 } from "react-icons/im";
export default function VerifiedLoading({
	verifiedError,
	loading,
	refreshToken,
}) {
	console.log(verifiedError.toLowerCase().includes("fetch"));
	return (
		<section className="fixed w-screen h-screen left-0 top-0 z-50 glassmorphism bg-slate-200/60 flex  justify-center items-center">
			<div className="flex flex-col gap-1">
				<Logo />
				<h2 className="text-xl font-bold">Verified The User</h2>
				<p className="text-sm text-gray-700 flex items-center gap-2">
					{loading && <ImSpinner9 className="animate-spin duration-500" />}
					we have check verification status, please wait for a second ...
				</p>
				{!loading && (
					<>
						<span className="text-red-400 text-xs">
							{verifiedError} - please check your connection and try again
						</span>
						<button
							onClick={refreshToken}
							className="bg-red-100 text-red-500 rounded-xl px-4 py-2 w-max text-xs mt-2 ml-auto"
						>
							try again
						</button>
						{!verifiedError.toLowerCase().includes("fetch") && (
							<Link
								to={"https://susa6.com/login"}
								onClick={refreshToken}
								className="bg-primary text-white rounded-xl px-4 py-2 text-xs mt-6 text-center"
							>
								Login
							</Link>
						)}
					</>
				)}
			</div>
		</section>
	);
}
