import { Link } from "react-router-dom";
import BadgeCards from "../Components/modules/BadgeCards";
import CreditListTable from "../Components/modules/CreditListTable";
import { Bar } from "react-chartjs-2";
import { data, options } from "../Components/charts/totalAuction";
import Top5Users from "../Components/SusaAdminState/Top5Users";
import userPurchased from "../Data/usersPurchased";
import userSales from "../Data/userSales";
import { useSelector } from "react-redux";
import { currentUser } from "../app/services/authSlice";

export default function Dashboard() {
	const user = useSelector(currentUser);
	return (
		<div className="custom_container flex flex-col gap-8">
			<BadgeCards />
			<div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-8 gap-4">
				<CreditListTable />
				<div className="flex flex-col gap-2">
					<div className="flex justify-between flex-wrap gap-4 items-center">
						<div>
							<h2 className="text-xl font-semibold">Total Auctions</h2>
							<p className="text-xs">
								This chart shows all the Offsets you have put up for auction
							</p>
						</div>

						<Link
							to="/fixed-price"
							className="text-xs text-primary ml-auto lg:ml-0"
						>
							place a new auction
						</Link>
					</div>

					<Bar className="w-full h-full" options={options} data={data} />
				</div>
			</div>
			{user?.roles === "SusaAdmin" ? (
				<div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 lg:gap-12 gap-4">
					<Top5Users
						title={"Top 5 User Purchased"}
						description={
							"In this section, you can see the list of the most purchased users"
						}
						data={userPurchased.sort((a, b) => b.count - a.count).slice(0, 5)}
					/>

					<Top5Users
						title={"Top 5 User Sales"}
						description={
							"In this section, you can see the list of the most Sales users"
						}
						data={userSales.sort((a, b) => b.count - a.count).slice(0, 5)}
					/>

					<div className="border-2 rounded-xl"></div>

				</div>
			) : null}
		</div>
	);
}
