import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import { useGetCreditsQuery } from "../../app/services/api/credit";
import toast from "react-hot-toast";

import sliceData from "../../helpers/sliceData";

// COMPONENTS
import Table from "../../Components/table/Table";
import DetailsModal from "../../Components/UiElements/DetailsModal";
import Loading from "../../Components/UiElements/Loading";
import ErrorCard from "../../Components/Cards/ErrorCard";
import ClientPagination from "../../Components/table/ClientPagination";

const rowsPerPage = 6;

export default function RetiredCredits(props) {
	const [page, setPage] = useState(1);
	const [slicedData, setSlicedData] = useState([]);
	const [fetchedData, setFetchedData] = useState(null);
	const { data, isLoading, isFetching, isError, error } = useGetCreditsQuery();

	const [tableDataSelect, setTableDataSelect] = useState({});
	const [modalIsOpen, setModalIsOpen] = useState(false);

	useEffect(() => {
		const filterd = data?.filter((item) => item.status === "RETIRED");
		setFetchedData(filterd);
	}, [data]);

	useEffect(() => {
		setModalIsOpen(false);
		const sliced = sliceData(fetchedData, page, rowsPerPage);
		setSlicedData(sliced);
	}, [fetchedData, page]);

	useEffect(() => {
		if (Object.keys(tableDataSelect).length > 0) {
			setModalIsOpen(true);
		} else {
			setModalIsOpen(false);
		}
	}, [tableDataSelect]);

	return (
		<motion.section
			initial={{ y: 10, opacity: 0.4, scale: 0.7 }}
			animate={{ y: 0, opacity: 1, scale: 1 }}
			transition={{ stiffness: 100 }}
			className="custom_container w-full flex flex-wrap gap-12 relative overflow-hidden"
		>
			<div className="flex flex-wrap items-center justify-between gap-4 mb-8 md:hidden w-full">
				<Link
					to="/history/issued-credits"
					className="px-4 py-2 rounded-2xl bg-lime-100 hover:scale-95 hover:bg-lime-300 transition-all duration-300"
				>
					Issued Offsets
				</Link>
				<Link
					to="/history/purchased-credits"
					className="px-4 py-2 rounded-2xl bg-lime-100 hover:scale-95 hover:bg-lime-300 transition-all duration-300"
				>
					Purchased Offsets
				</Link>
			</div>

			<div className="flex gap-4 flex-wrap">
				<div>
					<div className="mb-8">
						<h2 className="mb-1 text-lg lg:text-xl xl:text-2xl font-semibold">
							Retired Offsets
						</h2>
						{fetchedData?.length > 0 && (
							<p className="text-slate-600 text-xs">
								select on each rows to see details
							</p>
						)}
					</div>

					{isLoading || isFetching ? (
						<Loading />
					) : isError ? (
						<ErrorCard error={error} />
					) : (
						<>
							<Table
								emptyHandler
								data={slicedData}
								customButtonId={"retiringTxid"}
								additionalCols={["explore"]}
								buttons={[
									<button
										onClick={(e) => {
											const txId = e.target.parentElement.id;
											if (txId) {
												window.open(
													`http://susa6.com:8080/?tab=transactions&transId=${txId}`,
												);
											} else {
												toast.error("txID not available");
											}
										}}
										className="px-2 py-1 rounded-lg border hover:bg-primary hover:border-primary 
								hover:text-white transition-all duration-300"
									>
										Explore
									</button>,
								]}
								headers={[
									"vintageStartDate",
									"vintageEndDate",
									"forsaleStart",
									"forsaleEnd",
									"susaId",
									"retiringTxid",
									"status",
								]}
								setter={setTableDataSelect}
							/>
							{fetchedData?.length > rowsPerPage && (
								<ClientPagination
									data={fetchedData}
									slicedData={slicedData}
									rowsPerPage={rowsPerPage}
									page={page}
									setPage={setPage}
								/>
							)}
						</>
					)}
				</div>

				{modalIsOpen && (
					<DetailsModal
						title="Offset details"
						data={(({
							susaId,
							creditType,
							blockIdStart,
							blockIdEnd,
							programeCode,
							projectTypeCode,
							vintageStartDate,
							vintageEndDate,
							batchNumber,
						}) => ({
							susaId,
							creditType,
							blockIdStart,
							blockIdEnd,
							programeCode,
							projectTypeCode,
							vintageStartDate,
							vintageEndDate,
							batchNumber,
						}))(tableDataSelect)}
						setModalOpen={setModalIsOpen}
						setTableDataSelect={setTableDataSelect}
					/>
				)}
			</div>
		</motion.section>
	);
}
