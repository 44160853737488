import { useEffect } from "react";

import { useNavigate } from "react-router-dom";

export default function History() {
	const navigate = useNavigate();
	useEffect(() => {
		navigate("/history/issued-credits");
	}, [navigate]);
	return <></>;
}
