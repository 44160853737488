import InputField from "../../Components/InputField";
import useIssueCredit from "../../Hooks/formik/useIssueCredit";

import CountryDropDown from "../../Components/CountryDropDown";

export default function IssueCreditForm() {
	const [formik, isLoading] = useIssueCredit();

	return (
		<form
			onSubmit={formik.handleSubmit}
			className="w-full md:w-auto md:mx-auto flex flex-col lg:gap-2"
		>
			<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-4 lg:gap-x-8 w-full">
				<InputField
					label="credit_type"
					value={formik.values.credit_type}
					options={{ ...formik.getFieldProps("credit_type") }}
					formik={formik}
				/>
				<InputField
					label="block_id_start"
					options={{ ...formik.getFieldProps("block_id_start") }}
					formik={formik}
				/>

				<InputField
					label="block_id_end"
					options={{ ...formik.getFieldProps("block_id_end") }}
					formik={formik}
					full
				/>
				<InputField
					label="program_code"
					options={{ ...formik.getFieldProps("program_code") }}
					formik={formik}
					full
				/>
				<InputField
					label="project_type_code"
					options={{ ...formik.getFieldProps("project_type_code") }}
					formik={formik}
					full
				/>

				<InputField
					label="project_id"
					options={{ ...formik.getFieldProps("project_id") }}
					formik={formik}
					full
				/>

				<div
					className="flex flex-col gap-1 my-2 group 
				w-full lg:w-60 xl:w-80"
				>
					<CountryDropDown
						name="project_country"
						value={formik.values.project_country}
						setter={formik.setFieldValue}
					/>
				</div>
				<InputField
					label="vintage_start_date"
					type="date"
					options={{ ...formik.getFieldProps("vintage_start_date") }}
					formik={formik}
					full
				/>

				<InputField
					label="vintage_end_date"
					type="date"
					options={{ ...formik.getFieldProps("vintage_end_date") }}
					formik={formik}
					full
				/>
				<InputField
					label="validator_identifier"
					options={{ ...formik.getFieldProps("validator_identifier") }}
					formik={formik}
					full
				/>
				<InputField
					label="registery_identifier"
					options={{ ...formik.getFieldProps("registery_identifier") }}
					formik={formik}
					full
				/>
				<InputField
					label="susa_id"
					full={true}
					options={{ ...formik.getFieldProps("susa_id") }}
					formik={formik}
				/>
			</div>

			<div className="flex felx-wrap gap-4">
				<InputField
					label="original_credit_id"
					full={true}
					options={{ ...formik.getFieldProps("original_credit_id") }}
					formik={formik}
				/>
			</div>

			<div className="mt-4 w-full flex items-center justify-center">
				<button
					type="submit"
					className="w-full lg:w-[50%] text-white bg-primary/60 rounded-lg border-none px-4 py-3 col-span-2
					 shadow-xl shadow-primary/20 hover:bg-primary transition-all duration-300"
				>
					{isLoading ? "sending ..." : "submit"}
				</button>
			</div>
		</form>
	);
}
