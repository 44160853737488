import { useSelector } from "react-redux";
import { currentUser } from "../app/services/authSlice";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export default function ProtectedRoutes() {
	const user = useSelector(currentUser);
	const location = useLocation();

	return (
		<>
			{user?.accessToken ? (
				<Outlet />
			) : (
				<Navigate to="/login" state={{ from: location }} />
			)}
		</>
	);
}
