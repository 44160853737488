import { useState } from "react";
// ICONS
import { IoIosSend } from "react-icons/io";
import { BsDownload } from "react-icons/bs";
// COMPONENTS
import TokenBalance from "../../Components/Cards/TokenBalance";
import SendWalletForm from "./SendWalletForm";
import RecieveWallet from "./RecieveWallet";

export default function OverviewBalance() {
	const [actionPage, setActionPage] = useState("send");

	return (
		<div className="flex flex-col gap-4">
			<div>
				<h1 className="text-2xl font-semibold text-center md:text-start">
					Overview Balance
				</h1>
				<p className="text-center md:text-start text-xs text-slate-600 mt-2">
					Lorem ipsum dolor sit, amet consectetur adipisicing elit. Illo, nulla.
				</p>
			</div>
			<div className="flex flex-col md:flex-row items-center md:gap-8 gap-4">
				<div className="w-full lg:w-56">
					<TokenBalance count={100} name="Current Total Balance" />
				</div>
				<div className="flex items-center gap-[2px]">
					<button
						onClick={() => setActionPage("send")}
						className={`text-xl p-3 flex items-center
            justify-center hover:text-slate-500 hover:bg-slate-100 transition-all duration-300
            border rounded-l-lg ${
							actionPage === "send" && "bg-primary text-white border-primary"
						}`}
					>
						<IoIosSend />
					</button>
					<button
						onClick={() => setActionPage("recieve")}
						className={`text-xl p-3 flex items-center
            justify-center hover:text-slate-500 hover:bg-slate-100 transition-all duration-300
            border rounded-r-lg  ${
							actionPage === "recieve" && "bg-primary text-white border-primary"
						}`}
					>
						<BsDownload />
					</button>
				</div>
			</div>
			<div className="w-full h-full">
				{/* send page */}
				{actionPage === "send" ? <SendWalletForm /> : <RecieveWallet />}
			</div>
		</div>
	);
}
