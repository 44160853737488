//? React Router Dom
import { Route, Routes } from "react-router-dom";

//? PAGES
import Dashboard from "../Pages/Dashboard";
import IssueCredit from "../Pages/IssueCredit";
import History from "../Pages/History";
import IssueCredits from "../Pages/History/IssueCredits";
import RetiredCredits from "../Pages/History/RetiredCredits";
import PurchasedCredits from "../Pages/History/PurchasedCredits";
import FixedPrice from "../Pages/FixedPrice";
import Wallet from "../Pages/Wallet";
import Elections from "../Pages/Elections";
import ElectionHistory from "../Pages/Elections/ElectionHistory";
import ProtectedRoutes from "./protectedRoutes.routes";
import Login from "../Pages/Login";
import NotFound from "../Pages/NotFound";

import ForsaleCredits from "../Pages/History/ForsaleCredits";
import AdminRoutes from "./adminRoutes.routes";
import LeaderBoards from "../Pages/SUSA__ADMIN/LeaderBoards";
import Processes from "../Pages/SUSA__ADMIN/Processes";
import AddNewOrg from "../Pages/SUSA__ADMIN/processTypes/on-chain/AddNewOrg";
import ContactUs from "../Pages/SUSA__ADMIN/ContactUs";
import OnChain from "../Pages/SUSA__ADMIN/processTypes/on-chain";
import OffChain from "../Pages/SUSA__ADMIN/processTypes/off-chain";
import NewOrganizations from "../Pages/SUSA__ADMIN/processTypes/off-chain/NewOrganizations";
import ReserveList from "../Pages/USER__STATE/ReserveList";

export default function IndexRoutes() {
	return (
		<Routes>
			<Route path="/login" element={<Login />} />
			<Route element={<ProtectedRoutes />}>
				<Route element={<AdminRoutes />}>
					<Route path="/ticketing" element={<Processes />} />
					{/* onChain */}
					<Route path="/ticketing/on-chain" element={<OnChain />} />
					<Route path="/ticketing/on-chain/add-org" element={<AddNewOrg />} />
					{/* offChain */}
					<Route path="/ticketing/off-chain" element={<OffChain />} />
					<Route
						path="/ticketing/off-chain/new-organizations"
						element={<NewOrganizations />}
					/>

					<Route path="/issue-credit" element={<IssueCredit />} />
					<Route path="/leader-boards" element={<LeaderBoards />} />
					<Route path="/fixed-price" element={<FixedPrice />} />
					<Route path="/voting/history" element={<ElectionHistory />} />
					<Route path="/contact-us" element={<ContactUs />} />
				</Route>

				<Route path="/voting" element={<Elections />} />
				<Route path="/history" element={<History />} />
				<Route path="/history/issued-credits" element={<IssueCredits />} />
				<Route path="/" element={<Dashboard />} />
				<Route path="/history/retired-credits" element={<RetiredCredits />} />
				<Route path="/history/forsale-credits" element={<ForsaleCredits />} />
				<Route
					path="/history/purchased-credits"
					element={<PurchasedCredits />}
				/>
				<Route path="/wallet" element={<Wallet />} />
			</Route>

			{/* user state */}
			<Route path="/user/purchased-credits" element={<PurchasedCredits />} />

			<Route path="/user/retired-credits" element={<RetiredCredits />} />

			<Route path="/user/forsale-credits" element={<ForsaleCredits />} />
			<Route path="/user/reserve-credits" element={<ReserveList />} />

			<Route path="*" element={<NotFound />} />
		</Routes>
	);
}
