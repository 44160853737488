import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import Cookies from "js-cookie";

export const balanceApiSlice = createApi({
	reducerPath: "balanceapi",
	baseQuery: fetchBaseQuery({
		baseUrl: "https://susa6.com",
		credentials: "include",

		prepareHeaders: (headers) => {
			const token = Cookies.get("accessToken");
			if (token) {
				headers.set("authorization", `Bearer ${token}`);
			}
			return headers;
		},
	}),
	tagTypes: ["Balance"],

	endpoints: () => ({}),
});
