import * as YUP from "yup";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { useFixedPriceAuctionMutation } from "../../app/services/api/auction";

const useFixedPriceAuction = () => {
	const [fixedPriceAuction, { isLoading }] = useFixedPriceAuctionMutation();

	const formik = useFormik({
		initialValues: {
			fixed_swap_ratio: "",
			credit_id: "",
			auction_count: "",
			amount: "",
			eth_address: "",
			start_date: "",
			end_date: "",
		},
		onSubmit: (values, { resetForm }) => {
			//! auction count not sended to the API
			fixedPriceAuction({
				creditId: values.credit_id,
				swapRatio: values.fixed_swap_ratio,
				startDate: values.start_date,
				endDate: values.end_date,
				ethAddress: values.eth_address,
				amount: values.amount,
			})
				.unwrap()
				.then((res) => {
					toast.success("auction successfully fixed ");
					resetForm();
				})
				.catch((err) => {
					toast.error(err.message || err.data.message);
				});
		},
		validationSchema: YUP.object({
			fixed_swap_ratio: YUP.string("this field is string")
				.trim()
				.nullable("nullable value")
				.required("Required"),
			credit_id: YUP.string("this field is string")
				.trim()
				.nullable("nullable value")
				.required("Required"),
			auction_count: YUP.string("this field is string")
				.trim()
				.nullable("nullable value")
				.required("Required"),
			start_date: YUP.date("this field is string").required("Required"),
			end_date: YUP.date("this field is string")
				.min(YUP.ref("start_date", "end Date must be after start time"))
				.required("Required"),
			eth_address: YUP.string("this field is string")
				.trim()
				.nullable("nullable value")
				.required("Required"),
			amount: YUP.string("this field is string")
				.matches(/^\d+$/, {
					message: "please just entered digit",
				})
				.trim()
				.nullable("nullable value")
				.required("Required"),
		}),
	});

	return [formik, isLoading];
};

export default useFixedPriceAuction;
