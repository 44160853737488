import { faker } from "@faker-js/faker";

const options = {
	responsive: true,
	plugins: {
		legend: {
			maxHeight: 10,
		},
		title: {
			display: false,
		},
		tooltip: {
			backgroundColor: "#fff",
			titleColor: "black",
			titleAlign: "center",
			bodyColor: "rgba(0,0,0,0.6)",
		},
	},
	scales: {
		x: {
			grid: {
				display: false,
				drawOnChartArea: false,
				drawTicks: false,
			},
		},
		y: {
			grid: {
				color: "#f2f2f2",
			},
		},
	},
};

const labels = ["January", "February", "March", "April", "May", "June", "July"];

const data = {
	labels,
	datasets: [
		{
			label: "total auctions",
			data: labels.map(() => faker.datatype.number({ min: 0, max: 2000 })),
			backgroundColor: "#062156",
			borderRadius: 8,
			hoverBackgroundColor: "#062156cc",
		},
	],
};

export { data, options };
