import { HiCreditCard } from "react-icons/hi";
import { MdOutlineHistory, MdSell, MdSpaceDashboard } from "react-icons/md";
import { IoWalletOutline } from "react-icons/io5";
import { AiFillSignal } from "react-icons/ai";
import { VscServerProcess } from "react-icons/vsc";
import { MdOutlineHowToVote } from "react-icons/md";
import { AiOutlineMessage } from "react-icons/ai";

import { MdOutlineQueuePlayNext } from "react-icons/md";

export const sidebarData__ADMIN = [
	{
		id: 1,
		path: "/",
		title: "dashboard",
		icon: <MdSpaceDashboard className="text-xl" />,
	},
	{
		id: 2,
		path: "/issue-credit",
		title: "Issue Offset",
		icon: (
			<>
				<svg
					version="1.0"
					xmlns="http://www.w3.org/2000/svg"
					width="20.000000pt"
					height="20.000000pt"
					viewBox="0 0 200.000000 200.000000"
					preserveAspectRatio="xMidYMid meet"
				>
					<g
						transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
						stroke="none"
					>
						<path
							d="M895 1971 c-112 -53 -740 -402 -763 -424 -13 -12 -34 -40 -45 -62
-22 -39 -22 -49 -22 -485 0 -436 0 -446 22 -485 11 -22 32 -50 46 -63 28 -27
734 -417 791 -438 85 -31 132 -19 316 83 l124 70 49 -53 c28 -31 72 -65 105
-81 49 -24 69 -28 142 -28 103 0 170 27 239 96 69 69 96 136 96 239 0 72 -4
94 -28 145 l-28 60 -2 450 c-2 446 -2 450 -24 492 -13 23 -38 54 -56 69 -41
32 -727 410 -781 430 -60 22 -114 17 -181 -15z m540 -267 c370 -204 381 -211
403 -254 22 -44 22 -49 22 -438 0 -216 -3 -392 -7 -390 -133 68 -226 74 -350
22 -31 -13 14 66 70 123 l61 63 -22 89 -21 89 19 68 c11 38 20 78 20 89 0 12
-25 46 -59 79 -53 52 -61 66 -81 137 -13 43 -28 83 -34 88 -6 5 -44 18 -85 29
-64 19 -81 29 -136 82 -35 33 -69 60 -75 60 -6 0 -46 -9 -88 -20 -77 -19 -78
-19 -152 0 -41 11 -81 20 -88 20 -8 0 -42 -27 -77 -60 -55 -53 -72 -63 -136
-81 -41 -12 -79 -26 -86 -32 -6 -7 -20 -45 -32 -86 -18 -64 -28 -81 -81 -136
-33 -35 -60 -69 -60 -75 0 -6 9 -47 20 -90 l20 -79 -20 -73 c-11 -40 -20 -79
-20 -86 0 -8 27 -42 60 -77 53 -55 63 -72 82 -136 11 -41 24 -79 30 -86 5 -6
41 -19 81 -29 64 -15 78 -24 139 -81 37 -34 75 -63 85 -63 10 0 49 9 87 20
l68 19 89 -21 89 -22 63 61 c63 62 137 101 122 65 -27 -63 -36 -114 -33 -181
l3 -74 -135 -76 c-203 -114 -159 -123 -624 134 -365 202 -380 212 -403 254
-23 44 -23 45 -23 449 0 390 1 407 21 448 11 24 35 52 52 63 43 26 718 396
737 404 8 4 35 5 60 3 37 -3 116 -42 425 -212z m-499 -170 c59 -15 69 -15 122
0 32 9 67 16 77 16 11 0 43 -23 71 -51 43 -42 64 -54 119 -70 37 -10 70 -20
72 -22 2 -3 12 -35 23 -72 16 -55 29 -78 74 -125 l55 -58 -20 -75 -21 -75 21
-73 21 -74 -55 -55 c-46 -47 -58 -66 -74 -123 -10 -37 -21 -70 -24 -74 -4 -3
-37 -14 -74 -24 -57 -16 -76 -28 -123 -74 l-55 -55 -74 21 -73 21 -75 -21 -75
-20 -58 55 c-47 45 -70 58 -125 74 -37 11 -69 21 -72 23 -2 2 -12 35 -22 72
-16 55 -28 76 -70 119 -28 28 -51 60 -51 70 0 10 7 45 16 78 14 56 14 66 -2
134 l-18 73 52 54 c42 45 55 68 73 130 l21 75 76 21 c62 18 84 31 126 71 28
27 57 50 64 50 7 0 42 -7 78 -16z m892 -1046 c22 -22 13 -43 -75 -173 -68
-100 -93 -130 -110 -130 -28 0 -163 111 -163 134 0 21 19 41 39 41 8 0 36 -18
62 -40 l48 -40 62 92 c34 51 69 101 77 111 18 20 43 22 60 5z"
						/>
						<path
							d="M607 1145 c-92 -63 -96 -202 -6 -266 63 -44 167 -35 209 18 37 47
-15 85 -64 48 -41 -31 -107 -9 -127 41 -14 37 11 89 50 102 36 13 56 8 88 -20
30 -28 67 -20 71 14 9 76 -139 118 -221 63z"
						/>
						<path
							d="M920 1138 c-55 -38 -74 -71 -74 -132 -1 -40 5 -62 20 -83 73 -98 195
-98 268 0 32 43 28 124 -8 171 -15 20 -40 43 -56 51 -44 22 -111 19 -150 -7z
m129 -75 c56 -47 24 -133 -49 -133 -71 0 -104 76 -55 125 29 30 74 33 104 8z"
						/>
						<path
							d="M1224 1141 c-37 -23 -59 -74 -43 -102 15 -29 47 -23 67 11 15 27 22
30 56 28 22 -2 41 -8 43 -14 2 -6 -30 -32 -70 -58 -83 -51 -117 -99 -97 -136
10 -18 21 -20 119 -20 116 0 139 9 126 50 -5 17 -15 20 -63 21 l-57 0 30 22
c68 50 95 84 95 119 0 36 -22 69 -57 87 -34 18 -114 13 -149 -8z"
						/>
					</g>
				</svg>
			</>
		),
	},
	{
		id: 3,
		path: "/history",
		title: "history",
		subTitle: [
			{
				id: "issued",
				path: "history/issued-credits",
				title: "Issued Offsets",
			},
			{
				id: "retired",
				path: "history/retired-credits",
				title: "Retired Offsets",
			},
			{
				id: "purchase",
				path: "history/purchased-credits",
				title: "Purchased Offsets",
			},
			{
				id: "forsale",
				path: "/history/forsale-credits",
				title: "Forsale Offsets",
				icon: <MdSell className="text-xl" />,
			},
		],
		icon: <MdOutlineHistory className="text-xl" />,
	},
	{
		id: 4,
		path: "/fixed-price",
		title: "Fixed-Price Auction",
		icon: (
			<>
				<svg
					version="1.0"
					xmlns="http://www.w3.org/2000/svg"
					width="20.000000pt"
					height="20.000000pt"
					viewBox="0 0 200.000000 200.000000"
					preserveAspectRatio="xMidYMid meet"
				>
					<g
						transform="translate(0.000000,227.000000) scale(0.100000,-0.100000)"
						stroke="none"
					>
						<path
							d="M1139 2031 c-42 -41 -49 -54 -49 -87 0 -37 -8 -47 -179 -218 -131
-131 -183 -177 -196 -173 -46 18 -155 -77 -155 -136 0 -45 18 -68 162 -211
139 -136 168 -152 225 -122 51 26 93 82 93 124 1 22 9 47 20 61 l20 23 35 -31
c26 -23 46 -31 73 -31 35 0 52 -15 370 -332 l332 -333 56 0 c52 0 58 2 95 42
35 38 39 47 39 95 l0 52 -332 333 c-323 323 -332 334 -333 373 -1 29 -9 48
-30 70 l-29 30 23 23 c14 16 29 23 45 20 56 -11 146 72 146 135 0 20 -5 44
-10 52 -5 8 -71 77 -146 153 -135 135 -137 137 -182 137 -40 0 -50 -5 -93 -49z
m239 -144 c68 -69 122 -132 122 -142 0 -23 -48 -75 -69 -75 -19 0 -271 250
-271 269 0 23 51 71 75 71 14 0 63 -43 143 -123z m-205 -409 c-92 -93 -170
-168 -174 -168 -4 0 -170 163 -199 196 -2 2 72 81 165 174 l170 170 102 -102
103 -103 -167 -167z m102 -98 c-44 -44 -85 -80 -91 -80 -6 0 -19 9 -29 20 -19
20 -18 22 65 105 l85 85 25 -25 25 -25 -80 -80z m-433 -17 c84 -83 128 -134
128 -148 0 -30 -53 -79 -77 -71 -27 9 -263 249 -263 268 0 15 63 78 77 78 3 0
64 -57 135 -127z m727 -194 c111 -111 201 -204 201 -208 0 -4 -20 -27 -45 -51
l-45 -44 -205 204 -205 204 47 48 c26 26 48 48 50 48 1 0 92 -91 202 -201z
m351 -351 c97 -100 107 -124 68 -165 -38 -40 -61 -31 -164 69 l-95 92 48 48
c26 26 48 48 50 48 1 0 43 -42 93 -92z"
						/>
						<path
							d="M444 651 c-34 -15 -84 -79 -85 -111 -1 -17 -2 -35 -3 -42 0 -6 -14
-15 -30 -19 -62 -17 -101 -75 -111 -165 -12 -106 -49 -99 479 -101 451 -2 457
-2 477 18 19 19 21 29 14 82 -3 33 -13 73 -20 88 -18 35 -71 79 -95 79 -15 0
-20 10 -26 47 -5 35 -17 57 -45 87 l-38 41 -248 2 c-136 1 -257 -2 -269 -6z
m504 -81 c12 -12 22 -26 23 -33 0 -7 2 -20 4 -29 3 -17 -15 -18 -269 -18 -150
0 -275 3 -278 6 -10 10 10 66 29 80 14 10 70 13 244 14 213 0 226 -1 247 -20z
m132 -177 c35 -33 49 -99 21 -105 -9 -2 -196 -2 -417 0 l-401 2 4 38 c2 25 13
46 33 65 l30 27 350 0 350 0 30 -27z"
						/>
					</g>
				</svg>
			</>
		),
	},
	{
		id: 5,
		path: "/wallet",
		title: "Wallet",
		icon: <IoWalletOutline className="text-xl" />,
	},
	{
		id: 6,
		path: "/voting",
		title: "Voting",
		subTitle: [
			{
				id: "VotingHistory",
				path: "voting/history",
				title: "History",
			},
		],
		icon: <MdOutlineHowToVote className="text-xl" />,
	},
];

export const sidebarData__SUSA__ADMIN = [
	{
		id: 1,
		path: "/",
		title: "dashboard",
		icon: <MdSpaceDashboard className="text-xl" />,
	},
	{
		id: 2,
		path: "/issue-credit",
		title: "Issue Offset",
		icon: (
			<>
				<svg
					version="1.0"
					xmlns="http://www.w3.org/2000/svg"
					width="20.000000pt"
					height="20.000000pt"
					viewBox="0 0 200.000000 200.000000"
					preserveAspectRatio="xMidYMid meet"
				>
					<g
						transform="translate(0.000000,200.000000) scale(0.100000,-0.100000)"
						stroke="none"
					>
						<path
							d="M895 1971 c-112 -53 -740 -402 -763 -424 -13 -12 -34 -40 -45 -62
-22 -39 -22 -49 -22 -485 0 -436 0 -446 22 -485 11 -22 32 -50 46 -63 28 -27
734 -417 791 -438 85 -31 132 -19 316 83 l124 70 49 -53 c28 -31 72 -65 105
-81 49 -24 69 -28 142 -28 103 0 170 27 239 96 69 69 96 136 96 239 0 72 -4
94 -28 145 l-28 60 -2 450 c-2 446 -2 450 -24 492 -13 23 -38 54 -56 69 -41
32 -727 410 -781 430 -60 22 -114 17 -181 -15z m540 -267 c370 -204 381 -211
403 -254 22 -44 22 -49 22 -438 0 -216 -3 -392 -7 -390 -133 68 -226 74 -350
22 -31 -13 14 66 70 123 l61 63 -22 89 -21 89 19 68 c11 38 20 78 20 89 0 12
-25 46 -59 79 -53 52 -61 66 -81 137 -13 43 -28 83 -34 88 -6 5 -44 18 -85 29
-64 19 -81 29 -136 82 -35 33 -69 60 -75 60 -6 0 -46 -9 -88 -20 -77 -19 -78
-19 -152 0 -41 11 -81 20 -88 20 -8 0 -42 -27 -77 -60 -55 -53 -72 -63 -136
-81 -41 -12 -79 -26 -86 -32 -6 -7 -20 -45 -32 -86 -18 -64 -28 -81 -81 -136
-33 -35 -60 -69 -60 -75 0 -6 9 -47 20 -90 l20 -79 -20 -73 c-11 -40 -20 -79
-20 -86 0 -8 27 -42 60 -77 53 -55 63 -72 82 -136 11 -41 24 -79 30 -86 5 -6
41 -19 81 -29 64 -15 78 -24 139 -81 37 -34 75 -63 85 -63 10 0 49 9 87 20
l68 19 89 -21 89 -22 63 61 c63 62 137 101 122 65 -27 -63 -36 -114 -33 -181
l3 -74 -135 -76 c-203 -114 -159 -123 -624 134 -365 202 -380 212 -403 254
-23 44 -23 45 -23 449 0 390 1 407 21 448 11 24 35 52 52 63 43 26 718 396
737 404 8 4 35 5 60 3 37 -3 116 -42 425 -212z m-499 -170 c59 -15 69 -15 122
0 32 9 67 16 77 16 11 0 43 -23 71 -51 43 -42 64 -54 119 -70 37 -10 70 -20
72 -22 2 -3 12 -35 23 -72 16 -55 29 -78 74 -125 l55 -58 -20 -75 -21 -75 21
-73 21 -74 -55 -55 c-46 -47 -58 -66 -74 -123 -10 -37 -21 -70 -24 -74 -4 -3
-37 -14 -74 -24 -57 -16 -76 -28 -123 -74 l-55 -55 -74 21 -73 21 -75 -21 -75
-20 -58 55 c-47 45 -70 58 -125 74 -37 11 -69 21 -72 23 -2 2 -12 35 -22 72
-16 55 -28 76 -70 119 -28 28 -51 60 -51 70 0 10 7 45 16 78 14 56 14 66 -2
134 l-18 73 52 54 c42 45 55 68 73 130 l21 75 76 21 c62 18 84 31 126 71 28
27 57 50 64 50 7 0 42 -7 78 -16z m892 -1046 c22 -22 13 -43 -75 -173 -68
-100 -93 -130 -110 -130 -28 0 -163 111 -163 134 0 21 19 41 39 41 8 0 36 -18
62 -40 l48 -40 62 92 c34 51 69 101 77 111 18 20 43 22 60 5z"
						/>
						<path
							d="M607 1145 c-92 -63 -96 -202 -6 -266 63 -44 167 -35 209 18 37 47
-15 85 -64 48 -41 -31 -107 -9 -127 41 -14 37 11 89 50 102 36 13 56 8 88 -20
30 -28 67 -20 71 14 9 76 -139 118 -221 63z"
						/>
						<path
							d="M920 1138 c-55 -38 -74 -71 -74 -132 -1 -40 5 -62 20 -83 73 -98 195
-98 268 0 32 43 28 124 -8 171 -15 20 -40 43 -56 51 -44 22 -111 19 -150 -7z
m129 -75 c56 -47 24 -133 -49 -133 -71 0 -104 76 -55 125 29 30 74 33 104 8z"
						/>
						<path
							d="M1224 1141 c-37 -23 -59 -74 -43 -102 15 -29 47 -23 67 11 15 27 22
30 56 28 22 -2 41 -8 43 -14 2 -6 -30 -32 -70 -58 -83 -51 -117 -99 -97 -136
10 -18 21 -20 119 -20 116 0 139 9 126 50 -5 17 -15 20 -63 21 l-57 0 30 22
c68 50 95 84 95 119 0 36 -22 69 -57 87 -34 18 -114 13 -149 -8z"
						/>
					</g>
				</svg>
			</>
		),
	},
	{
		id: 3,
		path: "/history",
		title: "history",
		subTitle: [
			{
				id: "issued",
				path: "history/issued-credits",
				title: "Issued Offsets",
			},
			{
				id: "retired",
				path: "history/retired-credits",
				title: "Retired Offsets",
			},
			{
				id: "purchase",
				path: "history/purchased-credits",
				title: "Purchased Offsets",
			},
			{
				id: "forsale",
				path: "/history/forsale-credits",
				title: "Forsale Offsets",
				icon: <MdSell className="text-xl" />,
			},
		],
		icon: <MdOutlineHistory className="text-xl" />,
	},
	{
		id: 4,
		path: "/fixed-price",
		title: "Fixed-Price Auction",
		icon: (
			<>
				<svg
					version="1.0"
					xmlns="http://www.w3.org/2000/svg"
					width="20.000000pt"
					height="20.000000pt"
					viewBox="0 0 200.000000 200.000000"
					preserveAspectRatio="xMidYMid meet"
				>
					<g
						transform="translate(0.000000,227.000000) scale(0.100000,-0.100000)"
						stroke="none"
					>
						<path
							d="M1139 2031 c-42 -41 -49 -54 -49 -87 0 -37 -8 -47 -179 -218 -131
-131 -183 -177 -196 -173 -46 18 -155 -77 -155 -136 0 -45 18 -68 162 -211
139 -136 168 -152 225 -122 51 26 93 82 93 124 1 22 9 47 20 61 l20 23 35 -31
c26 -23 46 -31 73 -31 35 0 52 -15 370 -332 l332 -333 56 0 c52 0 58 2 95 42
35 38 39 47 39 95 l0 52 -332 333 c-323 323 -332 334 -333 373 -1 29 -9 48
-30 70 l-29 30 23 23 c14 16 29 23 45 20 56 -11 146 72 146 135 0 20 -5 44
-10 52 -5 8 -71 77 -146 153 -135 135 -137 137 -182 137 -40 0 -50 -5 -93 -49z
m239 -144 c68 -69 122 -132 122 -142 0 -23 -48 -75 -69 -75 -19 0 -271 250
-271 269 0 23 51 71 75 71 14 0 63 -43 143 -123z m-205 -409 c-92 -93 -170
-168 -174 -168 -4 0 -170 163 -199 196 -2 2 72 81 165 174 l170 170 102 -102
103 -103 -167 -167z m102 -98 c-44 -44 -85 -80 -91 -80 -6 0 -19 9 -29 20 -19
20 -18 22 65 105 l85 85 25 -25 25 -25 -80 -80z m-433 -17 c84 -83 128 -134
128 -148 0 -30 -53 -79 -77 -71 -27 9 -263 249 -263 268 0 15 63 78 77 78 3 0
64 -57 135 -127z m727 -194 c111 -111 201 -204 201 -208 0 -4 -20 -27 -45 -51
l-45 -44 -205 204 -205 204 47 48 c26 26 48 48 50 48 1 0 92 -91 202 -201z
m351 -351 c97 -100 107 -124 68 -165 -38 -40 -61 -31 -164 69 l-95 92 48 48
c26 26 48 48 50 48 1 0 43 -42 93 -92z"
						/>
						<path
							d="M444 651 c-34 -15 -84 -79 -85 -111 -1 -17 -2 -35 -3 -42 0 -6 -14
-15 -30 -19 -62 -17 -101 -75 -111 -165 -12 -106 -49 -99 479 -101 451 -2 457
-2 477 18 19 19 21 29 14 82 -3 33 -13 73 -20 88 -18 35 -71 79 -95 79 -15 0
-20 10 -26 47 -5 35 -17 57 -45 87 l-38 41 -248 2 c-136 1 -257 -2 -269 -6z
m504 -81 c12 -12 22 -26 23 -33 0 -7 2 -20 4 -29 3 -17 -15 -18 -269 -18 -150
0 -275 3 -278 6 -10 10 10 66 29 80 14 10 70 13 244 14 213 0 226 -1 247 -20z
m132 -177 c35 -33 49 -99 21 -105 -9 -2 -196 -2 -417 0 l-401 2 4 38 c2 25 13
46 33 65 l30 27 350 0 350 0 30 -27z"
						/>
					</g>
				</svg>
			</>
		),
	},
	{
		id: 5,
		path: "/wallet",
		title: "Wallet",
		icon: <IoWalletOutline className="text-xl" />,
	},
	{
		id: 6,
		path: "/voting",
		title: "Voting",
		subTitle: [
			{
				id: "VotingHistory",
				path: "voting/history",
				title: "History",
			},
		],
		icon: <MdOutlineHowToVote className="text-xl" />,
	},
	{
		id: 7,
		path: "/leader-boards",
		title: "Leader Boards",

		icon: <AiFillSignal className="text-xl" />,
	},
	{
		id: 8,
		path: "/ticketing",
		title: "ticketing",
		subTitle: [
			{
				id: "onChain",
				path: "ticketing/on-chain",
				title: "onChain ticketing",
			},
			{
				id: "offChain",
				path: "ticketing/off-chain",
				title: "offChain ticketing",
			},
		],
		icon: <VscServerProcess className="text-xl" />,
	},
	{
		id: 7,
		path: "/contact-us",
		title: "Contact Us",
		icon: <AiOutlineMessage className="text-xl" />,
	},
];

export const sidebarData__USER = [
	{
		id: 1,
		path: "/",
		title: "dashboard",
		icon: <MdSpaceDashboard className="text-xl" />,
	},
	{
		id: 2,
		path: "/user/purchased-credits",
		title: "Purchased Offsets",
		icon: <HiCreditCard className="text-xl" />,
	},
	{
		id: 3,
		path: "/user/retired-credits",
		title: "Retired Offsets",
		icon: (
			<>
				<svg
					version="1.0"
					xmlns="http://www.w3.org/2000/svg"
					width="20.000000pt"
					height="20.000000pt"
					viewBox="0 0 200.000000 200.000000"
					preserveAspectRatio="xMidYMid meet"
				>
					<g
						transform="translate(0.000000,217.000000) scale(0.100000,-0.100000)"
						stroke="none"
					>
						<path
							d="M1350 2125 c-88 -25 -176 -85 -222 -153 l-16 -24 -39 25 c-65 43
-132 59 -230 56 -73 -3 -98 -9 -148 -33 -126 -62 -211 -188 -225 -334 -9 -90
-23 -106 -100 -115 -185 -22 -277 -232 -167 -380 42 -54 126 -99 182 -95 35 3
40 6 40 27 0 28 -15 37 -70 46 -83 14 -148 119 -125 204 19 73 91 131 162 131
42 0 96 27 120 60 11 16 21 56 27 108 11 100 45 175 103 227 124 113 300 119
430 15 20 -17 44 -30 52 -30 9 0 31 24 50 54 37 57 74 90 146 127 62 32 193
33 262 2 65 -30 133 -95 164 -159 24 -48 30 -54 58 -54 101 0 229 -66 290
-148 126 -172 62 -419 -132 -510 l-57 -27 -382 -3 c-372 -3 -383 -3 -394 -23
-21 -41 1 -48 160 -51 l146 -3 -1 -32 c0 -18 -1 -134 -2 -258 l-2 -224 -76 -3
c-130 -5 -129 -8 86 -223 102 -102 192 -185 201 -185 8 0 98 80 198 177 154
149 182 181 179 202 -3 25 -5 26 -85 29 l-83 3 0 258 0 258 55 12 c74 17 141
53 198 109 169 165 170 417 2 585 -49 50 -147 103 -204 112 -20 4 -46 8 -57
10 -12 3 -36 27 -54 54 -94 147 -279 221 -440 176z m430 -1338 c0 -207 3 -286
12 -295 7 -7 35 -12 62 -12 l51 0 -130 -130 -130 -130 -128 127 -128 128 33 3
c18 1 43 5 56 7 l22 5 0 284 c0 155 3 286 7 289 3 4 66 7 140 7 l133 0 0 -283z"
						/>
						<path
							d="M1144 1771 c-46 -28 -56 -64 -52 -185 3 -94 5 -107 27 -130 51 -54
130 -55 180 -2 30 32 38 88 16 106 -21 17 -49 3 -53 -29 -2 -12 -14 -29 -28
-37 -22 -15 -26 -15 -49 0 -24 16 -25 21 -25 110 0 69 4 97 14 105 19 16 67
10 76 -9 22 -47 29 -55 51 -55 51 0 27 100 -31 130 -39 20 -89 19 -126 -4z"
						/>
						<path
							d="M1404 1756 l-34 -34 0 -115 c0 -109 1 -117 25 -146 35 -41 81 -55
130 -39 63 21 79 54 83 168 6 148 -28 200 -129 200 -33 0 -47 -6 -75 -34z
m126 -55 c5 -11 10 -56 10 -100 0 -68 -3 -84 -20 -101 -24 -24 -39 -25 -66 -6
-16 12 -19 27 -19 104 0 61 4 94 14 106 19 23 68 21 81 -3z"
						/>
						<path
							d="M1790 1725 c-20 -24 -1 -49 41 -56 69 -11 107 -49 118 -116 7 -40 29
-60 56 -49 24 9 16 91 -15 140 -45 73 -166 122 -200 81z"
						/>
						<path
							d="M1681 1523 c-38 -35 -48 -74 -26 -97 20 -19 41 -10 59 25 16 32 60
34 64 2 3 -17 -12 -33 -62 -70 -69 -51 -79 -64 -69 -88 4 -13 23 -15 102 -13
90 3 96 4 99 25 3 23 -30 49 -48 38 -20 -13 -9 8 15 26 50 40 41 135 -16 164
-44 23 -85 19 -118 -12z"
						/>
						<path
							d="M1959 1429 c-12 -23 1 -69 20 -69 28 0 41 14 41 45 0 21 -6 35 -16
39 -25 10 -33 7 -45 -15z"
						/>
						<path
							d="M744 1386 c-8 -32 12 -58 40 -54 17 2 21 10 21 38 0 31 -3 35 -27 38
-22 3 -29 -2 -34 -22z"
						/>
						<path
							d="M580 1296 c-15 -19 -9 -56 12 -69 34 -22 66 41 36 71 -16 16 -33 15
-48 -2z"
						/>
						<path
							d="M756 1267 c-24 -18 -20 -244 5 -260 9 -6 24 -7 33 -4 14 5 16 25 16
131 0 112 -2 126 -19 135 -13 7 -23 7 -35 -2z"
						/>
						<path
							d="M932 1268 c-18 -18 -15 -68 4 -75 39 -15 69 40 39 71 -20 19 -27 20
-43 4z"
						/>
						<path
							d="M577 1163 c-4 -3 -7 -52 -7 -108 0 -81 3 -105 16 -116 11 -9 20 -10
32 -2 13 8 18 29 20 99 4 112 -1 134 -32 134 -12 0 -26 -3 -29 -7z"
						/>
						<path
							d="M933 1133 c-9 -3 -13 -33 -13 -98 0 -87 1 -94 22 -101 34 -10 48 19
48 102 0 86 -17 114 -57 97z"
						/>
						<path
							d="M653 896 c-185 -60 -309 -251 -288 -443 14 -132 116 -272 242 -331
52 -24 69 -27 168 -27 103 0 114 2 177 32 88 41 160 113 201 201 30 62 32 75
32 172 0 97 -2 110 -32 172 -41 88 -113 160 -201 201 -58 27 -79 32 -162 34
-60 2 -110 -2 -137 -11z m249 -76 c73 -27 150 -97 185 -168 25 -50 28 -68 28
-152 0 -84 -3 -102 -28 -152 -17 -35 -48 -75 -80 -103 -176 -154 -441 -99
-544 113 -23 48 -27 70 -27 142 0 72 4 94 27 142 43 88 126 161 217 189 51 15
167 10 222 -11z"
						/>
						<path
							d="M680 793 c-96 -33 -177 -117 -201 -207 -16 -60 -6 -173 20 -224 93
-181 333 -228 482 -94 75 67 103 128 103 227 1 68 -3 89 -27 137 -69 140 -235
211 -377 161z m174 -65 c55 -16 138 -99 154 -154 47 -159 -66 -314 -228 -314
-139 0 -240 102 -240 242 0 164 153 273 314 226z"
						/>
						<path
							d="M756 695 c-9 -9 -16 -21 -16 -26 0 -5 -14 -22 -32 -38 -25 -22 -32
-37 -32 -65 0 -45 42 -91 91 -101 36 -6 53 -29 35 -47 -16 -16 -59 -1 -65 22
-6 23 -39 27 -56 6 -16 -20 10 -82 38 -91 12 -3 21 -12 21 -19 0 -7 6 -20 13
-30 16 -22 57 -14 57 10 0 9 16 30 35 48 28 27 35 41 35 70 0 45 -44 96 -84
96 -24 0 -56 23 -56 40 0 9 25 20 46 20 8 0 19 -11 26 -24 13 -30 36 -40 54
-25 24 20 16 59 -18 92 -18 18 -37 41 -43 51 -14 27 -30 30 -49 11z"
						/>
					</g>
				</svg>
			</>
		),
	},
	{
		id: 4,
		path: "/user/forsale-credits",
		title: "Forsale Offsets",
		icon: <MdSell className="text-xl" />,
	},

	{
		id: 5,
		path: "/user/reserve-credits",
		title: "Reserve Offsets List",
		icon: <MdOutlineQueuePlayNext className="text-xl" />,
	},
];
