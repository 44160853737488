import { motion } from "framer-motion";

import { useEffect, useState } from "react";

import { useReserveAuctionsQuery } from "../../app/services/api/auction";
import sliceData from "../../helpers/sliceData";
import Loading from "../../Components/UiElements/Loading";
import ErrorCard from "../../Components/Cards/ErrorCard";
import Table from "../../Components/table/Table";
import ClientPagination from "../../Components/table/ClientPagination";

const rowsPerPage = 10;

export default function ReserveList() {
	const [page, setPage] = useState(1);
	const [slicedData, setSlicedData] = useState([]);

	const { data, isLoading, isFetching, error, isError } =
		useReserveAuctionsQuery();

	useEffect(() => {
		const sliced = sliceData(data, page, rowsPerPage);
		setSlicedData(sliced);
	}, [data, page]);

	console.log(data);
	return (
		<motion.section
			initial={{ y: 10, opacity: 0.4, scale: 0.7 }}
			animate={{ y: 0, opacity: 1, scale: 1 }}
			transition={{ stiffness: 100 }}
			className="custom_container w-full overflow-hidden flex flex-col gap-4"
		>
			<div className="grid grid-cols-1 gap-6">
				<div className="flex flex-col gap-1">
					<h1 className="text-xl font-semibold">Your Reservation List</h1>
					<p className="text-sm text-gray-500">
						In this page, you can see the list of reservation offsets
					</p>
				</div>

				{isLoading || isFetching ? (
					<Loading />
				) : isError ? (
					<ErrorCard error={error} />
				) : (
					<>
						<Table
							emptyHandler
							data={slicedData}
							headers={[
								"auctionId",
								"createdTime",
								"fee",
								"status",
								"amount",
								"totalPrice",
								"reservationType",
								"stableCoinName",
							]}
						/>
						{data?.length > rowsPerPage && (
							<ClientPagination
								data={data}
								slicedData={slicedData}
								rowsPerPage={rowsPerPage}
								page={page}
								setPage={setPage}
							/>
						)}
					</>
				)}
			</div>
		</motion.section>
	);
}
