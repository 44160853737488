import { balanceApiSlice } from "../../balanceApiSlice";

const balanceApi = balanceApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getBalance: builder.query({
			query: (user) => `/governance/getClientBalance/${user}`,
			providesTags: ["Balance"],
		}),
		sendGT: builder.mutation({
			query: (data) => ({
				url: "api/transfer",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["Balance"],
		}),
		getHistory: builder.query({
			query: (user) => `governance/getUserHistory/${user}`,
			providesTags: ["Balance"],
		}),
	}),
});

export const { useGetBalanceQuery, useGetHistoryQuery, useSendGTMutation } =
	balanceApi;
