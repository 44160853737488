import calculateRange from "../../helpers/calculateRange";

import ResponsivePagination from "react-responsive-pagination";

const ClientPagination = ({ data, slicedData, rowsPerPage, page, setPage }) => {
	const range = calculateRange(data, rowsPerPage);

	return (
		<div className=" flex flex-col items-center gap-2 w-full">
			<p className="text-xs text-slate-500 text-center">
				showing
				<span className="px-1 text-md font-bold">
					{(page - 1) * rowsPerPage + 1}
				</span>
				to
				<span className="px-1 text-md font-bold">
					{page === range.length
						? page * rowsPerPage - (rowsPerPage - slicedData?.length)
						: page * rowsPerPage}
				</span>
				from<span className="mx-2 text-md">{data?.length}</span>results
			</p>
			<ResponsivePagination
				disabledLinkClassName="text-secondary"
				pageItemClassName="text-secondary"
				activeItemClassName="[&>*:first-child]:bg-secondary [&>*:first-child]:text-white"
				current={page}
				onPageChange={setPage}
				total={range.length}
			/>
		</div>
	);
};

export default ClientPagination;
