import { Link, useLocation } from "react-router-dom";

// icons
import { AiOutlineUserAdd } from "react-icons/ai";
import { useSelector } from "react-redux";
import { currentUser } from "../../app/services/authSlice";
import { IoIosArrowForward } from "react-icons/io";

export default function Navbar() {
	const { pathname } = useLocation();
	const currentPath = pathname.split("/").slice(1);
	console.log(pathname.split("/").slice(1));
	const user = useSelector(currentUser);
	let path = "";
	return (
		<nav
			className="w-full custom_container border-b-2 border-gray-100 py-4 
		flex justify-between gap-4 items-center"
		>
			<div className="uppercase lg:text-lg md:text-sm text-xs flex items-center flex-wrap">
				{pathname === "/" ? (
					<p>Dashboard</p>
				) : (
					currentPath.map((link, index) => {
						path = path + "/" + link;
						return (
							<div key={index} className="flex items-center">
								<Link to={`${path}`}>{link.replace("-", " ")}</Link>
								{currentPath.length !== index + 1 && (
									<IoIosArrowForward className="mx-2" />
								)}
							</div>
						);
					})
				)}
			</div>

			<div className="bg-secondary/10 text-secondary px-4 py-2 rounded-lg text-xs flex items-center gap-1">
				<AiOutlineUserAdd className="text-lg" />
				<span>{user?.username}</span>
			</div>
		</nav>
	);
}
