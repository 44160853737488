import { motion } from "framer-motion";
import { useGetContactsQuery } from "../../app/services/api/contactUs";
import Loading from "../../Components/UiElements/Loading";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

export default function ContactUs() {
	const { data, isLoading, isError, error } = useGetContactsQuery();
	return (
		<motion.section
			initial={{ y: 10, opacity: 0.4, scale: 0.7 }}
			animate={{ y: 0, opacity: 1, scale: 1 }}
			transition={{ stiffness: 100 }}
			className="custom_container relative"
		>
			{isLoading ? (
				<Loading />
			) : isError ? (
				<p>{error.data.message}</p>
			) : (
				<ResponsiveMasonry
					columnsCountBreakPoints={{ 350: 1, 750: 2, 1200: 3 }}
				>
					<Masonry gutter={"32px"}>
						{data?.map((message) => (
							<div className="flex flex-col gap-2 border rounded-xl px-4 py-2">
								<div className="space-y-1">
									<p className="text-sm font-semibold">{message?.email}</p>
									<span className="text-muted text-xs">{message?.phone}</span>
								</div>
								<div className="flex flex-col">
									<p className="text-xs">{message?.description}</p>
									<span className="self-end text-xs">
										{new Date(message?.date).toLocaleDateString()}
									</span>
								</div>
							</div>
						))}
					</Masonry>
				</ResponsiveMasonry>
			)}
		</motion.section>
	);
}
