import useAddVotingIssue from "../../Hooks/formik/useAddVotingIssue";
import DropDown from "../DropDown";

const AddNewForm = () => {
	const [formik, loading] = useAddVotingIssue();

	return (
		<div className="mt-2 pb-8 pt-4 px-6 border rounded-xl">
			<h2 className="text-lg text-slate-800 mb-2">Add New Voting Issue</h2>
			<form
				onSubmit={formik.handleSubmit}
				className="w-full flex flex-col gap-4"
			>
				<DropDown
					label="tag"
					data={["Fee", "Policy", "Role"]}
					value={formik.values.tag}
					name="tag"
					setter={formik.setFieldValue}
				/>

				<div className="flex flex-col gap-1">
					<textarea
						name="description"
						id="description"
						rows="5"
						value={formik.values.description}
						onChange={formik.handleChange("description")}
						placeholder="Description"
						className="border-2 border-dashed rounded-lg outline-none px-4 py-2 text-xs"
					></textarea>

					{formik.errors.description && formik.touched.description && (
						<span className="text-xs text-system-error">
							{formik.errors?.description}
						</span>
					)}
				</div>

				<div className="flex gap-2 lg:col-span-2">
					<button
						type="submit"
						className="rounded-lg border border-primary px-4 py-3 col-span-2 text-primary
                    hover:bg-primary hover:text-slate-100 transition-all duration-300 flex-1"
					>
						{loading ? "sending ..." : "send"}
					</button>
				</div>
			</form>
		</div>
	);
};

export default AddNewForm;
