import { useState } from "react";
import { useGetCreditsQuery } from "../../app/services/api/credit";

import { Link } from "react-router-dom";

// COMPONENTS
import DropDown from "../DropDown";
import Table from "../table/Table";
import Loading from "../UiElements/Loading";
import ErrorCard from "../Cards/ErrorCard";
import { useSelector } from "react-redux";
import { currentUser } from "../../app/services/authSlice";

export default function CreditListTable() {
	const [creditDropDown, setCreditDropDown] = useState("issued");

	const user = useSelector(currentUser);

	const { data, isLoading, isError, error } = useGetCreditsQuery(undefined, {
		refetchOnMountOrArgChange: true,
	});

	return (
		<div className="flex flex-col gao-2">
			<div className="py-2 flex justify-between gap-4 flex-wrap items-center">
				<div>
					<h2 className="text-xl font-semibold">Offset Lists</h2>
					<p className="text-xs">
						Select a Offset type to see the most recently created
					</p>
				</div>

				<div className="w-full lg:w-auto flex items-center justify-between lg:justify-start gap-2">
					<div className="self-center">
						<DropDown
							data={["issued", "purchased", "retired"]}
							value={creditDropDown}
							setter={setCreditDropDown}
						/>
					</div>
					<Link
						className="bg-transparent border border-primary text-primary px-4 py-2 rounded-lg
						text-xs"
						to={
							user?.roles === "Admin" || user?.roles === "SusaAdmin"
								? `/history/${creditDropDown}-credits`
								: `/user/${creditDropDown}-credits`
						}
					>
						view all
					</Link>
				</div>
			</div>

			{isLoading ? (
				<Loading />
			) : isError ? (
				<ErrorCard error={error?.message} key="credit Error" />
			) : data?.filter(
					(credit) => credit?.status === creditDropDown.toUpperCase(),
			  ).length === 0 ? (
				<div className="flex items-center justify-center h-full bg-gray-100/60 rounded-xl">
					<span className="text-xs py-8 md:py-4">no data available yet</span>
				</div>
			) : (
				<Table
					emptyHandler={false}
					headers={[
						"vintageStartDate",
						"vintageEndDate",
						"forsaleStart",
						"forsaleEnd",
						"susaId",
						"status",
					]}
					data={data
						?.filter(
							(credit) => credit?.status === creditDropDown.toUpperCase(),
						)
						.slice(0, 6)}
				/>
			)}
		</div>
	);
}
