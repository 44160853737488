import { apiSlice } from "../../apiSlice";

const auctionApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getAuctions: builder.query({
			query: () => "fpauction/getAuctions",
			providesTags: ["fixed"],
		}),
		reserveAuctions: builder.query({
			query: () => "fpauction/reserveCredit",
		}),
		fixedPriceAuction: builder.mutation({
			query: (data) => ({
				url: "fpauction/startauction",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["fixed"],
		}),
	}),
});

export const {
	useGetAuctionsQuery,
	useReserveAuctionsQuery,
	useFixedPriceAuctionMutation,
} = auctionApi;
