import React, { useState } from "react";

const Switch = () => {
	const [isChecked, setIsChecked] = useState(false);

	const handleCheck = () => {
		setIsChecked(!isChecked);
	};

	return (
		<div
			className="w-8 h-5 bg-gray-200 rounded-full relative cursor-pointer transition-all duration-300"
			onClick={handleCheck}
		>
			<span
				className={`h-3 w-3 rounded-full absolute ${
					isChecked ? "right-1 bg-muted" : "left-1 bg-white"
				} inset-y-1/2 -translate-y-1/2 transition-all duration-300`}
			></span>
		</div>
	);
};

export default Switch;
