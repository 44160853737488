import React from "react";
import { motion } from "framer-motion";
import Table from "../../Components/table/Table";
import userPurchased from "../../Data/usersPurchased";
import userSales from "../../Data/userSales";

export default function LeaderBoards() {
	return (
		<motion.section
			initial={{ y: 10, opacity: 0.4, scale: 0.7 }}
			animate={{ y: 0, opacity: 1, scale: 1 }}
			transition={{ stiffness: 100 }}
			className="custom_container w-full grid grid-cols-1 lg:grid-cols-2 gap-x-16 overflow-hidden"
		>
			<div className="flex flex-col gap-4">
				<p className="text-xl font-semibold">Total User Purchase</p>
				<Table
					data={userPurchased}
					headers={["id", "username", "roles", "count"]}
				/>
			</div>

			<div className="flex flex-col gap-4">
				<p className="text-xl font-semibold">Total User Sales</p>
				<Table
					data={userSales}
					headers={["id", "username", "roles", "count"]}
				/>
			</div>
		</motion.section>
	);
}
