import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

import { useGetCreditsQuery } from "../../app/services/api/credit";

// COMPONENTS
import Loading from "../../Components/UiElements/Loading";
import Table from "../../Components/table/Table";
import DetailsModal from "../../Components/UiElements/DetailsModal";
import ErrorCard from "../../Components/Cards/ErrorCard";
import ClientPagination from "../../Components/table/ClientPagination";

import sliceData from "../../helpers/sliceData";

const rowsPerPage = 6;

export default function IssueCredits() {
	const [page, setPage] = useState(1);
	const [fetchedData, setFetchedData] = useState([]);
	const [slicedData, setSlicedData] = useState([]);
	const { data, isLoading, isFetching, isError, error } = useGetCreditsQuery();

	const [tableDataSelect, setTableDataSelect] = useState({});
	const [modalIsOpen, setModalIsOpen] = useState(false);

	useEffect(() => {
		const filterd = data?.filter((item) => item.status === "ISSUED");
		setFetchedData(filterd);
	}, [data]);

	useEffect(() => {
		setModalIsOpen(false);
		const sliced = sliceData(fetchedData, page, rowsPerPage);
		setSlicedData(sliced);
	}, [fetchedData, page]);

	useEffect(() => {
		if (Object.keys(tableDataSelect).length > 0) {
			setModalIsOpen(true);
		} else {
			setModalIsOpen(false);
		}
	}, [tableDataSelect]);

	return (
		<motion.section
			initial={{ y: 10, opacity: 0.4, scale: 0.7 }}
			animate={{ y: 0, opacity: 1, scale: 1 }}
			transition={{ stiffness: 100 }}
			className="custom_container flex flex-col gap-2 relative overflow-hidden"
		>
			<div className="flex flex-col gap-2 lg:hidden">
				<Link to="/history/retired-credits" className="">
					Retired Offsets
				</Link>
				<Link to="/history/purchased-credits" className="">
					Purchased Offsets
				</Link>
			</div>

			<div className="flex gap-4 flex-wrap overflow-x-scroll">
				<div className="grid grid-cols-1">
					<div className="mb-2">
						<h2 className="mb-1 text-lg lg:text-xl xl:text-2xl font-semibold">
							Issued Offsets
						</h2>
						{fetchedData?.length > 0 && (
							<p className="text-slate-600 text-xs">
								select on each rows to see details
							</p>
						)}
					</div>

					{isLoading || isFetching ? (
						<Loading />
					) : isError ? (
						<ErrorCard error={error} />
					) : (
						<>
							<Table
								emptyHandler
								data={slicedData}
								customButtonId={"creditId"}
								headers={[
									"vintageStartDate",
									"vintageEndDate",
									"forsaleStart",
									"forsaleEnd",
									"susaId",
									"status",
								]}
								setter={setTableDataSelect}
							/>
							{fetchedData?.length > rowsPerPage && (
								<ClientPagination
									data={fetchedData}
									slicedData={slicedData}
									rowsPerPage={rowsPerPage}
									page={page}
									setPage={setPage}
								/>
							)}
						</>
					)}
				</div>

				{modalIsOpen && (
					<DetailsModal
						title="Offset details"
						data={(({
							susaId,
							creditType,
							blockIdStart,
							blockIdEnd,
							programeCode,
							projectTypeCode,
							vintageStartDate,
							vintageEndDate,
							batchNumber,
						}) => ({
							susaId,
							creditType,
							blockIdStart,
							blockIdEnd,
							programeCode,
							projectTypeCode,
							vintageStartDate,
							vintageEndDate,
							batchNumber,
						}))(tableDataSelect)}
						setModalOpen={setModalIsOpen}
						setTableDataSelect={setTableDataSelect}
					/>
				)}
			</div>
		</motion.section>
	);
}
