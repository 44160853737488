// form handlers
import { useFormik } from "formik";
import * as YUP from "yup";

import {
	useStartMultipleElectionMutation,
	useStartSingleElectionMutation,
} from "../../app/services/api/evote";

import toast from "react-hot-toast";

const useStartElection = (votableItems) => {
	const [startElection, { isLoading: singleLoading }] =
		useStartSingleElectionMutation();
	const [startMultipleElection, { isLoading: multipleLoading }] =
		useStartMultipleElectionMutation();
	const formik = useFormik({
		initialValues: {
			votingType: "Majority",
			voteCounting: "Simple",
			participant_level: "",
			votable_id: [],
			startDate: "",
			endDate: "",
		},
		onSubmit: (values, { resetForm }) => {
			if (votableItems.map((items) => items.votableId).length === 1) {
				startElection({
					startDate: values.startDate,
					endDate: values.endDate,
					voteCountingRule: values.voteCounting,
					votingType: values.votingType,
					votingIssueId: votableItems.map((items) => items.votableId),
					levelParticipants: values.participant_level,
				})
					.unwrap()
					.then((res) => {
						toast.success("Single Vote Successfuly Started!", {
							position: "bottom-center",
						});
						resetForm();
					})
					.catch((err) => {
						toast.error(err.error || err.data?.message, {
							position: "bottom-center",
						});
					});
			} else {
				startMultipleElection({
					startDate: values.startDate,
					endDate: values.endDate,
					voteCountingRule: values.voteCounting,
					votingType: values.votingType,
					votingIssueIds: votableItems.map((items) => items.votableId),
					levelParticipants: values.participant_level,
				})
					.unwrap()
					.then((res) => {
						toast.success("Multiple Vote Successfuly Started!", {
							position: "bottom-center",
						});
						resetForm();
					})
					.catch((err) => {
						console.log(err);
						toast.error(err.error || err.data?.message, {
							position: "bottom-center",
						});
					});
			}
		},
		validationSchema: YUP.object({
			participant_level: YUP.string()
				.matches(/[0-9]+/, "data must be Numbers")
				.required("this field is required"),
			startDate: YUP.date("enter data as Date format").required(
				"this field is required",
			),
			endDate: YUP.date("enter data as Date format")
				.min(YUP.ref("startDate", "end Date must be after start time"))
				.required("this field is required"),
		}),
	});

	return [formik, singleLoading, multipleLoading];
};

export default useStartElection;
