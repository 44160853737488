import { useEffect, useState } from "react";

// COMPONENTS
import Table from "../../Components/table/Table";
import { Link } from "react-router-dom";
import DetailsModal from "../../Components/UiElements/DetailsModal";
import Loading from "../../Components/UiElements/Loading";
import ErrorCard from "../../Components/Cards/ErrorCard";

import { motion } from "framer-motion";
import { toast } from "react-hot-toast";

// query
import {
	useGetCreditsQuery,
	useRetireCreditMutation,
} from "../../app/services/api/credit";

// logo
import SusaText from "../../Assets/susa_text.svg";
import SusaLogo from "../../Assets/susa_logo.svg";
import sliceData from "../../helpers/sliceData";
import ClientPagination from "../../Components/table/ClientPagination";

const rowsPerPage = 6;

export default function RetiredCredits(props) {
	const [page, setPage] = useState(1);
	const [fetchedData, setFetchedData] = useState(null);
	const [slicedData, setSlicedData] = useState([]);

	const [confirmModal, setConfirmModal] = useState(false);
	const [retiredCredit, setRetiredCredit] = useState({});
	const [statementText, setStatementText] = useState(
		"im retired credit and I hope nature survives",
	);

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [tableDataSelect, setTableDataSelect] = useState({});

	const { data, isLoading, isFetching, isError, error } = useGetCreditsQuery(
		page,
		5,
	);
	const [retireCredit, { isLoading: retiredLoading }] =
		useRetireCreditMutation();

	useEffect(() => {
		const filterd = data?.filter((item) => item.status === "PURCHASED");
		setFetchedData(filterd);
	}, [data]);

	useEffect(() => {
		setModalIsOpen(false);
		const sliced = sliceData(fetchedData, page, rowsPerPage);
		setSlicedData(sliced);
	}, [fetchedData, page]);

	useEffect(() => {
		if (Object.keys(tableDataSelect).length > 0) {
			setModalIsOpen(true);
		} else {
			setModalIsOpen(false);
		}
	}, [tableDataSelect]);

	return (
		<motion.section
			initial={{ y: 10, opacity: 0.4, scale: 0.7 }}
			animate={{ y: 0, opacity: 1, scale: 1 }}
			transition={{ stiffness: 100 }}
			className="custom_container lg:w-max"
		>
			{/* confirm Modal */}
			{confirmModal && (
				<motion.aside
					initial={{ opacity: 0.4 }}
					animate={{ opacity: 1 }}
					exit={{ opacity: 0 }}
					transition={{ duration: 0.4 }}
					className="absolute max-h-screen overflow-hidden top-0 left-0 w-full h-full z-50 bg-black/50 glassmorphism flex justify-center items-center"
				>
					<div className="max-h-screen overflow-scroll flex flex-col gap-4 md:w-96 w-[90%] md:py-6 py-4 px-2 bg-slate-50 rounded-xl">
						<div className="flex flex-col items-center self-center gap2">
							<img src={SusaLogo} alt="logo" />
							<img src={SusaText} alt="text_logo" />
						</div>

						<p className="text-center text-sm leading-7">
							<span className="text-3xl font-semibold">congratulations!👏</span>{" "}
							<br /> you did the right thing
						</p>

						<div className="flex flex-col gap-1">
							<label htmlFor="retirement_statement" className="text-xs ml-1">
								Retirement Statement
							</label>
							<textarea
								className="w-full border rounded-xl p-2 text-sm outline-none"
								name="retirement_statement"
								id="statement"
								cols="30"
								rows="5"
								maxLength={300}
								value={statementText}
								onChange={(e) => setStatementText(e.target.value)}
							></textarea>
							<span className="text-[11px] ml-auto text-gray-600">
								{statementText.length}/300
							</span>
						</div>

						<p className="text-xs">{new Date().toLocaleString()}</p>

						<p className="text-xs">
							You will receive a <strong>TxID</strong> after your credit is
							retired. <br /> Find it in the retiredCredits tab and use it to
							explore the{" "}
							<Link
								className="underline underline-offset-4 hover:text-primary"
								to="http://susa6.com:8080"
								target="_blank"
							>
								hyperledger explorer
							</Link>
						</p>

						<div className="grid grid-cols-2 gap-2">
							<button
								type="button"
								className="px-4 py-2 rounded-lg text-system-error border border-system-error 
								hover:bg-system-error hover:text-white transition-all duration-300"
								onClick={() => setConfirmModal(false)}
							>
								Cancel
							</button>

							<button
								className="px-4 py-2 bg-primary rounded-lg text-white hover:bg-primary/80 transition-all duration-300"
								onClick={() => {
									retireCredit({
										creditId: retiredCredit.creditId,
										statement: statementText,
									})
										.unwrap()
										.then((res) => {
											setConfirmModal(false);
											setRetiredCredit({});
											toast.success(
												"congratulation, Issue Retired successfully",
											);
										})
										.catch((err) => {
											setConfirmModal(false);
											toast.error(err.data.message || err.message);
										});
								}}
							>
								{retiredLoading ? "loading ..." : "Retire Credit"}
							</button>
						</div>
					</div>
				</motion.aside>
			)}
			<div className="flex flex-wrap items-center justify-between gap-4 mb-8 md:hidden">
				<Link
					to="/history/issued-credits"
					className="px-4 py-2 rounded-2xl bg-lime-100 hover:scale-95 hover:bg-lime-300 transition-all duration-300"
				>
					Issued Offsets
				</Link>
				<Link
					to="/history/retired-credits"
					className="px-4 py-2 rounded-2xl bg-lime-100 hover:scale-95 hover:bg-lime-300 transition-all duration-300"
				>
					Retired Offsets
				</Link>
			</div>

			<div className="flex gap-4 flex-wrap">
				<div className="">
					<div className="mb-8">
						<h2 className="mb-1 text-lg lg:text-xl xl:text-2xl font-semibold">
							Purchased Offsets
						</h2>
						{fetchedData?.length > 0 && (
							<p className="text-slate-600 text-xs">
								select on each rows to see details
							</p>
						)}
					</div>
					<div className="w-full max-w-max">
						{isLoading || isFetching ? (
							<Loading />
						) : isError ? (
							<ErrorCard error={error} />
						) : (
							<>
								<Table
									emptyHandler
									data={slicedData}
									customButtonId={"creditId"}
									headers={[
										"vintageStartDate",
										"vintageEndDate",
										"forsaleStart",
										"forsaleEnd",
										"susaId",
										"status",
									]}
									additionalCols={["retire"]}
									buttons={[
										<RetireButton
											setConfirmModal={setConfirmModal}
											data={data}
											setRetiredCredit={setRetiredCredit}
											key="retire__button"
										/>,
									]}
									setter={setTableDataSelect}
								/>
								{fetchedData?.length > rowsPerPage && (
									<ClientPagination
										data={fetchedData}
										slicedData={slicedData}
										rowsPerPage={rowsPerPage}
										page={page}
										setPage={setPage}
									/>
								)}
							</>
						)}
					</div>
				</div>

				{modalIsOpen && (
					<DetailsModal
						title="Offset details"
						data={(({
							susaId,
							creditType,
							blockIdStart,
							blockIdEnd,
							programeCode,
							projectTypeCode,
							vintageStartDate,
							vintageEndDate,
							batchNumber,
						}) => ({
							susaId,
							creditType,
							blockIdStart,
							blockIdEnd,
							programeCode,
							projectTypeCode,
							vintageStartDate,
							vintageEndDate,
							batchNumber,
						}))(tableDataSelect)}
						setModalOpen={setModalIsOpen}
						setTableDataSelect={setTableDataSelect}
					/>
				)}
			</div>
		</motion.section>
	);
}

const RetireButton = ({ setConfirmModal, data, setRetiredCredit }) => {
	const handleClick = (e) => {
		const creditId = e.target.parentElement.id;
		const retiredCredit = data?.find((credit) => credit.creditId === creditId);
		setRetiredCredit(retiredCredit);
		setConfirmModal(true);
	};
	return (
		<button
			type="button"
			onClick={(e) => handleClick(e)}
			className="px-4 py-1 rounded-xl bg-orange-200 text-orange-600 font-semibold
	border border-orange-200 shadow-xl shadow-orange-100 hover:bg-transparent hover:text-orange-600
	transition-all duration-300"
		>
			Retired
		</button>
	);
};
