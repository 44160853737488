import Loading from "./Loading";

export default function CardBadge({
	colorTheme,
	title,
	count,
	type,
	icon,
	loading,
	error,
}) {
	return (
		<div
			className={`
			${colorTheme === "transparent" && "bg-slate-100 hover:bg-slate-300"}
            ${colorTheme === "green" && "bg-secondary/10 hover:bg-secondary/20"}
            ${
							colorTheme === "red" &&
							"bg-system-error/10 hover:bg-system-error/20"
						}
            ${
							colorTheme === "yellow" &&
							"bg-system-danger/10 hover:bg-system-danger/20"
						}
            
            ${
							!colorTheme && "bg-primary/10 hover:bg-primary/20"
						} p-2 rounded-xl flex gap-2 select-none transition-all duration-300`}
		>
			<div
				className={`p-2 rounded-full self-start 
                ${colorTheme === "green" && "bg-secondary/10"}

                ${colorTheme === "red" && "bg-system-error/10"}

                ${colorTheme === "yellow" && "bg-system-danger/10"} 
                ${!colorTheme && "bg-primary/10"}

                flex justify-center items-center`}
			>
				<span
					className={`text-3xl 
                    ${colorTheme === "green" && "text-secondary"}
                    ${colorTheme === "red" && "text-system-error"}
                    ${colorTheme === "yellow" && "text-system-danger"}
                    ${!colorTheme && "text-primary"}
                    `}
				>
					{icon}
				</span>
			</div>

			<div className="flex flex-col justify-center lg:justify-start">
				<h3 className="break-words text-sm">{title}</h3>
				{loading ? (
					<Loading />
				) : error ? (
					<p className="text-red-500 text-xs">
						{error?.error || error?.message}
					</p>
				) : (
					<p className="font-bold text-xl">
						{count} <span className="text-sm font-normal">{type}</span>
					</p>
				)}
			</div>
		</div>
	);
}
