import { useEffect, useState } from "react";

// COMPONENTS
import Sidebar from "./Components/UiElements/Sidebar";
import Navbar from "./Components/UiElements/Navbar";
import IndexRoutes from "./Routes/index.routes";

// toast config
import { Toaster } from "react-hot-toast";

// pagination themes
import "react-responsive-pagination/themes/classic.css";

// chart configuration
import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
} from "chart.js";
import { useDispatch } from "react-redux";
import { setCredentials } from "./app/services/authSlice";
import { useNavigate } from "react-router-dom";

import cookie from "js-cookie";
import VerifiedLoading from "./Components/modules/VerifiedLoading";

ChartJS.register(
	CategoryScale,
	LinearScale,
	BarElement,
	Title,
	Tooltip,
	Legend,
);

function App() {
	const navigate = useNavigate();

	const dispatch = useDispatch();

	const [verifiedLoading, setVerifiedLoading] = useState(false);
	const [loading, setLoading] = useState(false);
	const [verifiedError, setVerifiedError] = useState("");

	const refreshToken = async () => {
		setVerifiedLoading(true);
		setLoading(true);
		const token = cookie.get("accessToken");

		if (token) {
			fetch("https://susa6.com/api/refresh", {
				method: "GET",
				headers: {
					autorization: `Bearer ${token}`,
				},
				credentials: "include",
			})
				.then(async (res) => {
					const refreshUser = await res.json();
					dispatch(setCredentials(refreshUser));
					cookie.set("accessToken", refreshUser?.accessToken);
					navigate("/");
					setLoading(false);
					setVerifiedLoading(false);
				})
				.catch((err) => {
					setLoading(false);
					if (err.message.includes("Forbidden")) {
						cookie.remove("accessToken");
						// window.location.href = "https://susa6.com/login";
						// ? Dev Mode
						navigate("/login");
						setVerifiedLoading(false);
					}
					setVerifiedError(err.message);
				});
		} else {
			setVerifiedLoading(false);
			// window.location.href = "https://susa6.com/login";
			// ? Dev Mode
			navigate("/login");
		}
	};

	useEffect(() => {
		refreshToken();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (verifiedLoading)
		return (
			<VerifiedLoading
				verifiedError={verifiedError}
				loading={loading}
				refreshToken={refreshToken}
			/>
		);

	return (
		<div className="w-screen h-screen flex overflow-hidden">
			<Sidebar />
			<div className="flex flex-col w-full">
				<Navbar />
				<div
					className="h-full w-full overflow-y-scroll overflow-x-hidden lg:py-8 
					md:py-4 pt-2 pb-6"
				>
					<IndexRoutes />
				</div>
			</div>
			<Toaster />
		</div>
	);
}

export default App;
