import { apiSlice } from "../../apiSlice";

const creditApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		issueCredit: builder.mutation({
			query: (data) => ({
				url: "/credit/issuecredit",
				method: "POST",
				body: data,
			}),
		}),

		getCredits: builder.query({
			query: () => "/credit/getcredits",
			providesTags: ["retired"],
		}),

		retireCredit: builder.mutation({
			query: (data) => ({
				url: "/credit/retire",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["retired"],
		}),
	}),
});

export const {
	useIssueCreditMutation,
	useGetCreditsQuery,
	useRetireCreditMutation,
} = creditApi;
