import { useCallback, useEffect, useState } from "react";
import DropDown from "../DropDown";
import Loading from "../UiElements/Loading";
import AddNewForm from "./AddNewVotableItemForm";

import { HiOutlineDocumentPlus, HiOutlineDocumentMinus } from "react-icons/hi2";

export default function VotingIssueList({
	data,
	setter,
	handleModal,
	isSuccess,
	isLoading,
	votable_id_input,
	isError,
	error,
}) {
	const [tagFilter, setTagFilter] = useState("all");
	const [addNew, setAddNew] = useState(false);

	const [filteredData, setFilteredData] = useState([]);

	const filterResult_tags = useCallback(() => {
		const filterResTag = data?.filter((item) => {
			if (tagFilter === "all") {
				return item;
			}
			return item.tags?.toLowerCase() === tagFilter?.toLowerCase();
		});

		if (votable_id_input?.length > 0) {
			return filterResTag?.filter((item) =>
				item.votableId
					?.toLowerCase()
					.includes(votable_id_input?.toLowerCase().trim()),
			);
		}

		return filterResTag;
	}, [tagFilter, data, votable_id_input]);

	useEffect(() => {
		setFilteredData(filterResult_tags);
	}, [filterResult_tags]);

	return (
		<div className="flex flex-col gap-2">
			<div className="flex items-center justify-between gap-8">
				<div className="flex flex-col gap-1">
					<div className="flex items-center gap-2">
						<h3 className="text-xl font-semibold ">votable Issue List</h3>
						<div className="" onClick={() => setAddNew(!addNew)}>
							<div
								className="p-2 flex items-center justify-center hover:bg-slate-100 transition-all
					 duration-300 rounded-full cursor-pointer"
							>
								{addNew ? (
									<HiOutlineDocumentMinus className="text-xl" />
								) : (
									<HiOutlineDocumentPlus className="text-xl" />
								)}
							</div>
						</div>
					</div>
					{isSuccess && (
						<p className="text-xs text-slate-500">
							<span>{filteredData?.length}</span> items
						</p>
					)}
				</div>
			</div>

			<div className="flex flex-col gap-4 lg:w-[50%] w-full mx-auto">
				{addNew && <AddNewForm setAddNewForm={setAddNew} />}
			</div>

			<div className="py-4">
				<h3 className="text-sm">filters</h3>
				<hr className="mb-4 mt-2" />
				<DropDown
					label="tag"
					value={tagFilter}
					data={["all", "fee", "policy", "role"]}
					setter={setTagFilter}
				/>
			</div>

			<div className="h-[500px] flex flex-col gap-2  overflow-y-scroll">
				{isLoading ? (
					<Loading />
				) : isError ? (
					<p className="text-sm text-center text-system-error">
						{JSON.stringify(error?.message || error?.error)}
					</p>
				) : filteredData?.length > 0 ? (
					filteredData.map((item) => (
						<div
							key={item._id}
							onClick={() => {
								setter(item);
								handleModal(false);
							}}
							className="flex gap-6 items-center flex-wrap justify-between border rounded-xl px-4 py-3
                    cursor-pointer hover:bg-slate-100  transition-all duration-300"
						>
							<p className="flex items-center gap-2 text-xs select-none">
								<span className="hidden md:block">id: </span>
								<span className="px-2 py-1 rounded-lg bg-slate-200">
									{item.votableId}
								</span>
							</p>
							<p className="flex items-center gap-2 text-xs select-none">
								tag:{" "}
								<span className="px-2 py-1 rounded-lg bg-slate-200">
									{item.tags}
								</span>
							</p>

							<p className="flex items-center gap-2 text-xs select-none w-full md:w-auto">
								description:{" "}
								<span className="px-2 py-1 rounded-lg ">
									{item.description}
								</span>
							</p>
						</div>
					))
				) : (
					<p className="text-sm text-center">no item found</p>
				)}
			</div>
		</div>
	);
}
