import { useSelector } from "react-redux";
import { currentUser } from "../app/services/authSlice";
import { Navigate, Outlet, useLocation } from "react-router-dom";

export default function AdminRoutes() {
	const user = useSelector(currentUser);
	const location = useLocation();

	return (
		<>
			{(user?.accessToken &&( user?.roles === "Admin" || user?.roles === "SusaAdmin")) ? (
				<Outlet />
			) : (
				<Navigate to="/" state={{ from: location }} />
			)}
		</>
	);
}
