import { useGetVotableItemsQuery } from "../../app/services/api/evote";

import VotingIssueList from "./VotingIssueList";

export default function AddVotableItemForm({
	setCurrentVote,
	setVotingModalIsOpen,
	votable_id_input,
}) {
	const { data, isLoading, isSuccess, isError, error } =
		useGetVotableItemsQuery();

	return (
		<div className="w-full flex flex-col gap-4 min-w-max">
			<VotingIssueList
				data={data}
				isSuccess={isSuccess}
				isLoading={isLoading}
				setter={setCurrentVote}
				handleModal={setVotingModalIsOpen}
				votable_id_input={votable_id_input}
				isError={isError}
				error={error}
			/>
		</div>
	);
}
