// COMPONENTS
import DropDown from "../DropDown";

import SecondaryInput from "../SecondaryInput";

// ICONS
import { MdPlaylistAdd } from "react-icons/md";
import { useEffect, useState } from "react";
import { GrList } from "react-icons/gr";
import { IoMdClose } from "react-icons/io";
import useStartElection from "../../Hooks/formik/useStartSingleElection";

export default function StartElectionForm({
	setVotingModalIsOpen,
	setVotable_id_input,
	votableItems,
	setVotableItems,
	votableItemsHandler,
	currentVote,
}) {
	const [formik, singleLoading, multipleLoading] =
		useStartElection(votableItems);

	const [inputIndex, setInputIndex] = useState(0);

	useEffect(() => {
		setVotable_id_input(formik.values.votable_id);
	}, [formik.values.votable_id, setVotable_id_input]);

	useEffect(() => {
		votableItemsHandler(inputIndex);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentVote]);

	return (
		<div className="flex flex-col gap-4 w-full">
			<h2 className="text-2xl font-semibold mb-4">Start a voting</h2>
			<form
				onSubmit={formik.handleSubmit}
				className="w-full lg:grid lg:grid-cols-2 flex flex-col gap-4 
                lg:gap-x-8 lg:gap-y-6"
			>
				<div className="grid gap-4">
					<DropDown
						label="Voting Type"
						data={["Majority", "Plurality"]}
						value={formik.values.votingType}
						name="votingType"
						setter={formik.setFieldValue}
					/>

					<DropDown
						name="voteCounting"
						label="Vote Counting Rule"
						data={["Simple", "Weighted"]}
						value={formik.values.voteCounting}
						setter={formik.setFieldValue}
					/>
				</div>

				<div className="grid gap-4">
					<SecondaryInput
						id="participant_level"
						name="participant_level"
						value={formik.values.participant_level}
						setter={formik.handleChange}
						formik={formik}
						label="Level of Participant"
					/>
				</div>
				<div className="col-span-2 flex flex-col gap-2">
					<div className="flex flex-col gap-2">
						{votableItems.map((votableItem, index) => (
							<div key={votableItem.votableId} className="flex flex-col gap-2">
								<label className="text-sm ml-2">Votable Item</label>
								<div className="flex flex-1 border rounded-lg px-4 py-2">
									{Object.entries(votableItems).length > 1 ? (
										<IoMdClose
											onClick={() => {
												const newVotableItems = [...votableItems];
												newVotableItems.splice(index, 1);
												setVotableItems(newVotableItems);
											}}
											className="cursor-pointer p-1 rounded-full bg-system-error hover:bg-red-500 
											transition-all duration-300 text-white self-center mr-2"
										/>
									) : null}
									<input
										value={votableItem.votableId}
										readOnly
										name={index}
										type="text"
										className="flex-grow outline-none border-none"
									/>
									<button
										onClick={() => {
											setVotingModalIsOpen(true);
											setInputIndex(index);
										}}
										type="button"
										className="border-l-2 pl-2"
									>
										<GrList className="text-xl" />
									</button>
								</div>
							</div>
						))}
					</div>

					<div className="flex justify-end">
						<button
							type="button"
							onClick={(e) => {
								e.preventDefault();
								setVotableItems([...votableItems, { votableId: "" }]);
							}}
							className="mt-2 text-xs flex items-center gap-1 text-primary hover:bg-primary/10 
						py-1 px-3 rounded-lg"
						>
							<span>Add Voting Issue</span>
							<MdPlaylistAdd className="text-lg" />
						</button>
					</div>
				</div>

				<SecondaryInput
					value={formik.values.startDate}
					setter={formik.handleChange}
					name="startDate"
					id="start_date"
					label="Start Date"
					type="date"
					formik={formik}
				/>
				<SecondaryInput
					value={formik.values.endDate}
					setter={formik.handleChange}
					id="end_date"
					name="endDate"
					label="End Date"
					type="date"
					formik={formik}
				/>

				<button
					type="submit"
					className="bg-primary/60 text-white rounded-lg border-none px-4 py-3 col-span-2 shadow-xl shadow-primary/20
                    hover:bg-primary transition-all duration-300"
				>
					{singleLoading || multipleLoading ? "starting ..." : "start"}
				</button>
			</form>
		</div>
	);
}
