import { useEffect } from "react";
import { useState } from "react";

import DropDown from "../DropDown";

import { useGetCreditsQuery } from "../../app/services/api/credit";

import Table from "../table/Table";
import sliceData from "../../helpers/sliceData";

import { CgCloseR } from "react-icons/cg";

import ClientPagination from "../table/ClientPagination";
import Loading from "./Loading";
const rowsPerPage = 5;

export default function CreditDetailModal({
	setModalIsOpen,
	setCreditIdSelected,
}) {
	const { data, isLoading } = useGetCreditsQuery();

	const [tempData, setTempData] = useState();

	const [page, setPage] = useState(1);
	const [slicedData, setSlicedData] = useState([]);
	const [statusFilter, setStatusFilter] = useState("all");

	const [filterData, setFilterData] = useState([]);

	useEffect(() => {
		if (statusFilter === "all") {
			setFilterData(data);
			setTempData(data);
		} else {
			const filter = data?.filter(
				(item) => item.status.toLowerCase() === statusFilter,
			);
			setTempData(filter);
			setFilterData(filter);
		}

		const sliced = sliceData(filterData, page, rowsPerPage);

		setSlicedData(sliced);
	}, [data, page, statusFilter, filterData]);

	const handleClick = (e) => {
		const id = e.target.parentElement.id;

		const creditId = slicedData?.filter((item) => item.creditId === id)[0]
			?.creditId;

		setCreditIdSelected(creditId);
		setModalIsOpen(false);
	};

	return (
		<div
			className="fixed z-50 top-0 left-0 w-screen h-screen glassmorphism bg-black/40 flex
     items-center justify-center py-4 px-2"
		>
			<div className="max-w-full max-h-full p-4 rounded-xl bg-white flex flex-col gap-4">
				<div className="flex items-center justify-between gap-4">
					<h2 className="text-2xl">Offset List</h2>
					<button
						className="text-2xl text-slate-500 hover:text-slate-800 transition-all duration-300"
						onClick={() => setModalIsOpen(false)}
					>
						<CgCloseR />
					</button>
				</div>
				<div className="flex gap-4 flex-wrap">
					<div className="lg:w-32 w-full">
						<DropDown
							data={["all", "forsale", "issued", "retired", "purchased"]}
							label="status"
							value={statusFilter}
							setter={setStatusFilter}
						/>
					</div>
				</div>

				<div className="flex flex-col gap-1 items-center">
					{isLoading ? (
						<Loading />
					) : (
						<>
							<Table
								headers={[
									"creditId",
									"vintageStartDate",
									"vintageEndDate",
									"status",
								]}
								data={slicedData}
								additionalCols={["Action"]}
								customButtonId="creditId"
								buttons={[
									<button
										key="select AuctionId"
										className="px-2 py-1 text-xs rounded-lg border border-green-300 bg-green-300 text-green-700"
										type="button"
										onClick={(e) => handleClick(e)}
									>
										Select
									</button>,
								]}
							/>
							{tempData?.length > rowsPerPage && (
								<ClientPagination
									data={tempData}
									slicedData={slicedData}
									page={page}
									setPage={setPage}
									rowsPerPage={rowsPerPage}
								/>
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
}
