import { motion } from "framer-motion";
import {
	useAcceptOrganizationMutation,
	useGetOrganizationsInfoQuery,
	useRejectOrganizationMutation,
} from "../../../../app/services/api/organizations";
import { useEffect, useRef, useState } from "react";
import sliceData from "../../../../helpers/sliceData";
import ClientPagination from "../../../../Components/table/ClientPagination";
import Table from "../../../../Components/table/Table";
import ErrorCard from "../../../../Components/Cards/ErrorCard";
import Loading from "../../../../Components/UiElements/Loading";

import { toast } from "react-hot-toast";

const rowsPerPage = 10;

export default function NewOrganizations() {
	const [page, setPage] = useState(1);
	const [slicedData, setSlicedData] = useState([]);

	const { data, isLoading, isFetching, error, isError } =
		useGetOrganizationsInfoQuery();

	useEffect(() => {
		const sliced = sliceData(data, page, rowsPerPage);
		const pendingOrganizations = sliced?.filter(
			(item) => item.status === "pending",
		);
		setSlicedData(pendingOrganizations);
	}, [data, page]);

	return (
		<motion.section
			initial={{ y: 10, opacity: 0.4, scale: 0.7 }}
			animate={{ y: 0, opacity: 1, scale: 1 }}
			transition={{ stiffness: 100 }}
			className="custom_container w-full overflow-hidden flex flex-col gap-4"
		>
			<div className="grid grid-cols-1 gap-6">
				<div className="flex flex-col gap-1">
					<h1 className="text-xl font-semibold">New Organizations</h1>
					<p className="text-sm text-gray-500">
						In this page, you can see the list of organizations that have
						requested to join us, you can make a decision about each of these
						organizations.
					</p>
				</div>

				{isLoading || isFetching ? (
					<Loading />
				) : isError ? (
					<ErrorCard error={error} />
				) : (
					<>
						<Table
							emptyHandler
							data={slicedData}
							headers={[
								"username",
								"registered_name",
								"email",
								"country",
								"scope",
								"status",
							]}
							additionalCols={["Desicion"]}
							buttons={[<Decision data={slicedData} />]}
						/>
						{data?.length > rowsPerPage && (
							<ClientPagination
								data={data}
								slicedData={slicedData}
								rowsPerPage={rowsPerPage}
								page={page}
								setPage={setPage}
							/>
						)}
					</>
				)}
			</div>
		</motion.section>
	);
}

const Decision = ({ data }) => {
	const currentRef = useRef();
	const [acceptOrganization, { isLoading: acceptLoading }] =
		useAcceptOrganizationMutation();

	const [rejectOrganization, { isLoading: rejectLoading }] =
		useRejectOrganizationMutation();

	const getUsername = () => {
		const clickedRowId = currentRef.current.parentElement.id;
		const { username } = data?.find((item) => item._id === clickedRowId);
		return username;
	};

	const handleAcceptOrganization = async () => {
		const username = getUsername();

		acceptOrganization({
			username,
		})
			.unwrap()
			.then((res) => {
				toast.success(`${res.username} accepted!`);
			})
			.catch((err) => {
				console.log(err);
				toast.error(err.message);
			});
	};

	const handleRejectOrganization = async () => {
		const username = getUsername();

		rejectOrganization({
			username,
		})
			.unwrap()
			.then((res) => {
				toast.success(`${res.username} rejected`);
			})
			.catch((err) => {
				toast.error(err.message);
			});
	};
	return (
		<div ref={currentRef} className="flex items-center gap-2 w-max mx-auto">
			<button
				onClick={handleAcceptOrganization}
				className="px-4 py-2 rounded-md bg-green-50 text-green-500 hover:bg-green-100 transition-all duration-300
			 font-semibold text-xs"
			>
				{acceptLoading ? "accept..." : "accept"}
			</button>
			<button
				onClick={handleRejectOrganization}
				className="px-4 py-2 rounded-md bg-red-50 text-red-400 hover:bg-red-100 transition-all duration-300
			 font-semibold text-xs"
			>
				{rejectLoading ? "reject..." : "reject"}
			</button>
		</div>
	);
};
