import error404 from "../Assets/404_error.svg";
import Button from "../Components/Button";

import { AiOutlineRollback } from "react-icons/ai";

export default function NotFound() {
	return (
		<section className="overflow-hidden flex items-center justify-center w-full h-full">
			<div className="flex flex-col items-center gap-12">
				<img src={error404} alt="404 error" className="w-96 h-96" />
				<Button
					text="Back To Home"
					size="lg"
					icon={<AiOutlineRollback />}
					href="/"
				/>
			</div>
		</section>
	);
}
