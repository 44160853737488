import { useState } from "react";

import { useFormik } from "formik";
import { motion } from "framer-motion";

// validation form
import * as YUP from "yup";

// COMPONENTS
import FloatingLabelInput from "./FloatingLabelInput";
// ICONS
import { AiOutlineCloseSquare } from "react-icons/ai";
import { useSendGTMutation } from "../../app/services/api/governance/balance";

// toast
import toast from "react-hot-toast";
import { useSelector } from "react-redux";
import { currentUser } from "../../app/services/authSlice";
export default function SendWalletForm() {
	const [confirmModal, setConfirmModal] = useState(false);
	const user = useSelector(currentUser);

	const [sendGT, { isLoading }] = useSendGTMutation();

	const formik = useFormik({
		initialValues: {
			name: "",
			organizationName: "",
			amount: "",
		},
		onSubmit: (values, { resetForm }) => {
			sendGT({
				userId: user?.name,
				to: values.name,
				value: `${values.amount}`,
			})
				.unwrap()
				.then(() => {
					toast.success("Governance Token is sended");
					setConfirmModal(false);
					resetForm();
				})
				.catch((err) => {
					const msg = err.data?.message || err.error;
					toast.error(msg.substring(msg.search("Error:"), msg.length));
					setConfirmModal(false);
				});
		},
		validationSchema: YUP.object({
			name: YUP.string("this field is string")
				.trim()
				.min(4, "at least 4 charachter")
				.nullable("nullable value")
				.required("Required"),
			organizationName: YUP.string("this field is string")
				.matches(
					/[a-zA-Z_][a-zA-Z0-9_]*/,
					"started with alphabetical charachters",
				)
				.trim()
				.min(6, "at least 6 charachter")
				.nullable("nullable value")
				.required("Required"),
			amount: YUP.number("this field is number")
				.nullable("nullable Value")
				.required("Required"),
		}),
	});

	return (
		<motion.form
			onSubmit={formik.handleSubmit}
			initial={{ x: -100, scale: 0.8, opacity: 0.4 }}
			animate={{ x: 0, opacity: 1, scale: 1 }}
			transition={{ type: "spring" }}
			className="w-full py-2"
		>
			<h2 className="font-semibold text-slate-700 text-xl mb-4 text-center uppercase">
				Send
			</h2>

			<div className="flex flex-col gap-2">
				<FloatingLabelInput
					value={formik.values.name}
					label="name"
					options={{ ...formik.getFieldProps("name") }}
					formik={formik}
				/>

				<FloatingLabelInput
					value={formik.values.organizationName}
					label="organizationName"
					options={{ ...formik.getFieldProps("organizationName") }}
					formik={formik}
				/>

				<div className="flex flex-col">
					<FloatingLabelInput
						value={formik.values.amount}
						label="amount"
						options={{ ...formik.getFieldProps("amount") }}
						formik={formik}
					>
						<span
							className="w-max px-3 py-1 flex items-center justify-center
						text-slate-200 bg-secondary rounded-lg shadow-xl shadow-secondary/20"
						>
							GT
						</span>
					</FloatingLabelInput>

					<span className="mt-1 ml-1 text-xs text-slate-500">
						Max Amount : 100 GT
					</span>
				</div>
				<div className="text-xs text-slate-500 mt-2">
					<p>
						Estimated Gas Fee:{" "}
						<span className="px-1 font-semibold text-base">1</span>
						GT
					</p>

					<hr className="my-1" />
					{formik.values.amount > 0 && (
						<motion.p
							initial={{ scale: 0.95, opacity: 0.5 }}
							animate={{ scale: 1, opacity: 1 }}
						>
							You Will Send:{" "}
							<span className="px-1 text-slate-800 text-base font-semibold">
								{formik.values.amount - 1}
							</span>
						</motion.p>
					)}
				</div>
			</div>

			<button
				disabled={!(formik.isValid && formik.dirty)}
				onClick={(e) => {
					e.preventDefault();
					setConfirmModal(true);
				}}
				className="mt-8 disabled:text-slate-300 bg-primary w-full p-2 rounded-lg text-white hover:bg-primary-dark hover:text-slate-300
    transition-all duraiton-300 shadow-xl shadow-primary/20"
			>
				Next
			</button>
			{confirmModal && (
				<div
					className="z-50 fixed top-0 left-0 right-0 bottom-0 bg-black/70 flex items-center 
				justify-center"
				>
					<div className="bg-slate-100 rounded-lg p-4 flex flex-col gap-6">
						<AiOutlineCloseSquare
							className="text-3xl ml-auto text-primary-dark/40 hover:text-primary-dark
						transition-all duration-300 cursor-pointer"
							onClick={() => setConfirmModal(false)}
						/>
						<h2 className="text-xl font-semibold text-center uppercase">
							confirmation
						</h2>
						<div className="md:w-80 w-64 border-2 border-primary/60 px-4 py-2 rounded-xl text-center">
							<p className="text-lg text-primary">Amount</p>
							<p className="text-xl font-bold text-primary-dark">
								{formik.values.amount}{" "}
								<span className="text-base font-light">GT</span>
							</p>
						</div>
						<div className="flex flex-col gap-2 my-2">
							<div className="flex items-center justify-between gap-4 flex-wrap">
								<p className="text-xs">Account Name</p>
								<p>{formik.values.name}</p>
							</div>

							<div className="flex items-center justify-between gap-4 flex-wrap">
								<p className="text-xs">Bank</p>
								<p>Susa</p>
							</div>

							<div className="flex items-center justify-between gap-4 flex-wrap">
								<p className="text-xs">Transaction Fee</p>
								<p>1 GT</p>
							</div>

							<div className="flex items-center justify-between gap-4 flex-wrap">
								<p className="text-xs">You will sended</p>
								<p>{formik.values.amount - 1} GT</p>
							</div>
						</div>
						<button
							type="submit"
							className="w-full px-4 py-2 bg-primary/70 text-slate-300 rounded-lg hover:bg-primary 
							hover:text-slate-200 transition-all duration-300 font-semibold"
						>
							{isLoading ? "Loading ..." : "Confirm"}
						</button>
					</div>
				</div>
			)}
		</motion.form>
	);
}
