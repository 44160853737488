import InputField from "../InputField";
import CreditDetailModal from "../UiElements/CreditDetailModal";
import useFixedPriceAuction from "../../Hooks/formik/useFixedPriceAuction";
import { useState } from "react";
import { useEffect } from "react";

export default function FixedPriceForm(props) {
	const [formik, isLoading] = useFixedPriceAuction();

	const [modalIsOpen, setModalIsOpen] = useState(false);
	const [creditIdSelected, setCreditIdSelected] = useState("");

	useEffect(() => {
		formik.setFieldValue("credit_id", creditIdSelected);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [creditIdSelected]);

	return (
		<form className="mb-12 md:w-max mx-auto" onSubmit={formik.handleSubmit}>
			<h2 className="text-2xl font-bold mb-6 text-center">Start An Auction</h2>

			<div className="grid grid-cols-1 md:grid-cols-2 gap-x-8">
				<InputField
					label="fixed_swap_ratio"
					value={formik.values.fixed_swap_ratio}
					options={{ ...formik.getFieldProps("fixed_swap_ratio") }}
					formik={formik}
				/>

				<div className="flex flex-col gap-1">
					<InputField
						label="credit_id"
						value={formik.values.credit_id}
						options={{ ...formik.getFieldProps("credit_id") }}
						formik={formik}
					/>
					<button
						type="button"
						onClick={() => setModalIsOpen((prev) => !prev)}
						className="self-end text-xs px-4 py-1 bg-primary/20 text-primary rounded-lg
					hover:bg-primary/30 transition-all duration-300"
					>
						Browse Offset ID
					</button>
				</div>
				<InputField
					label="auction_count"
					value={formik.values.auction_count}
					options={{ ...formik.getFieldProps("auction_count") }}
					formik={formik}
				/>

				<InputField
					label="amount"
					value={formik.values.amount}
					options={{ ...formik.getFieldProps("amount") }}
					formik={formik}
				/>

				<InputField
					label="eth_address"
					value={formik.values.eth_address}
					options={{ ...formik.getFieldProps("eth_address") }}
					formik={formik}
				/>

				<InputField
					label="start_date"
					value={formik.values.start_date}
					type="date"
					options={{ ...formik.getFieldProps("start_date") }}
					formik={formik}
				/>
				<InputField
					label="end_date"
					value={formik.values.end_date}
					type="date"
					options={{ ...formik.getFieldProps("end_date") }}
					formik={formik}
				/>
			</div>
			<div className="mt-4 w-full flex items-center justify-center">
				<button
					type="submit"
					className="bg-primary/60 text-white rounded-lg border-none px-4 py-3 col-span-2 shadow-xl
					 shadow-primary/20 hover:bg-primary transition-all duration-300 flex-1"
				>
					{isLoading ? "Starting ..." : "Start"}
				</button>
			</div>

			{modalIsOpen && (
				<CreditDetailModal
					setModalIsOpen={setModalIsOpen}
					setCreditIdSelected={setCreditIdSelected}
				/>
			)}
		</form>
	);
}
