import { useFormik } from "formik";

import * as YUP from "yup";
import { useIssueCreditMutation } from "../../app/services/api/credit";
import { toast } from "react-hot-toast";
const useIssueCredit = () => {
	const [issueCredit, { isLoading }] = useIssueCreditMutation();

	const formik = useFormik({
		initialValues: {
			susa_id: "",
			credit_type: "",
			block_id_start: "",
			block_id_end: "",
			program_code: "",
			project_id: "",
			project_country: "",
			project_type_code: "",
			vintage_start_date: "",
			vintage_end_date: "",
			validator_identifier: "",
			registery_identifier: "",
			original_credit_id: "",
		},
		onSubmit: (values, { resetForm }) => {
			issueCredit({
				registryIdentifier: values.registery_identifier,
				susaId: values.susa_id,
				programeCode: values.program_code,
				creditType: values.credit_type,
				validatorIdentifier: values.validator_identifier,
				projectTypeCode: values.project_type_code,
				iso2CountryCode: values.project_country,
				projectId: values.project_id,
				vintageStartDate: values.vintage_start_date,
				vintageEndDate: values.vintage_end_date,
				originalCreditId: values.original_credit_id,
				blockIdStart: values.block_id_start,
				blockIdEnd: values.block_id_end,
			})
				.unwrap()
				.then((res) => {
					toast.success("issue credit Successfully added");
					resetForm();
				})
				.catch((err) => {
					toast.error(err.message);
					console.error(err);
				});
		},
		validationSchema: YUP.object({
			susa_id: YUP.string("this field is string")
				.trim()
				.nullable("nullable value")
				.required("Required"),
			credit_type: YUP.string("this field is string")
				.trim()
				.min(4, "at least 4 charachter")
				.nullable("nullable value")
				.required("Required"),
			block_id_start: YUP.string("this field is string")
				.trim()
				.nullable("nullable value")
				.required("Required"),
			block_id_end: YUP.string("this field is string")
				.trim()
				.nullable("nullable value")
				.required("Required"),
			program_code: YUP.string("this field is string")
				.trim()
				.required("Required"),
			project_country: YUP.string("this field is string")
				.trim()
				.required("Required"),
			project_type_code: YUP.string("this field is string")
				.trim()
				.nullable("nullable value")
				.required("Required"),
			project_id: YUP.string("this field is string")
				.trim()
				.nullable("nullable value")
				.required("Required"),
			vintage_start_date: YUP.date("this field is Date format").required(
				"Required",
			),
			validator_identifier: YUP.string("this field is string").required(
				"Required",
			),
			registery_identifier: YUP.string("this field is string").required(
				"Required",
			),
			vintage_end_date: YUP.date("this field is Date format")
				.min(YUP.ref("vintage_start_date", "end Date must be after start time"))
				.required("Required"),
			original_credit_id: YUP.string("this field is string")
				.trim()
				.min(10, "at least 10 charachter")
				.nullable("nullable value")
				.required("Required"),
		}),
	});

	console.log(formik);

	return [formik, isLoading];
};

export default useIssueCredit;
