import { BiMessageSquareError } from "react-icons/bi";
export default function ErrorCard({ error }) {
	console.log(error);
	return (
		<div
			className="glassmorphism bg-slate-50/30 rounded-lg shadow-2xl border border-red-200 shadow-red-100/30 
			w-full h-auto p-2 flex flex-col items-center gap-2"
		>
			<div className="flex items-center gap-2">
				<BiMessageSquareError className="text-red-400 text-xl" />
				<p className="text-red-400 text-sm font-semibold">{error?.status}</p>
			</div>
			<span className="text-red-400 text-xs  break-words w-full">
				{error?.error || error?.message || error?.data?.message}
			</span>
		</div>
	);
}
