import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";

import { BrowserRouter } from "react-router-dom";

// react tooltip default css styles
import "react-tooltip/dist/react-tooltip.css";

// framer motion wrapper
import { AnimatePresence } from "framer-motion";
// store provider - handle states
import { Provider } from "react-redux";
import store from "./app/store";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<AnimatePresence>
				<Provider store={store}>
					<App />
				</Provider>
			</AnimatePresence>
		</BrowserRouter>
	</React.StrictMode>,
);
