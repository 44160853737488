import { useFormik } from "formik";
import * as YUP from "yup";
import { useAddVotableItemMutation } from "../../app/services/api/evote";
import { toast } from "react-hot-toast";

const useAddVotingIssue = () => {
	const [addVotableItem, { isLoading }] = useAddVotableItemMutation();

	const formik = useFormik({
		initialValues: {
			tag: "Fee",
			description: "",
		},
		onSubmit: (values) => {
			addVotableItem({
				tags: values.tag,
				description: values.description,
			})
				.unwrap()
				.then((res) => {
					toast.success(`votable item successfully added`);
				})
				.catch((err) => {
					toast.error(err.message || err.data?.message);
				});
		},
		validationSchema: YUP.object({
			tag: YUP.string().required("this field is required"),
			description: YUP.string().required("this field is required"),
		}),
	});

	return [formik, isLoading];
};

export default useAddVotingIssue;
