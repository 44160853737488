import { motion } from "framer-motion";
import ProcessCard from "../../../../Components/Cards/processCard";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const ProcessLists = [
	[
		{
			id: 1,
			step: 1,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "2024/1/1",
			endHour: "10:30",
			tookTime: "1day, 22h, 12m",
			title: "Necessary Crypto",
			description:
				"All Necessary Crypto Material of New Organization Is Accessible",
			nextProcessTitle: "Add New Organization",
			nextProcessDescription:
				"Susa organization add new organization crypto material to most recent config block for the channel",
		},
		{
			id: 2,
			step: 2,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "2024/1/1",
			endHour: "10:30",
			tookTime: "1day, 22h, 12m",
			title: "Add New Organization",
			description:
				"Susa organization add new organization crypto material to most recent config block for the channel",
			nextProcessTitle: "Organization Signed",
			nextProcessDescription:
				"Organization signed the updated config based on the modification policy.",
		},
		{
			id: 3,
			step: 3,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "2024/1/1",
			endHour: "10:30",
			tookTime: "1day, 22h, 12m",
			title: "Organization Signed",
			description:
				"Organization signed the updated config based on the modification policy.",
			nextProcessTitle: "Joined New Organization",
			nextProcessDescription: "New organization joined the governance channel",
		},
		{
			id: 4,
			step: 4,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "2024/1/1",
			endHour: "10:30",
			tookTime: "1day, 22h, 12m",
			title: "Joined New Organization",
			description: "New organization joined the governance channel",
			nextProcessTitle: "",
			nextProcessDescription: "",
		},
	],
	[
		{
			id: 1,
			step: 1,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "2024/1/1",
			endHour: "10:30",
			tookTime: "1day, 22h, 12m",
			title: "Necessary Crypto",
			description:
				"All Necessary Crypto Material of New Organization Is Accessible",
			nextProcessTitle: "Add New Organization",
			nextProcessDescription:
				"Susa organization add new organization crypto material to most recent config block for the channel",
		},
		{
			id: 2,
			step: 2,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "",
			endHour: "",
			tookTime: "1day, 22h, 12m",
			title: "Add New Organization",
			description:
				"Susa organization add new organization crypto material to most recent config block for the channel",
			nextProcessTitle: "Organization Signed",
			nextProcessDescription:
				"Organization signed the updated config based on the modification policy.",
		},
		{
			id: 3,
			step: 3,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "",
			endHour: "",
			tookTime: "1day, 22h, 12m",
			title: "Organization Signed",
			description:
				"Organization signed the updated config based on the modification policy.",
			nextProcessTitle: "Joined New Organization",
			nextProcessDescription: "New organization joined the governance channel",
		},
		{
			id: 4,
			step: 4,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "",
			endHour: "",
			tookTime: "1day, 22h, 12m",
			title: "Joined New Organization",
			description: "New organization joined the governance channel",
			nextProcessTitle: "",
			nextProcessDescription: "",
		},
	],
	[
		{
			id: 1,
			step: 1,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "2024/1/1",
			endHour: "10:30",
			tookTime: "1day, 22h, 12m",
			title: "Necessary Crypto",
			description:
				"All Necessary Crypto Material of New Organization Is Accessible",
			nextProcessTitle: "Add New Organization",
			nextProcessDescription:
				"Susa organization add new organization crypto material to most recent config block for the channel",
		},
		{
			id: 2,
			step: 2,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "2024/1/1",
			endHour: "10:30",
			tookTime: "1day, 22h, 12m",
			title: "Add New Organization",
			description:
				"Susa organization add new organization crypto material to most recent config block for the channel",
			nextProcessTitle: "Organization Signed",
			nextProcessDescription:
				"Organization signed the updated config based on the modification policy.",
		},
		{
			id: 3,
			step: 3,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "2024/1/1",
			endHour: "10:30",
			tookTime: "1day, 22h, 12m",
			title: "Organization Signed",
			description:
				"Organization signed the updated config based on the modification policy.",
			nextProcessTitle: "Joined New Organization",
			nextProcessDescription: "New organization joined the governance channel",
		},
		{
			id: 4,
			step: 4,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "",
			endHour: "",
			tookTime: "1day, 22h, 12m",
			title: "Joined New Organization",
			description: "New organization joined the governance channel",
			nextProcessTitle: "",
			nextProcessDescription: "",
		},
	],
	[
		{
			id: 1,
			step: 1,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "2024/1/1",
			endHour: "10:30",
			tookTime: "1day, 22h, 12m",
			title: "Necessary Crypto",
			description:
				"All Necessary Crypto Material of New Organization Is Accessible",
			nextProcessTitle: "Add New Organization",
			nextProcessDescription:
				"Susa organization add new organization crypto material to most recent config block for the channel",
		},
		{
			id: 2,
			step: 2,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "",
			endHour: "",
			tookTime: "1day, 22h, 12m",
			title: "Add New Organization",
			description:
				"Susa organization add new organization crypto material to most recent config block for the channel",
			nextProcessTitle: "Organization Signed",
			nextProcessDescription:
				"Organization signed the updated config based on the modification policy.",
		},
		{
			id: 3,
			step: 3,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "",
			endHour: "",
			tookTime: "1day, 22h, 12m",
			title: "Organization Signed",
			description:
				"Organization signed the updated config based on the modification policy.",
			nextProcessTitle: "Joined New Organization",
			nextProcessDescription: "New organization joined the governance channel",
		},
		{
			id: 4,
			step: 4,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "",
			endHour: "",
			tookTime: "1day, 22h, 12m",
			title: "Joined New Organization",
			description: "New organization joined the governance channel",
			nextProcessTitle: "",
			nextProcessDescription: "",
		},
	],
	[
		{
			id: 1,
			step: 1,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "2024/1/1",
			endHour: "10:30",
			tookTime: "1day, 22h, 12m",
			title: "Necessary Crypto",
			description:
				"All Necessary Crypto Material of New Organization Is Accessible",
			nextProcessTitle: "Add New Organization",
			nextProcessDescription:
				"Susa organization add new organization crypto material to most recent config block for the channel",
		},
		{
			id: 2,
			step: 2,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "",
			endHour: "",
			tookTime: "1day, 22h, 12m",
			title: "Add New Organization",
			description:
				"Susa organization add new organization crypto material to most recent config block for the channel",
			nextProcessTitle: "Organization Signed",
			nextProcessDescription:
				"Organization signed the updated config based on the modification policy.",
		},
		{
			id: 3,
			step: 3,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "",
			endHour: "",
			tookTime: "1day, 22h, 12m",
			title: "Organization Signed",
			description:
				"Organization signed the updated config based on the modification policy.",
			nextProcessTitle: "Joined New Organization",
			nextProcessDescription: "New organization joined the governance channel",
		},
		{
			id: 4,
			step: 4,
			startDate: "2023/12/12",
			startHour: "11:50",
			endDate: "",
			endHour: "",
			tookTime: "1day, 22h, 12m",
			title: "Joined New Organization",
			description: "New organization joined the governance channel",
			nextProcessTitle: "",
			nextProcessDescription: "",
		},
	],
];

export default function AddNewOrg() {
	return (
		<motion.section
			initial={{ y: 10, opacity: 0.4, scale: 0.7 }}
			animate={{ y: 0, opacity: 1, scale: 1 }}
			transition={{ stiffness: 100 }}
			className="custom_container w-full overflow-hidden flex flex-col gap-4"
		>
			<div className="flex flex-col gap-1">
				<h1 className="text-xl font-semibold">
					Add New Organization To Governance Channel
				</h1>
				<p className="text-sm text-gray-500">
					in this page you will see the list of the processes about add new
					organizations to governance channel
				</p>
			</div>

			<ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 750: 2, 1200: 3 }}>
				<Masonry gutter={"12px"}>
					{ProcessLists.map((processBlock, index) => (
						<ProcessCard key={index} processBlock={processBlock} />
					))}
				</Masonry>
			</ResponsiveMasonry>
		</motion.section>
	);
}
