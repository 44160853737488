import { useRef, useState } from "react";

// api handlers
import {
	useResultRevealMultipleMutation,
	useResultRevealSingleMutation,
} from "../app/services/api/evote";
import { toast } from "react-hot-toast";
import { useEffect } from "react";

const ResultRevealButton = ({ data }) => {
	const [result, setResult] = useState(null);
	const [resultRevealSingle, { isLoading: singleLoading }] =
		useResultRevealSingleMutation();
	const [resultRevealMultiple, { isLoading: multipleLoading }] =
		useResultRevealMultipleMutation();

	const currentRef = useRef();

	useEffect(() => {
		try {
			const id = currentRef.current.parentElement.id;
			const [item] = data.filter((item) => item._id === id);
			setResult(item?.result);
		} catch (error) {
			console.log("====================================");
			console.log(error.message);
			console.log("====================================");
		}
	}, [data]);

	const handleClick = () => {
		try {
			const id = currentRef.current.parentElement.id;
			const [{ electionId }] = data.filter((item) => item._id === id);
			const election = data.filter((item) => item._id === id)[0];
			if (election.type === "SingleElection") {
				resultRevealSingle({
					electionId,
				})
					.unwrap()
					.then((res) => {
						setResult(res?.result);
					})
					.catch((err) => {
						toast.error(err.message || err.data.message);
					});
			} else {
				resultRevealMultiple({
					electionId,
				})
					.unwrap()
					.then((res) => {
						setResult(res?.result);
					})
					.catch((err) => {
						toast.error(err.message || err.data.message);
					});
			}
		} catch (error) {
			toast.error(error.message);
		}
	};

	return (
		<div ref={currentRef}>
			{!result ? (
				<button
					onClick={handleClick}
					className="bg-secondary/10 px-2 py-1 rounded-lg border border-secondary/10 
					text-secondary hover:text-slate-100 hover:bg-secondary 
					hover:border-secondary transition-all duration-300"
				>
					{singleLoading || multipleLoading ? "Loading..." : "result Reveal"}
				</button>
			) : (
				<p
					className={`${result === "lose" && "text-red-500"} ${
						result === "win" && "text-green-600"
					} font-semibold uppercase`}
				>
					{typeof result === "object" ? (
						<p className="flex flex-col gap-1">
							<span>{result.votableId}</span>
							<span className="capitalize">
								{result.tags} is {result.description}
							</span>
						</p>
					) : (
						result
					)}
				</p>
			)}
		</div>
	);
};

export default ResultRevealButton;
