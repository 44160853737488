import React from "react";
import { TiTick } from "react-icons/ti";
import { MdKeyboardArrowRight } from "react-icons/md";
import { Link } from "react-router-dom";

export default function Top5Users({ title, description, data }) {
	return (
		<section className="flex flex-col gap-6 border-2 p-4 rounded-xl">
			<div>
				<h2 className="text-xl font-semibold">{title}</h2>
				<p className="text-xs">{description}</p>
			</div>
			<div className="flex flex-col">
				{data.map((user) => (
					<div
						key={user.id}
						className="flex items-center gap-4 justify-between py-2 hover:bg-slate-50 px-2 rounded-lg 
                        transition-all duration-300 cursor-pointer"
					>
						<div className="flex items-center gap-2">
							{/* avatar */}
							<div className="w-12 h-12 bg-slate-100 rounded-full flex items-center justify-center">
								{user.username?.charAt(0).toUpperCase()}
							</div>
							<p className="flex flex-col font-semibold">
								{user.username}{" "}
								<span className="text-xs font-thin">{user.roles}</span>
							</p>
						</div>

						<div className="flex items-center gap-1">
							<TiTick className="text-xl" />
							<p className="text-lg font-bold">
								{user.count} <span className="text-sm font-light">offsets</span>
							</p>
						</div>
					</div>
				))}
			</div>
			<Link
				to={"/leader-boards"}
				className="self-end text-sm flex items-center gap-1 hover:underline underline-offset-4"
			>
				View All <MdKeyboardArrowRight />
			</Link>
		</section>
	);
}
