import { Link } from "react-router-dom";
const ButtonType = ({ type, text, icon, padding, href, isLoading }) => {
	return (
		<button
			type={type}
			className={`border-2 border-primary bg-primary text-white ${padding} rounded-2xl shadow-xl shadow-primary/30 
			hover:shadow-none transition-all duration-300 w-full text-center
			hover:bg-transparent hover:text-primary group`}
		>
			{href ? (
				<Link
					to={href}
					className="w-full h-full flex items-center justify-center gap-2"
				>
					<p className="-mt-1">{text}</p>
					{icon && (
						<span className="group-hover:rotate-[360deg] transition-all duration-500">
							{icon}
						</span>
					)}
				</Link>
			) : (
				<p className="w-full h-full flex items-center justify-center gap-2">
					<span className="-mt-1">{isLoading ? "loading..." : text}</span>
					{icon && (
						<span className="group-hover:rotate-[360deg] transition-all duration-500">
							{icon}
						</span>
					)}
				</p>
			)}
		</button>
	);
};

export default function Button({ text, icon, size, type, href, isLoading }) {
	switch (size) {
		case "md":
			return (
				<ButtonType
					padding="px-8 py-2"
					icon={icon}
					text={text}
					type={type}
					href={href}
					isLoading={isLoading}
				/>
			);
		case "lg":
			return (
				<ButtonType
					padding="px-12 py-3 "
					icon={icon}
					text={text}
					type={type}
					href={href}
					isLoading={isLoading}
				/>
			);
		case "xl":
			return (
				<ButtonType
					padding="px-20 py-4"
					icon={icon}
					text={text}
					type={type}
					href={href}
					isLoading={isLoading}
				/>
			);

		default:
			return (
				<ButtonType
					padding="px-4 py-2"
					icon={icon}
					text={text}
					type={type}
					href={href}
					isLoading={isLoading}
				/>
			);
	}
}
