import { apiSlice } from "../../apiSlice";
const contactApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getContacts: builder.query({
			query: () => "/contact-info",
		}),
	}),
});

export const { useGetContactsQuery } = contactApi;
