const RecentTransactionsDetails = [
	{
		id: "9a24688899434343099076e723",
		timestamp: new Date().toLocaleString(),
		transaction_hash: "6688725055948506273",
		block: 175646,
		status: "success",
		from: "7c24645879434343099076ef423",
		to: "6b24645879434348088076af461",
		total_sent: 0.42,
	},
	{
		id: "9a24688899434343099076e724",
		timestamp: new Date().toLocaleString(),
		transaction_hash: "6688725055948506273",
		block: 175646,
		status: "faild",
		from: "7c24645879434343099076ef423",
		to: "6b24645879434348088076af461",
		total_sent: 0.42,
	},
	{
		id: "9a24688899434343099076e725",
		timestamp: new Date().toLocaleString(),
		transaction_hash: "6688725055948506273",
		block: 175646,
		status: "success",
		from: "7c24645879434343099076ef423",
		to: "6b24645879434348088076af461",
		total_sent: 0.42,
	},
];

export default RecentTransactionsDetails;
