import { useState, useEffect } from "react";
import FixedPriceForm from "../Components/modules/FixedPriceForm";
import Table from "../Components/table/Table";

import Loading from "../Components/UiElements/Loading";
import ErrorCard from "../Components/Cards/ErrorCard";

import { motion } from "framer-motion";
import { useGetAuctionsQuery } from "../app/services/api/auction";
import sliceData from "../helpers/sliceData";
import ClientPagination from "../Components/table/ClientPagination";

const rowsPerPage = 10;

export default function FixedPrice() {
	const [page, setPage] = useState(1);
	const [slicedData, setSlicedData] = useState([]);

	const [liveAuctions, setLiveAuctions] = useState([]);
	const { data, isLoading, isError, error } = useGetAuctionsQuery();

	useEffect(() => {
		const filterAuctions = data?.filter(
			(item) => item.status.toLowerCase() === "live",
		);
		setLiveAuctions(filterAuctions);
	}, [data, isLoading]);

	useEffect(() => {
		const sliced = sliceData(liveAuctions, page, rowsPerPage);
		setSlicedData(sliced);
	}, [liveAuctions, page]);

	return (
		<motion.section
			initial={{ y: 10, opacity: 0.4, scale: 0.7 }}
			animate={{ y: 0, opacity: 1, scale: 1 }}
			transition={{ stiffness: 100 }}
			className="custom_container flex flex-col gap-4 relative"
		>
			<FixedPriceForm />

			<div className="">
				<h2 className="text-2xl font-bold mb-6">Your Live Auctions</h2>

				{isError ? (
					<ErrorCard error={error} />
				) : isLoading ? (
					<Loading />
				) : liveAuctions?.length > 0 ? (
					<>
						<Table
							headers={[
								"creditId",
								"auctionId",
								"swapRatio",
								"startDate",
								"endDate",
							]}
							data={slicedData}
						/>
						{liveAuctions?.length > rowsPerPage && (
							<ClientPagination
								data={liveAuctions}
								slicedData={slicedData}
								rowsPerPage={rowsPerPage}
								page={page}
								setPage={setPage}
							/>
						)}
					</>
				) : (
					<p>no live auction founded</p>
				)}
			</div>
		</motion.section>
	);
}
