import { motion } from "framer-motion";

import checkStatus from "../../Utils/checkStatusBadge";

export default function Table({
	data,
	buttons,
	additionalCols,
	setter,
	headers,
	customButtonId,
	emptyHandler,
}) {
	buttons = buttons || [];
	additionalCols = additionalCols || [];

	const wrapText = (text) => {
		if (text.length > 25) {
			return text.slice(0, 10) + "..." + text.slice(-8);
		}
		return text;
	};

	if (data?.length === 0 && emptyHandler) {
		return (
			<p className="bg-yellow-100 rounded-xl px-8 py-2 text-sm text-yellow-500 w-max">
				no item available yet
			</p>
		);
	}

	if (!headers) {
		return (
			<p className="px-4 py-3 bg-red-50 border border-red-200 rounded-lg text-sm text-slate-600">
				<span className="text-red-500 uppercase text-base">
					Developer Error!
				</span>
				<br />
				<span className="text-red-400">source: Table.js</span>
				<br />
				please enter headers field to render table
			</p>
		);
	}

	return (
		<motion.section
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			className="w-full overflow-x-scroll"
		>
			<table
				className="w-full border-2 border-collapse border-gray-100 bg-white rounded-t-lg 
			table-auto self-start overflow-hidden"
			>
				<thead className="border-b-2 bg-gray-100">
					<tr>
						{headers.map((item) => (
							<td
								className={`p-2 ${
									item === "project_details" && "hidden md:block"
								} uppercase text-[8px] md:text-[10px] 
								sticky overflow-y-scroll font-semibold text-[#5A6689]`}
								key={item}
							>
								{item.replace("_", " ")}
							</td>
						))}

						{additionalCols.map((item) => (
							<td
								key={item}
								className="px-2 py-1 lg:px-3 lg:py-2 uppercase text-center text-[8px] md:text-[10px] font-semibold text-[#5A6689]"
							>
								{item}
							</td>
						))}
					</tr>
				</thead>
				<tbody>
					{data?.map((item) => {
						return (
							<tr
								onClick={() => setter && setter(item)}
								key={item?._id}
								className="odd:bg-[#F9F9FA] text-[8px] md:text-[10px] hover:bg-slate-100/80 transition-all duration-300 select-none"
							>
								{headers.map((element) => {
									const checkItem = Object.entries(item).find(
										(flag) => flag[0] === element,
									);
									if (checkItem) {
										return (
											<td
												key={checkItem}
												className={`first:select-all px-3 py-2 lg:px-4 lg:py-3 ${
													checkItem[0] === "date" && "font-medium"
												}
										${checkItem[0] === "project_details" && "hidden md:block"}
										${checkItem[0] === "serial_number" ? "text-[#2563EB]" : "text-[#5A6689]"}
										${checkItem[0] === "status" && checkStatus(checkItem[1])}
										border text-center md:text-start md:border-none`}
											>
												<span>
													{checkItem[0] === "serial_number"
														? wrapText(checkItem[1])
														: checkItem[0] === "date" ||
														  checkItem[0] === "createdTime"
														? new Date(checkItem[1]).toLocaleString()
														: checkItem[1]}
												</span>
											</td>
										);
									} else {
										return <></>;
									}
								})}

								{Object.keys(buttons).length > 0 &&
									Object.entries(buttons).map((button) => (
										<td
											key={item[customButtonId] || Object.values(item)[0]}
											id={item[customButtonId] || Object.values(item)[0]}
											className={`px-3 py-2 lg:px-5 lg:py-3 xl:px-7 xl:py-4 z-50 ${checkStatus(
												button[1],
											)}`}
											onClick={(e) => {
												e.stopPropagation();
											}}
										>
											{button[1]}
										</td>
									))}
							</tr>
						);
					})}
				</tbody>
			</table>
		</motion.section>
	);
}
