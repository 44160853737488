import { useLayoutEffect, useState } from "react";
// hndle routes with react router dom
import { NavLink } from "react-router-dom";

// tooltip
import { Tooltip } from "react-tooltip";
// icons
import { AiOutlinePlus, AiOutlineMinus } from "react-icons/ai";

export default function SidebarItem({ path, title, icon, subTitle }) {
	const [subIsOpen, setSubIsOpen] = useState(false);
	const [size, setSize] = useState(0);

	useLayoutEffect(() => {
		function updateSize() {
			setSize(window.innerWidth);
		}
		window.addEventListener("resize", updateSize);
		updateSize();

		return () => window.removeEventListener("resize", updateSize);
	}, [size, setSize]);

	return (
		<div className="flex flex-col">
			<NavLink
				to={path}
				className={({ isActive }) =>
					isActive ? "sidebar__active" : "sidebar__inActive relative"
				}
				data-tooltip-id={path}
				data-tooltip-content={title}
				data-tooltip-variant="success"
			>
				{icon}
				<span className="hidden lg:block">{title}</span>
				{subTitle?.length > 0 && (
					<span className="self-center ml-auto z-50 transition-all duration-300 lg:block hidden">
						{subIsOpen ? (
							<AiOutlineMinus
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setSubIsOpen(false);
								}}
								className="w-auto h-full bg-muted/10 p-[2px] rounded-sm"
							/>
						) : (
							<AiOutlinePlus
								onClick={(e) => {
									e.preventDefault();
									e.stopPropagation();
									setSubIsOpen(true);
								}}
								className="w-auto h-full bg-muted/10 p-[2px] rounded-sm"
							/>
						)}
					</span>
				)}
			</NavLink>

			{subTitle?.length > 0 && (
				<div
					className={`${
						subIsOpen ? "lg:block hidden h-max" : "hidden"
					} transition-all duration-300`}
				>
					<div
						className={` mt-2 ml-10 mb-8 flex flex-col gap-3 transition-all duration-300 text-xs`}
					>
						{subTitle.map((item) => {
							return (
								<NavLink
									key={item?.id}
									to={item?.path}
									className={({ isActive }) =>
										isActive
											? "text-primary font-semibold"
											: "text-muted hover:text-black transition-all duration-300"
									}
								>
									{item?.title}
								</NavLink>
							);
						})}
					</div>
				</div>
			)}
			<Tooltip
				className="lg:hidden"
				id={path}
				place="right"
				style={{
					borderRadius: "10px",
					zIndex: 500,
					backgroundColor: "#474747",
				}}
			/>
		</div>
	);
}
