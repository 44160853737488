import { useRef, useState } from "react";
import { motion } from "framer-motion";
// IMAGES
import QRcode from "../../Assets/QRcode.svg";
// ICONS
import { IoMdCopy } from "react-icons/io";
export default function RecieveWallet() {
	const [copied, setCopied] = useState(false);
	const walletAddressRef = useRef(null);
	return (
		<motion.div
			initial={{ x: -100, scale: 0.8, opacity: 0.4 }}
			animate={{ x: 0, opacity: 1, scale: 1 }}
			transition={{ type: "spring" }}
			className="w-full py-2 flex flex-col items-center"
		>
			<h2 className="font-semibold text-slate-700 text-xl mb-2 text-center uppercase">
				recieve
			</h2>
			<img src={QRcode} alt="QRcode" className="w-32 h-auto" />
			<div className="flex items-center gap-1">
				<p
					ref={walletAddressRef}
					className="p-2 rounded-lg border text-[10px] md:text-xs"
				>
					0x6ccad24f4971bc1e91849f5c5d3b306a551833fb
				</p>
				<div className="flex flex-col relative ">
					<button
						onClick={() => {
							try {
								navigator.clipboard.writeText(
									walletAddressRef.current.innerText,
								);
								setCopied(walletAddressRef.current.innerText);
							} catch (error) {
								setCopied(false);
							}
						}}
						className="p-2 rounded-lg bg-primary w-full md:w-max"
					>
						<IoMdCopy />
					</button>
					{copied && (
						<motion.span
							initial={{ opacity: 0 }}
							animate={{ opacity: 1 }}
							className="absolute -bottom-4 text-xs text-green-400 w-max"
						>
							copied !
						</motion.span>
					)}
				</div>
			</div>
		</motion.div>
	);
}
