const countryList = {
	BD: {
		Name: "Bangladesh",
		Capital: {
			DLST: "null",
			TD: 6.0,
			Flg: 2,
			Name: "Dhaka",
			GeoPt: [23.43, 90.24],
		},
		GeoRectangle: {
			West: 88.0283279419,
			East: 92.6736831665,
			North: 26.6319484711,
			South: 20.7433319092,
		},
		SeqID: 19,
		GeoPt: [24.0, 90.0],
		TelPref: "880",
		CountryCodes: {
			tld: "bd",
			iso3: "BGD",
			iso2: "BD",
			fips: "BG",
			isoN: 50,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/bd.html",
	},
	BE: {
		Name: "Belgium",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Brussels",
			GeoPt: [50.5, 4.2],
		},
		GeoRectangle: {
			West: 2.54694366455,
			East: 6.40386152267,
			North: 51.5054512024,
			South: 49.4936027527,
		},
		SeqID: 20,
		GeoPt: [50.5, 4.0],
		TelPref: "32",
		CountryCodes: {
			tld: "be",
			iso3: "BEL",
			iso2: "BE",
			fips: "BE",
			isoN: 56,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/be.html",
	},
	BF: {
		Name: "Burkina Faso",
		Capital: {
			DLST: "null",
			TD: 0.0,
			Flg: 2,
			Name: "Ouagadougou",
			GeoPt: [12.22, -1.31],
		},
		GeoRectangle: {
			West: -5.5189166069,
			East: 2.40539526939,
			North: 15.0825948715,
			South: 9.40110683441,
		},
		SeqID: 21,
		GeoPt: [13.0, -2.0],
		TelPref: "226",
		CountryCodes: {
			tld: "bf",
			iso3: "BFA",
			iso2: "BF",
			fips: "UV",
			isoN: 854,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/bf.html",
	},
	BG: {
		Name: "Bulgaria",
		Capital: {
			DLST: 1.0,
			TD: 2.0,
			Flg: 2,
			Name: "Sofia",
			GeoPt: [42.41, 23.19],
		},
		GeoRectangle: {
			West: 22.3711624146,
			East: 28.6121692657,
			North: 44.2176475525,
			South: 41.2420768738,
		},
		SeqID: 22,
		GeoPt: [43.0, 25.0],
		TelPref: "359",
		CountryCodes: {
			tld: "bg",
			iso3: "BGR",
			iso2: "BG",
			fips: "BU",
			isoN: 100,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/bg.html",
	},
	BA: {
		Name: "Bosnia and Herzegovina",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Sarajevo",
			GeoPt: [43.52, 18.25],
		},
		GeoRectangle: {
			West: 15.7189435959,
			East: 19.6222267151,
			North: 45.239200592,
			South: 42.5461044312,
		},
		SeqID: 17,
		GeoPt: [44.0, 18.0],
		TelPref: "387",
		CountryCodes: {
			tld: "ba",
			iso3: "BIH",
			iso2: "BA",
			fips: "BK",
			isoN: 70,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ba.html",
	},
	BB: {
		Name: "Barbados",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "Bridgetown",
			GeoPt: [13.06, -59.37],
		},
		GeoRectangle: {
			West: -59.6489295959,
			East: -59.4203720093,
			North: 13.32725811,
			South: 13.0398426056,
		},
		SeqID: 18,
		GeoPt: [13.1, -59.32],
		TelPref: "1 246",
		CountryCodes: {
			tld: "bb",
			iso3: "BRB",
			iso2: "BB",
			fips: "BB",
			isoN: 52,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/bb.html",
	},
	WF: {
		Name: "Wallis and Futuna",
		Capital: {
			DLST: "null",
			TD: 12.0,
			Flg: 2,
			Name: "Mata-Utu",
			GeoPt: [-13.57, -171.56],
		},
		GeoRectangle: {
			West: -178.206802368,
			East: -176.128753662,
			North: -13.2142496109,
			South: -14.3286018372,
		},
		SeqID: 234,
		GeoPt: [-13.18, -176.12],
		TelPref: "681",
		CountryCodes: {
			tld: "wf",
			iso3: "WLF",
			iso2: "WF",
			fips: "WF",
			isoN: 876,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/wf.html",
	},
	BL: {
		Name: "Saint Barthelemy",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "Gustavia",
			GeoPt: [17.53, -62.51],
		},
		GeoRectangle: {
			West: -62.8763618469,
			East: -62.7924041748,
			North: 17.9326896667,
			South: 17.883939743,
		},
		SeqID: 26,
		GeoPt: [17.9, -62.85],
		TelPref: "590",
		CountryCodes: {
			tld: "bl",
			iso3: "BLM",
			iso2: "BL",
			fips: "TB",
			isoN: 652,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/bl.html",
	},
	BM: {
		Name: "Bermuda",
		Capital: {
			DLST: 1.0,
			TD: -4.0,
			Flg: 2,
			Name: "Hamilton",
			GeoPt: [32.17, -64.47],
		},
		GeoRectangle: {
			West: -64.8960647583,
			East: -64.6519851685,
			North: 32.3790092468,
			South: 32.2466316223,
		},
		SeqID: 27,
		GeoPt: [32.2, -64.45],
		TelPref: "1 441",
		CountryCodes: {
			tld: "bm",
			iso3: "BMU",
			iso2: "BM",
			fips: "BD",
			isoN: 60,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/bm.html",
	},
	BN: {
		Name: "Brunei",
		Capital: {
			DLST: "null",
			TD: 8.0,
			Flg: 2,
			Name: "Bandar Seri Begawan",
			GeoPt: [4.53, 114.56],
		},
		GeoRectangle: {
			West: 114.071434021,
			East: 115.359458923,
			North: 5.04716777802,
			South: 4.00308227539,
		},
		SeqID: 28,
		GeoPt: [4.3, 114.4],
		TelPref: "673",
		CountryCodes: {
			tld: "bn",
			iso3: "BRN",
			iso2: "BN",
			fips: "BX",
			isoN: 96,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/bn.html",
	},
	BO: {
		Name: "Bolivia",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "La Paz",
			GeoPt: [-16.3, -68.09],
		},
		GeoRectangle: {
			West: -69.6407699585,
			East: -57.4580917358,
			North: -9.68056583405,
			South: -22.8961353302,
		},
		SeqID: 29,
		GeoPt: [-17.0, -65.0],
		TelPref: "591",
		CountryCodes: {
			tld: "bo",
			iso3: "BOL",
			iso2: "BO",
			fips: "BL",
			isoN: 68,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/bo.html",
	},
	BH: {
		Name: "Bahrain",
		Capital: {
			DLST: "null",
			TD: 3.0,
			Flg: 2,
			Name: "Manama",
			GeoPt: [26.14, 50.34],
		},
		GeoRectangle: {
			West: 50.4541358948,
			East: 50.664478302,
			North: 26.282585144,
			South: 25.7968597412,
		},
		SeqID: 23,
		GeoPt: [26.0, 50.33],
		TelPref: "973",
		CountryCodes: {
			tld: "bh",
			iso3: "BHR",
			iso2: "BH",
			fips: "BA",
			isoN: 48,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/bh.html",
	},
	BI: {
		Name: "Burundi",
		Capital: {
			DLST: "null",
			TD: 2.0,
			Flg: 2,
			Name: "Bujumbura",
			GeoPt: [-3.22, 29.21],
		},
		GeoRectangle: {
			West: 28.993057251,
			East: 30.8477325439,
			North: -2.31012272835,
			South: -4.46571350098,
		},
		SeqID: 24,
		GeoPt: [-3.3, 30.0],
		TelPref: "257",
		CountryCodes: {
			tld: "bi",
			iso3: "BDI",
			iso2: "BI",
			fips: "BY",
			isoN: 108,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/bi.html",
	},
	BJ: {
		Name: "Benin",
		Capital: {
			DLST: "null",
			TD: 1.0,
			Flg: 2,
			Name: "Porto-Novo",
			GeoPt: [6.29, 2.37],
		},
		GeoRectangle: {
			West: 0.774574935436,
			East: 3.85170125961,
			North: 12.4183483124,
			South: 6.22574710846,
		},
		SeqID: 25,
		GeoPt: [9.3, 2.15],
		TelPref: "229",
		CountryCodes: {
			tld: "bj",
			iso3: "BEN",
			iso2: "BJ",
			fips: "BN",
			isoN: 204,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/bj.html",
	},
	BT: {
		Name: "Bhutan",
		Capital: {
			DLST: "null",
			TD: 6.0,
			Flg: 2,
			Name: "Thimphu",
			GeoPt: [27.29, 89.36],
		},
		GeoRectangle: {
			West: 88.7597122192,
			East: 92.1252059937,
			North: 28.3237800598,
			South: 26.7076377869,
		},
		SeqID: 32,
		GeoPt: [27.3, 90.3],
		TelPref: "975",
		CountryCodes: {
			tld: "bt",
			iso3: "BTN",
			iso2: "BT",
			fips: "BT",
			isoN: 64,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/bt.html",
	},
	JM: {
		Name: "Jamaica",
		Capital: {
			DLST: "null",
			TD: -5.0,
			Flg: 2,
			Name: "Kingston",
			GeoPt: [18.0, -76.48],
		},
		GeoRectangle: {
			West: -78.366645813,
			East: -76.1803131104,
			North: 18.5269794464,
			South: 17.7035503387,
		},
		SeqID: 108,
		GeoPt: [18.15, -77.3],
		TelPref: "1 876",
		CountryCodes: {
			tld: "jm",
			iso3: "JAM",
			iso2: "JM",
			fips: "JM",
			isoN: 388,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/jm.html",
	},
	BV: {
		Name: "Bouvet Island",
		Capital: null,
		GeoRectangle: {
			West: 3.3354985714,
			East: 3.48797631264,
			North: -54.4003181458,
			South: -54.462387085,
		},
		SeqID: 33,
		GeoPt: [-54.26, 3.24],
		TelPref: "47",
		CountryCodes: {
			tld: "bv",
			iso3: "BVT",
			iso2: "BV",
			fips: "BV",
			isoN: 74,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/bv.html",
	},
	BW: {
		Name: "Botswana",
		Capital: {
			DLST: "null",
			TD: 2.0,
			Flg: 2,
			Name: "Gaborone",
			GeoPt: [-24.45, 25.55],
		},
		GeoRectangle: {
			West: 19.9995326996,
			East: 29.3607845306,
			North: -17.7808094025,
			South: -26.9072494507,
		},
		SeqID: 34,
		GeoPt: [-22.0, 24.0],
		TelPref: "267",
		CountryCodes: {
			tld: "bw",
			iso3: "BWA",
			iso2: "BW",
			fips: "BC",
			isoN: 72,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/bw.html",
	},
	WS: {
		Name: "Samoa",
		Capital: {
			DLST: "null",
			TD: -11.0,
			Flg: 2,
			Name: "Apia",
			GeoPt: [-13.5, -171.44],
		},
		GeoRectangle: {
			West: -172.798614502,
			East: -171.415725708,
			North: -13.4322052002,
			South: -14.0409402847,
		},
		SeqID: 235,
		GeoPt: [-13.35, -172.2],
		TelPref: "685",
		CountryCodes: {
			tld: "ws",
			iso3: "WSM",
			iso2: "WS",
			fips: "WS",
			isoN: 882,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ws.html",
	},
	BR: {
		Name: "Brazil",
		Capital: {
			DLST: 1.0,
			TD: -3.0,
			Flg: 2,
			Name: "Brasilia",
			GeoPt: [-15.47, -47.55],
		},
		GeoRectangle: {
			West: -73.9855499268,
			East: -32.392993927,
			North: 5.26487779617,
			South: -33.7507133484,
		},
		SeqID: 30,
		GeoPt: [-10.0, -55.0],
		TelPref: "55",
		CountryCodes: {
			tld: "br",
			iso3: "BRA",
			iso2: "BR",
			fips: "BR",
			isoN: 76,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/br.html",
	},
	BS: {
		Name: "Bahamas",
		Capital: {
			DLST: 1.0,
			TD: -5.0,
			Flg: 2,
			Name: "Nassau",
			GeoPt: [25.05, -77.21],
		},
		GeoRectangle: {
			West: -78.9959259033,
			East: -74.423866272,
			North: 26.9192466736,
			South: 22.8527393341,
		},
		SeqID: 31,
		GeoPt: [24.15, -76.0],
		TelPref: "1 242",
		CountryCodes: {
			tld: "bs",
			iso3: "BHS",
			iso2: "BS",
			fips: "BF",
			isoN: 44,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/bs.html",
	},
	JE: {
		Name: "Jersey",
		Capital: {
			DLST: 1.0,
			TD: 0.0,
			Flg: 2,
			Name: "Saint Helier",
			GeoPt: [49.11, -2.06],
		},
		GeoRectangle: {
			West: -2.26002836227,
			East: -2.02208256721,
			North: 49.2650642395,
			South: 49.1698265076,
		},
		SeqID: 107,
		GeoPt: [49.15, -2.1],
		TelPref: "",
		CountryCodes: {
			tld: "je",
			iso3: "JEY",
			iso2: "JE",
			fips: "JE",
			isoN: 832,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/je.html",
	},
	BY: {
		Name: "Belarus",
		Capital: {
			DLST: 1.0,
			TD: 2.0,
			Flg: 2,
			Name: "Minsk",
			GeoPt: [53.54, 27.34],
		},
		GeoRectangle: {
			West: 23.1768856049,
			East: 32.7708091736,
			North: 56.165813446,
			South: 51.2564086914,
		},
		SeqID: 35,
		GeoPt: [53.0, 28.0],
		TelPref: "375",
		CountryCodes: {
			tld: "by",
			iso3: "BLR",
			iso2: "BY",
			fips: "BO",
			isoN: 112,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/by.html",
	},
	BZ: {
		Name: "Belize",
		Capital: {
			DLST: "null",
			TD: -6.0,
			Flg: 2,
			Name: "Belmopan",
			GeoPt: [17.15, -88.46],
		},
		GeoRectangle: {
			West: -89.224822998,
			East: -87.7769699097,
			North: 18.4965591431,
			South: 15.889298439,
		},
		SeqID: 36,
		GeoPt: [17.15, -88.45],
		TelPref: "501",
		CountryCodes: {
			tld: "bz",
			iso3: "BLZ",
			iso2: "BZ",
			fips: "BH",
			isoN: 84,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/bz.html",
	},
	RU: {
		Name: "Russia",
		Capital: {
			DLST: 1.0,
			TD: 3.0,
			Flg: 2,
			Name: "Moscow",
			GeoPt: [55.45, 37.35],
		},
		GeoRectangle: {
			West: 19.25,
			East: -169.05,
			North: 81.8573760986,
			South: 41.1888580322,
		},
		SeqID: 185,
		GeoPt: [60.0, 100.0],
		TelPref: "7",
		CountryCodes: {
			tld: "ru",
			iso3: "RUS",
			iso2: "RU",
			fips: "RS",
			isoN: 643,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ru.html",
	},
	RW: {
		Name: "Rwanda",
		Capital: {
			DLST: "null",
			TD: 2.0,
			Flg: 2,
			Name: "Kigali",
			GeoPt: [-1.57, 30.04],
		},
		GeoRectangle: {
			West: 28.8567905426,
			East: 30.8959617615,
			North: -1.05348086357,
			South: -2.84067940712,
		},
		SeqID: 186,
		GeoPt: [-2.0, 30.0],
		TelPref: "250",
		CountryCodes: {
			tld: "rw",
			iso3: "RWA",
			iso2: "RW",
			fips: "RW",
			isoN: 646,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/rw.html",
	},
	RS: {
		Name: "Serbia",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Belgrade",
			GeoPt: [44.5, 20.3],
		},
		GeoRectangle: {
			West: 18.8170185089,
			East: 23.0049991608,
			North: 46.1813926697,
			South: 41.8558235168,
		},
		SeqID: 184,
		GeoPt: [44.0, 21.0],
		TelPref: "381",
		CountryCodes: {
			tld: "rs",
			iso3: "SRB",
			iso2: "RS",
			fips: "RB",
			isoN: 688,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/rs.html",
	},
	TL: {
		Name: "Timor-Leste",
		Capital: {
			DLST: "null",
			TD: 9.0,
			Flg: 2,
			Name: "Dili",
			GeoPt: [-8.35, 125.36],
		},
		GeoRectangle: {
			West: 124.046089172,
			East: 127.308601379,
			North: -8.13583278656,
			South: -9.46362781525,
		},
		SeqID: 213,
		GeoPt: [-8.5, 125.55],
		TelPref: "670",
		CountryCodes: {
			tld: "tl",
			iso3: "TLS",
			iso2: "TL",
			fips: "TT",
			isoN: 626,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/tl.html",
	},
	TM: {
		Name: "Turkmenistan",
		Capital: {
			DLST: "null",
			TD: 5.0,
			Flg: 2,
			Name: "Ashgabat",
			GeoPt: [37.57, 58.23],
		},
		GeoRectangle: {
			West: 46.6846046448,
			East: 66.6843109131,
			North: 47.0156173706,
			South: 35.141078949,
		},
		SeqID: 214,
		GeoPt: [40.0, 60.0],
		TelPref: "993",
		CountryCodes: {
			tld: "tm",
			iso3: "TKM",
			iso2: "TM",
			fips: "TX",
			isoN: 795,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/tm.html",
	},
	TJ: {
		Name: "Tajikistan",
		Capital: {
			DLST: "null",
			TD: 5.0,
			Flg: 2,
			Name: "Dushanbe",
			GeoPt: [38.35, 68.48],
		},
		GeoRectangle: {
			West: 67.3871231079,
			East: 75.1372299194,
			North: 41.0422592163,
			South: 36.6741294861,
		},
		SeqID: 211,
		GeoPt: [39.0, 71.0],
		TelPref: "992",
		CountryCodes: {
			tld: "tj",
			iso3: "TJK",
			iso2: "TJ",
			fips: "TI",
			isoN: 762,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/tj.html",
	},
	RO: {
		Name: "Romania",
		Capital: {
			DLST: 1.0,
			TD: 2.0,
			Flg: 2,
			Name: "Bucharest",
			GeoPt: [44.26, 26.06],
		},
		GeoRectangle: {
			West: 20.2699699402,
			East: 29.6910572052,
			North: 48.2669525146,
			South: 43.6272964478,
		},
		SeqID: 183,
		GeoPt: [46.0, 25.0],
		TelPref: "40",
		CountryCodes: {
			tld: "ro",
			iso3: "ROU",
			iso2: "RO",
			fips: "RO",
			isoN: 642,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ro.html",
	},
	TK: {
		Name: "Tokelau",
		Capital: null,
		GeoRectangle: {
			West: -172.500350952,
			East: -171.211410522,
			North: -8.55361270905,
			South: -9.38111209869,
		},
		SeqID: 212,
		GeoPt: [-9.0, -172.0],
		TelPref: "690",
		CountryCodes: {
			tld: "tk",
			iso3: "TKL",
			iso2: "TK",
			fips: "TL",
			isoN: 772,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/tk.html",
	},
	GW: {
		Name: "Guinea-Bissau",
		Capital: {
			DLST: "null",
			TD: 0.0,
			Flg: 2,
			Name: "Bissau",
			GeoPt: [11.51, -15.35],
		},
		GeoRectangle: {
			West: -16.7175369263,
			East: -13.6365203857,
			North: 12.6807909012,
			South: 10.9242639542,
		},
		SeqID: 89,
		GeoPt: [12.0, -15.0],
		TelPref: "245",
		CountryCodes: {
			tld: "gw",
			iso3: "GNB",
			iso2: "GW",
			fips: "PU",
			isoN: 624,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/gw.html",
	},
	GU: {
		Name: "Guam",
		Capital: {
			DLST: "null",
			TD: 10.0,
			Flg: 2,
			Name: "Hagatna",
			GeoPt: [13.28, 144.44],
		},
		GeoRectangle: {
			West: 144.619216919,
			East: 144.953994751,
			North: 13.6523342133,
			South: 13.240609169,
		},
		SeqID: 88,
		GeoPt: [13.28, 144.47],
		TelPref: "1 671",
		CountryCodes: {
			tld: "gu",
			iso3: "GUM",
			iso2: "GU",
			fips: "GQ",
			isoN: 316,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/gu.html",
	},
	GT: {
		Name: "Guatemala",
		Capital: {
			DLST: 1.0,
			TD: -6.0,
			Flg: 2,
			Name: "Guatemala City",
			GeoPt: [14.37, -90.31],
		},
		GeoRectangle: {
			West: -92.2363052368,
			East: -88.2231903076,
			North: 17.8152236938,
			South: 13.7373008728,
		},
		SeqID: 87,
		GeoPt: [15.3, -90.15],
		TelPref: "502",
		CountryCodes: {
			tld: "gt",
			iso3: "GTM",
			iso2: "GT",
			fips: "GT",
			isoN: 320,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/gt.html",
	},
	GR: {
		Name: "Greece",
		Capital: {
			DLST: 1.0,
			TD: 2.0,
			Flg: 2,
			Name: "Athens",
			GeoPt: [37.59, 23.44],
		},
		GeoRectangle: {
			West: 19.3744430542,
			East: 28.2463912964,
			North: 41.7574234009,
			South: 34.8096351624,
		},
		SeqID: 86,
		GeoPt: [39.0, 22.0],
		TelPref: "30",
		CountryCodes: {
			tld: "gr",
			iso3: "GRC",
			iso2: "GR",
			fips: "GR",
			isoN: 300,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/gr.html",
	},
	GQ: {
		Name: "Equatorial Guinea",
		Capital: {
			DLST: "null",
			TD: 1.0,
			Flg: 2,
			Name: "Malabo",
			GeoPt: [3.45, 8.47],
		},
		GeoRectangle: {
			West: 9.34686374664,
			East: 11.3357257843,
			North: 2.34698939323,
			South: 0.920859932899,
		},
		SeqID: 85,
		GeoPt: [2.0, 10.0],
		TelPref: "240",
		CountryCodes: {
			tld: "gq",
			iso3: "GNQ",
			iso2: "GQ",
			fips: "EK",
			isoN: 226,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/gq.html",
	},
	JP: {
		Name: "Japan",
		Capital: {
			DLST: "null",
			TD: 9.0,
			Flg: 2,
			Name: "Tokyo",
			GeoPt: [35.41, 139.45],
		},
		GeoRectangle: {
			West: 122.938522339,
			East: 145.820907593,
			North: 45.523147583,
			South: 24.2494697571,
		},
		SeqID: 110,
		GeoPt: [36.0, 138.0],
		TelPref: "81",
		CountryCodes: {
			tld: "jp",
			iso3: "JPN",
			iso2: "JP",
			fips: "JA",
			isoN: 392,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/jp.html",
	},
	GY: {
		Name: "Guyana",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "Georgetown",
			GeoPt: [6.48, -58.1],
		},
		GeoRectangle: {
			West: -61.3847694397,
			East: -56.4802436829,
			North: 8.55756855011,
			South: 1.17507982254,
		},
		SeqID: 90,
		GeoPt: [5.0, -59.0],
		TelPref: "592",
		CountryCodes: {
			tld: "gy",
			iso3: "GUY",
			iso2: "GY",
			fips: "GY",
			isoN: 328,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/gy.html",
	},
	GG: {
		Name: "Guernsey",
		Capital: {
			DLST: 1.0,
			TD: 0.0,
			Flg: 2,
			Name: "Saint Peter Port",
			GeoPt: [49.27, -2.32],
		},
		GeoRectangle: {
			West: -2.68247246742,
			East: -2.50911068916,
			North: 49.5146980286,
			South: 49.4224128723,
		},
		SeqID: 79,
		GeoPt: [49.28, -2.35],
		TelPref: "44",
		CountryCodes: {
			tld: "gg",
			iso3: "GGY",
			iso2: "GG",
			fips: "GK",
			isoN: 831,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/gg.html",
	},
	GE: {
		Name: "Georgia",
		Capital: {
			DLST: "null",
			TD: 4.0,
			Flg: 2,
			Name: "T'bilisi",
			GeoPt: [41.43, 44.47],
		},
		GeoRectangle: {
			West: 40.0101318359,
			East: 46.7259750366,
			North: 43.5865020752,
			South: 41.0531921387,
		},
		SeqID: 78,
		GeoPt: [42.0, 43.3],
		TelPref: "995",
		CountryCodes: {
			tld: "ge",
			iso3: "GEO",
			iso2: "GE",
			fips: "GG",
			isoN: 268,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ge.html",
	},
	GD: {
		Name: "Grenada",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "Saint George's",
			GeoPt: [12.03, -61.45],
		},
		GeoRectangle: {
			West: -61.7999801636,
			East: -61.5738258362,
			North: 12.319396019,
			South: 11.9923725128,
		},
		SeqID: 77,
		GeoPt: [12.07, -61.4],
		TelPref: "1 473",
		CountryCodes: {
			tld: "gd",
			iso3: "GRD",
			iso2: "GD",
			fips: "GJ",
			isoN: 308,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/gd.html",
	},
	GB: {
		Name: "United Kingdom",
		Capital: {
			DLST: 1.0,
			TD: 0.0,
			Flg: 2,
			Name: "London",
			GeoPt: [51.3, -0.1],
		},
		GeoRectangle: {
			West: -8.62355613708,
			East: 1.75900018215,
			North: 60.8458099365,
			South: 49.9061889648,
		},
		SeqID: 76,
		GeoPt: [54.0, -2.0],
		TelPref: "44",
		CountryCodes: {
			tld: "uk",
			iso3: "GBR",
			iso2: "GB",
			fips: "UK",
			isoN: 826,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/gb.html",
	},
	GA: {
		Name: "Gabon",
		Capital: {
			DLST: "null",
			TD: 1.0,
			Flg: 2,
			Name: "Libreville",
			GeoPt: [0.23, 9.27],
		},
		GeoRectangle: {
			West: 8.69546985626,
			East: 14.5023488998,
			North: 2.32261228561,
			South: -3.97880625725,
		},
		SeqID: 75,
		GeoPt: [-1.0, 11.45],
		TelPref: "241",
		CountryCodes: {
			tld: "ga",
			iso3: "GAB",
			iso2: "GA",
			fips: "GB",
			isoN: 266,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ga.html",
	},
	SV: {
		Name: "El Salvador",
		Capital: {
			DLST: "null",
			TD: -6.0,
			Flg: 2,
			Name: "San Salvador",
			GeoPt: [13.42, -89.12],
		},
		GeoRectangle: {
			West: -90.1286697388,
			East: -87.6921539307,
			North: 14.4450683594,
			South: 13.1486778259,
		},
		SeqID: 203,
		GeoPt: [13.5, -88.55],
		TelPref: "503",
		CountryCodes: {
			tld: "sv",
			iso3: "SLV",
			iso2: "SV",
			fips: "ES",
			isoN: 222,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/sv.html",
	},
	GN: {
		Name: "Guinea",
		Capital: {
			DLST: "null",
			TD: 0.0,
			Flg: 2,
			Name: "Conakry",
			GeoPt: [9.33, -13.42],
		},
		GeoRectangle: {
			West: -14.9266204834,
			East: -7.64107036591,
			North: 12.6762218475,
			South: 7.19355249405,
		},
		SeqID: 84,
		GeoPt: [11.0, -10.0],
		TelPref: "224",
		CountryCodes: {
			tld: "gn",
			iso3: "GIN",
			iso2: "GN",
			fips: "GV",
			isoN: 324,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/gn.html",
	},
	GM: {
		Name: "Gambia",
		Capital: {
			DLST: "null",
			TD: 0.0,
			Flg: 2,
			Name: "Banjul",
			GeoPt: [13.27, -16.34],
		},
		GeoRectangle: {
			West: -16.8250827789,
			East: -13.797791481,
			North: 13.8265724182,
			South: 13.064250946,
		},
		SeqID: 83,
		GeoPt: [13.28, -16.34],
		TelPref: "220",
		CountryCodes: {
			tld: "gm",
			iso3: "GMB",
			iso2: "GM",
			fips: "GA",
			isoN: 270,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/gm.html",
	},
	GL: {
		Name: "Greenland",
		Capital: {
			DLST: 1.0,
			TD: -3.0,
			Flg: 2,
			Name: "Nuuk",
			GeoPt: [64.11, -51.45],
		},
		GeoRectangle: {
			West: -73.0420379639,
			East: -11.3123178482,
			North: 83.6273651123,
			South: 59.7773971558,
		},
		SeqID: 82,
		GeoPt: [72.0, -40.0],
		TelPref: "299",
		CountryCodes: {
			tld: "gl",
			iso3: "GRL",
			iso2: "GL",
			fips: "GL",
			isoN: 304,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/gl.html",
	},
	GI: {
		Name: "Gibraltar",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Gibraltar",
			GeoPt: [36.08, -5.21],
		},
		GeoRectangle: {
			West: -5.35725069046,
			East: -5.33963823318,
			North: 36.1598091125,
			South: 36.1124076843,
		},
		SeqID: 81,
		GeoPt: [36.08, -5.21],
		TelPref: "350",
		CountryCodes: {
			tld: "gi",
			iso3: "GIB",
			iso2: "GI",
			fips: "GI",
			isoN: 292,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/gi.html",
	},
	GH: {
		Name: "Ghana",
		Capital: {
			DLST: "null",
			TD: 0.0,
			Flg: 2,
			Name: "Accra",
			GeoPt: [5.33, -0.13],
		},
		GeoRectangle: {
			West: -3.2554204464,
			East: 1.19178116322,
			North: 11.1733026505,
			South: 4.73672246933,
		},
		SeqID: 80,
		GeoPt: [8.0, -2.0],
		TelPref: "233",
		CountryCodes: {
			tld: "gh",
			iso3: "GHA",
			iso2: "GH",
			fips: "GH",
			isoN: 288,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/gh.html",
	},
	OM: {
		Name: "Oman",
		Capital: {
			DLST: "null",
			TD: 4.0,
			Flg: 2,
			Name: "Muscat",
			GeoPt: [23.37, 58.35],
		},
		GeoRectangle: {
			West: 51.8819961548,
			East: 59.8365859985,
			North: 26.3879756927,
			South: 16.6457462311,
		},
		SeqID: 167,
		GeoPt: [21.0, 57.0],
		TelPref: "968",
		CountryCodes: {
			tld: "om",
			iso3: "OMN",
			iso2: "OM",
			fips: "MU",
			isoN: 512,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/om.html",
	},
	TN: {
		Name: "Tunisia",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Tunis",
			GeoPt: [36.48, 10.11],
		},
		GeoRectangle: {
			West: 7.52483224869,
			East: 11.5982789993,
			North: 37.5439224243,
			South: 30.2404136658,
		},
		SeqID: 215,
		GeoPt: [34.0, 9.0],
		TelPref: "216",
		CountryCodes: {
			tld: "tn",
			iso3: "TUN",
			iso2: "TN",
			fips: "TS",
			isoN: 788,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/tn.html",
	},
	JO: {
		Name: "Jordan",
		Capital: {
			DLST: 1.0,
			TD: 2.0,
			Flg: 2,
			Name: "Amman",
			GeoPt: [31.57, 35.56],
		},
		GeoRectangle: {
			West: 34.9599914551,
			East: 39.3011741638,
			North: 33.3676719666,
			South: 29.1858844757,
		},
		SeqID: 109,
		GeoPt: [31.0, 36.0],
		TelPref: "962",
		CountryCodes: {
			tld: "jo",
			iso3: "JOR",
			iso2: "JO",
			fips: "JO",
			isoN: 400,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/jo.html",
	},
	HR: {
		Name: "Croatia",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Zagreb",
			GeoPt: [45.48, 16.0],
		},
		GeoRectangle: {
			West: 13.4932203293,
			East: 19.4273910522,
			North: 46.5387573242,
			South: 42.4358825684,
		},
		SeqID: 94,
		GeoPt: [45.1, 15.3],
		TelPref: "385",
		CountryCodes: {
			tld: "hr",
			iso3: "HRV",
			iso2: "HR",
			fips: "HR",
			isoN: 191,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/hr.html",
	},
	HT: {
		Name: "Haiti",
		Capital: {
			DLST: 1.0,
			TD: -5.0,
			Flg: 2,
			Name: "Port-au-Prince",
			GeoPt: [18.32, -72.2],
		},
		GeoRectangle: {
			West: -74.4785919189,
			East: -71.6133499146,
			North: 20.0878219604,
			South: 18.0210285187,
		},
		SeqID: 95,
		GeoPt: [19.0, -72.25],
		TelPref: "509",
		CountryCodes: {
			tld: "ht",
			iso3: "HTI",
			iso2: "HT",
			fips: "HA",
			isoN: 332,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ht.html",
	},
	HU: {
		Name: "Hungary",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Budapest",
			GeoPt: [47.3, 19.05],
		},
		GeoRectangle: {
			West: 16.1118869781,
			East: 22.9060020447,
			North: 48.5856742859,
			South: 45.7436027527,
		},
		SeqID: 96,
		GeoPt: [47.0, 20.0],
		TelPref: "36",
		CountryCodes: {
			tld: "hu",
			iso3: "HUN",
			iso2: "HU",
			fips: "HU",
			isoN: 348,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/hu.html",
	},
	HK: {
		Name: "Hong Kong",
		Capital: null,
		GeoRectangle: {
			West: 113.837738037,
			East: 114.434761047,
			North: 22.5597801208,
			South: 22.1532478333,
		},
		SeqID: 91,
		GeoPt: [22.15, 114.1],
		TelPref: "852",
		CountryCodes: {
			tld: "hk",
			iso3: "HKG",
			iso2: "HK",
			fips: "HK",
			isoN: 344,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/hk.html",
	},
	HN: {
		Name: "Honduras",
		Capital: {
			DLST: 1.0,
			TD: -6.0,
			Flg: 2,
			Name: "Tegucigalpa",
			GeoPt: [14.06, -87.13],
		},
		GeoRectangle: {
			West: -89.3508071899,
			East: -83.1553878784,
			North: 16.5102596283,
			South: 12.9824094772,
		},
		SeqID: 93,
		GeoPt: [15.0, -86.3],
		TelPref: "504",
		CountryCodes: {
			tld: "hn",
			iso3: "HND",
			iso2: "HN",
			fips: "HO",
			isoN: 340,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/hn.html",
	},
	HM: {
		Name: "Heard Island and McDonald Islands",
		Capital: null,
		GeoRectangle: {
			West: 72.5965270996,
			East: 73.8591537476,
			North: -52.9094085693,
			South: -53.1920051575,
		},
		SeqID: 92,
		GeoPt: [-53.06, 72.31],
		TelPref: null,
		CountryCodes: {
			tld: "hm",
			iso3: "HMD",
			iso2: "HM",
			fips: "HM",
			isoN: 334,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/hm.html",
	},
	VE: {
		Name: "Venezuela",
		Capital: {
			DLST: "null",
			TD: -4.5,
			Flg: 2,
			Name: "Caracas",
			GeoPt: [10.3, -66.56],
		},
		GeoRectangle: {
			West: -73.3540878296,
			East: -59.8037757874,
			North: 12.2019042969,
			South: 0.626310884953,
		},
		SeqID: 229,
		GeoPt: [8.0, -66.0],
		TelPref: "58",
		CountryCodes: {
			tld: "ve",
			iso3: "VEN",
			iso2: "VE",
			fips: "VE",
			isoN: 862,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ve.html",
	},
	PR: {
		Name: "Puerto Rico",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "San Juan",
			GeoPt: [18.28, -66.07],
		},
		GeoRectangle: {
			West: -67.9427337646,
			East: -65.242729187,
			North: 18.5201683044,
			South: 17.9264030457,
		},
		SeqID: 177,
		GeoPt: [18.15, -66.3],
		TelPref: "1",
		CountryCodes: {
			tld: "pr",
			iso3: "PRI",
			iso2: "PR",
			fips: "RQ",
			isoN: 630,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/pr.html",
	},
	PS: {
		Name: "Palestinian Territory",
		Capital: null,
		GeoRectangle: {
			West: 34.2166557312,
			East: 35.573299408,
			North: 32.5463905334,
			South: 31.2165393829,
		},
		SeqID: 178,
		GeoPt: [31.25, 34.2],
		TelPref: "970",
		CountryCodes: {
			tld: "ps",
			iso3: "PSE",
			iso2: "PS",
			fips: "WE",
			isoN: 275,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ps.html",
	},
	PW: {
		Name: "Palau",
		Capital: {
			DLST: "null",
			TD: 9.0,
			Flg: 2,
			Name: "Melekeok",
			GeoPt: [7.29, 134.38],
		},
		GeoRectangle: {
			West: 134.123199463,
			East: 134.6537323,
			North: 7.73211193085,
			South: 6.88627719879,
		},
		SeqID: 180,
		GeoPt: [7.3, 134.3],
		TelPref: "680",
		CountryCodes: {
			tld: "pw",
			iso3: "PLW",
			iso2: "PW",
			fips: "PS",
			isoN: 585,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/pw.html",
	},
	PT: {
		Name: "Portugal",
		Capital: {
			DLST: 1.0,
			TD: 0.0,
			Flg: 2,
			Name: "Lisbon",
			GeoPt: [38.43, -9.08],
		},
		GeoRectangle: {
			West: -9.49594497681,
			East: -6.18269348145,
			North: 42.1456451416,
			South: 36.9806594849,
		},
		SeqID: 179,
		GeoPt: [39.3, -8.0],
		TelPref: "351",
		CountryCodes: {
			tld: "pt",
			iso3: "PRT",
			iso2: "PT",
			fips: "PO",
			isoN: 620,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/pt.html",
	},
	SJ: {
		Name: "Svalbard",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Longyearbyen",
			GeoPt: [78.13, 15.33],
		},
		GeoRectangle: {
			West: 17.6993865967,
			East: 33.2873382568,
			North: 80.7621002197,
			South: 79.2202911377,
		},
		SeqID: 195,
		GeoPt: [78.0, 20.0],
		TelPref: "",
		CountryCodes: {
			tld: "sj",
			iso3: "SJM",
			iso2: "SJ",
			fips: "SV",
			isoN: 744,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/sj.html",
	},
	PY: {
		Name: "Paraguay",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "Asuncion",
			GeoPt: [-25.16, -57.4],
		},
		GeoRectangle: {
			West: -62.6470832825,
			East: -54.259349823,
			North: -19.2940387726,
			South: -27.6087417603,
		},
		SeqID: 181,
		GeoPt: [-23.0, -58.0],
		TelPref: "595",
		CountryCodes: {
			tld: "py",
			iso3: "PRY",
			iso2: "PY",
			fips: "PA",
			isoN: 600,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/py.html",
	},
	IQ: {
		Name: "Iraq",
		Capital: {
			DLST: "null",
			TD: 3.0,
			Flg: 2,
			Name: "Baghdad",
			GeoPt: [33.2, 44.23],
		},
		GeoRectangle: {
			West: 38.7958831787,
			East: 48.575920105,
			North: 37.378036499,
			South: 29.069442749,
		},
		SeqID: 103,
		GeoPt: [33.0, 44.0],
		TelPref: "964",
		CountryCodes: {
			tld: "iq",
			iso3: "IRQ",
			iso2: "IQ",
			fips: "IZ",
			isoN: 368,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/iq.html",
	},
	PA: {
		Name: "Panama",
		Capital: {
			DLST: "null",
			TD: -5.0,
			Flg: 2,
			Name: "Panama City",
			GeoPt: [8.58, -79.32],
		},
		GeoRectangle: {
			West: -83.0514526367,
			East: -77.1740951538,
			North: 9.63751506805,
			South: 7.19790506363,
		},
		SeqID: 168,
		GeoPt: [9.0, -80.0],
		TelPref: "507",
		CountryCodes: {
			tld: "pa",
			iso3: "PAN",
			iso2: "PA",
			fips: "PM",
			isoN: 591,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/pa.html",
	},
	PF: {
		Name: "French Polynesia",
		Capital: {
			DLST: "null",
			TD: -10.0,
			Flg: 2,
			Name: "Papeete",
			GeoPt: [-17.32, -149.34],
		},
		GeoRectangle: {
			West: -152.877197266,
			East: -134.92980957,
			North: -7.90357208252,
			South: -27.6535739899,
		},
		SeqID: 170,
		GeoPt: [-15.0, -140.0],
		TelPref: "689",
		CountryCodes: {
			tld: "pf",
			iso3: "PYF",
			iso2: "PF",
			fips: "FP",
			isoN: 258,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/pf.html",
	},
	PG: {
		Name: "Papua New Guinea",
		Capital: {
			DLST: "null",
			TD: 10.0,
			Flg: 2,
			Name: "Port Moresby",
			GeoPt: [-9.3, 147.1],
		},
		GeoRectangle: {
			West: 140.842849731,
			East: 155.963470459,
			North: -1.31863880157,
			South: -11.6578626633,
		},
		SeqID: 171,
		GeoPt: [-6.0, 147.0],
		TelPref: "675",
		CountryCodes: {
			tld: "pg",
			iso3: "PNG",
			iso2: "PG",
			fips: "PP",
			isoN: 598,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/pg.html",
	},
	PE: {
		Name: "Peru",
		Capital: {
			DLST: "null",
			TD: -5.0,
			Flg: 2,
			Name: "Lima",
			GeoPt: [-12.03, -77.03],
		},
		GeoRectangle: {
			West: -81.326751709,
			East: -68.6779708862,
			North: -0.0129769984633,
			South: -18.3497314453,
		},
		SeqID: 169,
		GeoPt: [-10.0, -76.0],
		TelPref: "51",
		CountryCodes: {
			tld: "pe",
			iso3: "PER",
			iso2: "PE",
			fips: "PE",
			isoN: 604,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/pe.html",
	},
	PK: {
		Name: "Pakistan",
		Capital: {
			DLST: 1.0,
			TD: 5.0,
			Flg: 2,
			Name: "Islamabad",
			GeoPt: [33.42, 73.1],
		},
		GeoRectangle: {
			West: 60.8786087036,
			East: 77.840927124,
			North: 37.0970039368,
			South: 23.7867183685,
		},
		SeqID: 173,
		GeoPt: [30.0, 70.0],
		TelPref: "92",
		CountryCodes: {
			tld: "pk",
			iso3: "PAK",
			iso2: "PK",
			fips: "PK",
			isoN: 586,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/pk.html",
	},
	PH: {
		Name: "Philippines",
		Capital: {
			DLST: "null",
			TD: 8.0,
			Flg: 2,
			Name: "Manila",
			GeoPt: [14.35, 121.0],
		},
		GeoRectangle: {
			West: 116.931549072,
			East: 126.601531982,
			North: 21.1206130981,
			South: 4.64330530167,
		},
		SeqID: 172,
		GeoPt: [13.0, 122.0],
		TelPref: "63",
		CountryCodes: {
			tld: "ph",
			iso3: "PHL",
			iso2: "PH",
			fips: "RP",
			isoN: 608,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ph.html",
	},
	PN: {
		Name: "Pitcairn",
		Capital: {
			DLST: "null",
			TD: -9.0,
			Flg: 2,
			Name: "Adamstown",
			GeoPt: [-25.04, -130.05],
		},
		GeoRectangle: {
			West: -128.346466064,
			East: -124.772834778,
			North: -24.3158626556,
			South: -24.6725673676,
		},
		SeqID: 176,
		GeoPt: [-25.04, -130.06],
		TelPref: "870",
		CountryCodes: {
			tld: "pn",
			iso3: "PCN",
			iso2: "PN",
			fips: "PC",
			isoN: 612,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/pn.html",
	},
	PL: {
		Name: "Poland",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Warsaw",
			GeoPt: [52.15, 21.0],
		},
		GeoRectangle: {
			West: 14.1229982376,
			East: 24.1507511139,
			North: 54.8391418457,
			South: 49.0063591003,
		},
		SeqID: 174,
		GeoPt: [52.0, 20.0],
		TelPref: "48",
		CountryCodes: {
			tld: "pl",
			iso3: "POL",
			iso2: "PL",
			fips: "PL",
			isoN: 616,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/pl.html",
	},
	PM: {
		Name: "Saint Pierre and Miquelon",
		Capital: {
			DLST: 1.0,
			TD: -3.0,
			Flg: 2,
			Name: "Saint-Pierre",
			GeoPt: [46.46, -56.11],
		},
		GeoRectangle: {
			West: -56.4206619263,
			East: -56.252986908,
			North: 47.1462898254,
			South: 46.7860336304,
		},
		SeqID: 175,
		GeoPt: [46.5, -56.2],
		TelPref: "508",
		CountryCodes: {
			tld: "pm",
			iso3: "SPM",
			iso2: "PM",
			fips: "SB",
			isoN: 666,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/pm.html",
	},
	ZM: {
		Name: "Zambia",
		Capital: {
			DLST: "null",
			TD: 2.0,
			Flg: 2,
			Name: "Lusaka",
			GeoPt: [-15.25, 28.17],
		},
		GeoRectangle: {
			West: 21.9993686676,
			East: 33.7057113647,
			North: -8.22435855865,
			South: -18.0794754028,
		},
		SeqID: 239,
		GeoPt: [-15.0, 30.0],
		TelPref: "260",
		CountryCodes: {
			tld: "zm",
			iso3: "ZMB",
			iso2: "ZM",
			fips: "ZA",
			isoN: 894,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/zm.html",
	},
	EH: {
		Name: "Western Sahara",
		Capital: null,
		GeoRectangle: {
			West: -17.1031856537,
			East: -8.6702747345,
			North: 27.6696777344,
			South: 20.7741546631,
		},
		SeqID: 64,
		GeoPt: [24.3, -13.0],
		TelPref: "",
		CountryCodes: {
			tld: "eh",
			iso3: "ESH",
			iso2: "EH",
			fips: "WI",
			isoN: 732,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/eh.html",
	},
	EE: {
		Name: "Estonia",
		Capital: {
			DLST: 1.0,
			TD: 2.0,
			Flg: 2,
			Name: "Tallinn",
			GeoPt: [59.26, 24.43],
		},
		GeoRectangle: {
			West: 21.8375816345,
			East: 28.2099742889,
			North: 59.6762313843,
			South: 57.5161857605,
		},
		SeqID: 62,
		GeoPt: [59.0, 26.0],
		TelPref: "372",
		CountryCodes: {
			tld: "ee",
			iso3: "EST",
			iso2: "EE",
			fips: "EN",
			isoN: 233,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ee.html",
	},
	EG: {
		Name: "Egypt",
		Capital: {
			DLST: 1.0,
			TD: 2.0,
			Flg: 2,
			Name: "Cairo",
			GeoPt: [30.03, 31.15],
		},
		GeoRectangle: {
			West: 24.6981086731,
			East: 35.7948684692,
			North: 31.6673374176,
			South: 21.7253856659,
		},
		SeqID: 63,
		GeoPt: [27.0, 30.0],
		TelPref: "20",
		CountryCodes: {
			tld: "eg",
			iso3: "EGY",
			iso2: "EG",
			fips: "EG",
			isoN: 818,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/eg.html",
	},
	ZA: {
		Name: "South Africa",
		Capital: {
			DLST: "null",
			TD: 2.0,
			Flg: 2,
			Name: "Pretoria",
			GeoPt: [-25.42, 28.13],
		},
		GeoRectangle: {
			West: 16.4580173492,
			East: 32.8959770203,
			North: -22.1266098022,
			South: -34.8398323059,
		},
		SeqID: 238,
		GeoPt: [-29.0, 24.0],
		TelPref: "27",
		CountryCodes: {
			tld: "za",
			iso3: "ZAF",
			iso2: "ZA",
			fips: "SF",
			isoN: 710,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/za.html",
	},
	EC: {
		Name: "Ecuador",
		Capital: {
			DLST: "null",
			TD: -5.0,
			Flg: 2,
			Name: "Quito",
			GeoPt: [-0.13, -78.3],
		},
		GeoRectangle: {
			West: -91.6618881226,
			East: -75.1845779419,
			North: 1.41893422604,
			South: -4.99882364273,
		},
		SeqID: 61,
		GeoPt: [-2.0, -77.3],
		TelPref: "593",
		CountryCodes: {
			tld: "ec",
			iso3: "ECU",
			iso2: "EC",
			fips: "EC",
			isoN: 218,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ec.html",
	},
	IT: {
		Name: "Italy",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Rome",
			GeoPt: [41.54, 12.29],
		},
		GeoRectangle: {
			West: 6.61488819122,
			East: 18.5134487152,
			North: 47.0952033997,
			South: 36.6527748108,
		},
		SeqID: 106,
		GeoPt: [42.5, 12.5],
		TelPref: "39",
		CountryCodes: {
			tld: "it",
			iso3: "ITA",
			iso2: "IT",
			fips: "IT",
			isoN: 380,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/it.html",
	},
	VN: {
		Name: "Vietnam",
		Capital: {
			DLST: "null",
			TD: 7.0,
			Flg: 2,
			Name: "Hanoi",
			GeoPt: [21.02, 105.51],
		},
		GeoRectangle: {
			West: 102.148216248,
			East: 109.464653015,
			North: 23.3888378143,
			South: 8.55960941315,
		},
		SeqID: 232,
		GeoPt: [16.1, 107.5],
		TelPref: "84",
		CountryCodes: {
			tld: "vn",
			iso3: "VNM",
			iso2: "VN",
			fips: "VM",
			isoN: 704,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/vn.html",
	},
	SB: {
		Name: "Solomon Islands",
		Capital: {
			DLST: "null",
			TD: 11.0,
			Flg: 2,
			Name: "Honiara",
			GeoPt: [-9.26, 159.57],
		},
		GeoRectangle: {
			West: 155.508590698,
			East: 166.980880737,
			North: -6.58961009979,
			South: -11.8505563736,
		},
		SeqID: 188,
		GeoPt: [-8.0, 159.0],
		TelPref: "677",
		CountryCodes: {
			tld: "sb",
			iso3: "SLB",
			iso2: "SB",
			fips: "BP",
			isoN: 90,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/sb.html",
	},
	EU: {
		Name: "European Union",
		Capital: null,
		GeoRectangle: { West: -18.17, East: 34.6, North: 70.1, South: 27.64 },
		SeqID: 68,
		GeoPt: [50.1021, 9.9],
		TelPref: null,
		CountryCodes: {
			tld: "eu",
			iso3: "N/A",
			iso2: "EU",
			fips: "N/A",
			isoN: 0,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/eu.html",
	},
	ET: {
		Name: "Ethiopia",
		Capital: {
			DLST: "null",
			TD: 3.0,
			Flg: 2,
			Name: "Addis Ababa",
			GeoPt: [9.02, 38.42],
		},
		GeoRectangle: {
			West: 32.9999351501,
			East: 47.9861831665,
			North: 14.8937511444,
			South: 3.40242171288,
		},
		SeqID: 67,
		GeoPt: [8.0, 38.0],
		TelPref: "251",
		CountryCodes: {
			tld: "et",
			iso3: "ETH",
			iso2: "ET",
			fips: "ET",
			isoN: 231,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/et.html",
	},
	SO: {
		Name: "Somalia",
		Capital: {
			DLST: "null",
			TD: 3.0,
			Flg: 2,
			Name: "Mogadishu",
			GeoPt: [2.04, 45.22],
		},
		GeoRectangle: {
			West: 40.9865875244,
			East: 51.4126434326,
			North: 11.9791669846,
			South: -1.67486822605,
		},
		SeqID: 200,
		GeoPt: [10.0, 49.0],
		TelPref: "252",
		CountryCodes: {
			tld: "so",
			iso3: "SOM",
			iso2: "SO",
			fips: "SO",
			isoN: 706,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/so.html",
	},
	ZW: {
		Name: "Zimbabwe",
		Capital: {
			DLST: "null",
			TD: 2.0,
			Flg: 2,
			Name: "Harare",
			GeoPt: [-17.5, 31.03],
		},
		GeoRectangle: {
			West: 25.2370243073,
			East: 33.056312561,
			North: -15.608833313,
			South: -22.4177417755,
		},
		SeqID: 240,
		GeoPt: [-20.0, 30.0],
		TelPref: "263",
		CountryCodes: {
			tld: "zw",
			iso3: "ZWE",
			iso2: "ZW",
			fips: "ZI",
			isoN: 716,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/zw.html",
	},
	SA: {
		Name: "Saudi Arabia",
		Capital: {
			DLST: "null",
			TD: 3.0,
			Flg: 2,
			Name: "Riyadh",
			GeoPt: [24.38, 46.43],
		},
		GeoRectangle: {
			West: 34.4956855774,
			East: 55.6665878296,
			North: 32.1583404541,
			South: 15.6142482758,
		},
		SeqID: 187,
		GeoPt: [25.0, 45.0],
		TelPref: "966",
		CountryCodes: {
			tld: "sa",
			iso3: "SAU",
			iso2: "SA",
			fips: "SA",
			isoN: 682,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/sa.html",
	},
	ES: {
		Name: "Spain",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Madrid",
			GeoPt: [40.24, -3.41],
		},
		GeoRectangle: {
			West: -18.1696414948,
			East: 4.31538963318,
			North: 43.7917251587,
			South: 27.6388168335,
		},
		SeqID: 66,
		GeoPt: [40.0, -4.0],
		TelPref: "34",
		CountryCodes: {
			tld: "es",
			iso3: "ESP",
			iso2: "ES",
			fips: "SP",
			isoN: 724,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/es.html",
	},
	ER: {
		Name: "Eritrea",
		Capital: {
			DLST: "null",
			TD: 3.0,
			Flg: 2,
			Name: "Asmara",
			GeoPt: [15.2, 38.56],
		},
		GeoRectangle: {
			West: 36.4387741089,
			East: 43.1346473694,
			North: 18.0030860901,
			South: 12.3595533371,
		},
		SeqID: 65,
		GeoPt: [15.0, 39.0],
		TelPref: "291",
		CountryCodes: {
			tld: "er",
			iso3: "ERI",
			iso2: "ER",
			fips: "ER",
			isoN: 232,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/er.html",
	},
	ME: {
		Name: "Montenegro",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Podgorica",
			GeoPt: [42.26, 19.16],
		},
		GeoRectangle: {
			West: 18.4613037109,
			East: 20.3588352203,
			North: 43.5701408386,
			South: 41.8501586914,
		},
		SeqID: 136,
		GeoPt: [42.3, 19.18],
		TelPref: "382",
		CountryCodes: {
			tld: "me",
			iso3: "MNE",
			iso2: "ME",
			fips: "MJ",
			isoN: 499,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/me.html",
	},
	MD: {
		Name: "Moldova",
		Capital: {
			DLST: 1.0,
			TD: 2.0,
			Flg: 2,
			Name: "Chisinau",
			GeoPt: [47.0, 28.51],
		},
		GeoRectangle: {
			West: 26.6189403534,
			East: 30.1354484558,
			North: 48.4901733398,
			South: 45.4688796997,
		},
		SeqID: 135,
		GeoPt: [47.0, 29.0],
		TelPref: "373",
		CountryCodes: {
			tld: "md",
			iso3: "MDA",
			iso2: "MD",
			fips: "MD",
			isoN: 498,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/md.html",
	},
	MG: {
		Name: "Madagascar",
		Capital: {
			DLST: "null",
			TD: 3.0,
			Flg: 2,
			Name: "Antananarivo",
			GeoPt: [-18.55, 47.31],
		},
		GeoRectangle: {
			West: 43.2248687744,
			East: 50.4837875366,
			North: -11.9454317093,
			South: -25.6089553833,
		},
		SeqID: 138,
		GeoPt: [-20.0, 47.0],
		TelPref: "261",
		CountryCodes: {
			tld: "mg",
			iso3: "MDG",
			iso2: "MG",
			fips: "MA",
			isoN: 450,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/mg.html",
	},
	MF: {
		Name: "Saint Martin",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "Marigot",
			GeoPt: [18.04, -63.05],
		},
		GeoRectangle: {
			West: -63.1527709961,
			East: -63.0129890442,
			North: 18.1303577423,
			South: 18.0522289276,
		},
		SeqID: 137,
		GeoPt: [18.05, -63.57],
		TelPref: "1 599",
		CountryCodes: {
			tld: "mf",
			iso3: "MAF",
			iso2: "MF",
			fips: "RN",
			isoN: 663,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/mf.html",
	},
	MA: {
		Name: "Morocco",
		Capital: {
			DLST: "null",
			TD: 0.0,
			Flg: 2,
			Name: "Rabat",
			GeoPt: [34.01, -6.49],
		},
		GeoRectangle: {
			West: -13.1685876846,
			East: -0.991749882698,
			North: 35.9280319214,
			South: 27.6621112823,
		},
		SeqID: 133,
		GeoPt: [32.0, -5.0],
		TelPref: "212",
		CountryCodes: {
			tld: "ma",
			iso3: "MAR",
			iso2: "MA",
			fips: "MO",
			isoN: 504,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ma.html",
	},
	MC: {
		Name: "Monaco",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Monaco",
			GeoPt: [43.44, 7.25],
		},
		GeoRectangle: {
			West: 7.38638830185,
			East: 7.43929338455,
			North: 43.7730522156,
			South: 43.7275428772,
		},
		SeqID: 134,
		GeoPt: [43.44, 7.24],
		TelPref: "377",
		CountryCodes: {
			tld: "mc",
			iso3: "MCO",
			iso2: "MC",
			fips: "MN",
			isoN: 492,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/mc.html",
	},
	UZ: {
		Name: "Uzbekistan",
		Capital: {
			DLST: "null",
			TD: 5.0,
			Flg: 2,
			Name: "Tashkent",
			GeoPt: [41.2, 69.18],
		},
		GeoRectangle: {
			West: 55.9966316223,
			East: 73.1322860718,
			North: 45.5750083923,
			South: 37.1844367981,
		},
		SeqID: 226,
		GeoPt: [41.0, 64.0],
		TelPref: "998",
		CountryCodes: {
			tld: "uz",
			iso3: "UZB",
			iso2: "UZ",
			fips: "UZ",
			isoN: 860,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/uz.html",
	},
	MM: {
		Name: "Myanmar",
		Capital: {
			DLST: "null",
			TD: 6.5,
			Flg: 2,
			Name: "Rangoon",
			GeoPt: [16.48, 96.09],
		},
		GeoRectangle: {
			West: 92.1892700195,
			East: 101.176795959,
			North: 28.5432510376,
			South: 9.78458118439,
		},
		SeqID: 142,
		GeoPt: [22.0, 98.0],
		TelPref: "95",
		CountryCodes: {
			tld: "mm",
			iso3: "MMR",
			iso2: "MM",
			fips: "BM",
			isoN: 104,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/mm.html",
	},
	ML: {
		Name: "Mali",
		Capital: {
			DLST: "null",
			TD: 0.0,
			Flg: 2,
			Name: "Bamako",
			GeoPt: [12.39, -8.0],
		},
		GeoRectangle: {
			West: -12.2426156998,
			East: 4.24496889114,
			North: 25.000005722,
			South: 10.1595115662,
		},
		SeqID: 141,
		GeoPt: [17.0, -4.0],
		TelPref: "223",
		CountryCodes: {
			tld: "ml",
			iso3: "MLI",
			iso2: "ML",
			fips: "ML",
			isoN: 466,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ml.html",
	},
	MO: {
		Name: "Macao",
		Capital: null,
		GeoRectangle: {
			West: 113.528938293,
			East: 113.565841675,
			North: 22.2223377228,
			South: 22.1803855896,
		},
		SeqID: 144,
		GeoPt: [22.1, 113.33],
		TelPref: "853",
		CountryCodes: {
			tld: "mo",
			iso3: "MAC",
			iso2: "MO",
			fips: "MC",
			isoN: 446,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/mo.html",
	},
	MN: {
		Name: "Mongolia",
		Capital: {
			DLST: "null",
			TD: 8.0,
			Flg: 2,
			Name: "Ulaanbaatar",
			GeoPt: [47.55, 106.55],
		},
		GeoRectangle: {
			West: 87.7496490479,
			East: 119.924324036,
			North: 52.1542549133,
			South: 41.5676307678,
		},
		SeqID: 143,
		GeoPt: [46.0, 105.0],
		TelPref: "976",
		CountryCodes: {
			tld: "mn",
			iso3: "MNG",
			iso2: "MN",
			fips: "MG",
			isoN: 496,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/mn.html",
	},
	MH: {
		Name: "Marshall Islands",
		Capital: {
			DLST: "null",
			TD: 12.0,
			Flg: 2,
			Name: "Majuro",
			GeoPt: [7.06, 171.23],
		},
		GeoRectangle: {
			West: 165.524902344,
			East: 171.93182373,
			North: 14.6200017929,
			South: 5.58763837814,
		},
		SeqID: 139,
		GeoPt: [9.0, 168.0],
		TelPref: "692",
		CountryCodes: {
			tld: "mh",
			iso3: "MHL",
			iso2: "MH",
			fips: "RM",
			isoN: 584,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/mh.html",
	},
	MK: {
		Name: "Macedonia",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Skopje",
			GeoPt: [42.0, 21.26],
		},
		GeoRectangle: {
			West: 20.4646930695,
			East: 23.0381412506,
			North: 42.3618125916,
			South: 40.8601875305,
		},
		SeqID: 140,
		GeoPt: [41.5, 22.0],
		TelPref: "389",
		CountryCodes: {
			tld: "mk",
			iso3: "MKD",
			iso2: "MK",
			fips: "MK",
			isoN: 807,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/mk.html",
	},
	MU: {
		Name: "Mauritius",
		Capital: {
			DLST: "null",
			TD: 4.0,
			Flg: 2,
			Name: "Port Louis",
			GeoPt: [-20.09, 57.29],
		},
		GeoRectangle: {
			West: 56.5127105713,
			East: 63.5001831055,
			North: -10.3192539215,
			South: -20.5257205963,
		},
		SeqID: 149,
		GeoPt: [-20.17, 57.33],
		TelPref: "230",
		CountryCodes: {
			tld: "mu",
			iso3: "MUS",
			iso2: "MU",
			fips: "MP",
			isoN: 480,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/mu.html",
	},
	MT: {
		Name: "Malta",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Valletta",
			GeoPt: [35.53, 14.3],
		},
		GeoRectangle: {
			West: 14.1915826797,
			East: 14.5776405334,
			North: 36.08203125,
			South: 35.8102684021,
		},
		SeqID: 148,
		GeoPt: [35.5, 14.35],
		TelPref: "356",
		CountryCodes: {
			tld: "mt",
			iso3: "MLT",
			iso2: "MT",
			fips: "MT",
			isoN: 470,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/mt.html",
	},
	MW: {
		Name: "Malawi",
		Capital: {
			DLST: "null",
			TD: 2.0,
			Flg: 2,
			Name: "Lilongwe",
			GeoPt: [-13.59, 33.47],
		},
		GeoRectangle: {
			West: 32.6739425659,
			East: 35.9168281555,
			North: -9.36753940582,
			South: -17.1250019073,
		},
		SeqID: 151,
		GeoPt: [-13.3, 34.0],
		TelPref: "265",
		CountryCodes: {
			tld: "mw",
			iso3: "MWI",
			iso2: "MW",
			fips: "MI",
			isoN: 454,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/mw.html",
	},
	MV: {
		Name: "Maldives",
		Capital: {
			DLST: "null",
			TD: 5.0,
			Flg: 2,
			Name: "Male",
			GeoPt: [4.1, 73.3],
		},
		GeoRectangle: {
			West: 72.6932067871,
			East: 73.6372909546,
			North: 7.09836149216,
			South: -0.692694067955,
		},
		SeqID: 150,
		GeoPt: [3.15, 73.0],
		TelPref: "960",
		CountryCodes: {
			tld: "mv",
			iso3: "MDV",
			iso2: "MV",
			fips: "MV",
			isoN: 462,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/mv.html",
	},
	MP: {
		Name: "Northern Mariana Islands",
		Capital: {
			DLST: "null",
			TD: 10.0,
			Flg: 2,
			Name: "Saipan",
			GeoPt: [15.12, 145.45],
		},
		GeoRectangle: {
			West: 145.126800537,
			East: 145.84942627,
			North: 18.8050861359,
			South: 14.1073598862,
		},
		SeqID: 145,
		GeoPt: [15.12, 145.45],
		TelPref: "1 670",
		CountryCodes: {
			tld: "mp",
			iso3: "MNP",
			iso2: "MP",
			fips: "CQ",
			isoN: 580,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/mp.html",
	},
	MS: {
		Name: "Montserrat",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "Plymouth",
			GeoPt: [16.42, -62.13],
		},
		GeoRectangle: {
			West: -62.2425880432,
			East: -62.1464157104,
			North: 16.8173313141,
			South: 16.6710033417,
		},
		SeqID: 147,
		GeoPt: [16.45, -62.12],
		TelPref: "1 664",
		CountryCodes: {
			tld: "ms",
			iso3: "MSR",
			iso2: "MS",
			fips: "MH",
			isoN: 500,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ms.html",
	},
	MR: {
		Name: "Mauritania",
		Capital: {
			DLST: "null",
			TD: 0.0,
			Flg: 2,
			Name: "Nouakchott",
			GeoPt: [18.07, -16.02],
		},
		GeoRectangle: {
			West: -17.0665245056,
			East: -4.82767343521,
			North: 27.2980766296,
			South: 14.7155456543,
		},
		SeqID: 146,
		GeoPt: [20.0, -12.0],
		TelPref: "222",
		CountryCodes: {
			tld: "mr",
			iso3: "MRT",
			iso2: "MR",
			fips: "MR",
			isoN: 478,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/mr.html",
	},
	IM: {
		Name: "Isle of Man",
		Capital: {
			DLST: 1.0,
			TD: 0.0,
			Flg: 2,
			Name: "Douglas",
			GeoPt: [54.09, -4.29],
		},
		GeoRectangle: {
			West: -4.79872274399,
			East: -4.3114991188,
			North: 54.4197311401,
			South: 54.0559120178,
		},
		SeqID: 100,
		GeoPt: [54.15, -4.3],
		TelPref: "44",
		CountryCodes: {
			tld: "im",
			iso3: "IMN",
			iso2: "IM",
			fips: "IM",
			isoN: 833,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/im.html",
	},
	UG: {
		Name: "Uganda",
		Capital: {
			DLST: "null",
			TD: 3.0,
			Flg: 2,
			Name: "Kampala",
			GeoPt: [0.19, 32.25],
		},
		GeoRectangle: {
			West: 29.5732498169,
			East: 35.0360565186,
			North: 4.214427948,
			South: -1.48405015469,
		},
		SeqID: 223,
		GeoPt: [1.0, 32.0],
		TelPref: "256",
		CountryCodes: {
			tld: "ug",
			iso3: "UGA",
			iso2: "UG",
			fips: "UG",
			isoN: 800,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ug.html",
	},
	MY: {
		Name: "Malaysia",
		Capital: {
			DLST: "null",
			TD: 8.0,
			Flg: 2,
			Name: "Kuala Lumpur",
			GeoPt: [3.1, 101.42],
		},
		GeoRectangle: {
			West: 99.6434402466,
			East: 119.26751709,
			North: 7.36341762543,
			South: 0.855221927166,
		},
		SeqID: 153,
		GeoPt: [2.3, 112.3],
		TelPref: "60",
		CountryCodes: {
			tld: "my",
			iso3: "MYS",
			iso2: "MY",
			fips: "MY",
			isoN: 458,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/my.html",
	},
	MX: {
		Name: "Mexico",
		Capital: {
			DLST: 1.0,
			TD: -6.0,
			Flg: 2,
			Name: "Mexico City",
			GeoPt: [19.26, -99.08],
		},
		GeoRectangle: {
			West: -118.453964233,
			East: -86.70337677,
			North: 32.7167663574,
			South: 14.5328645706,
		},
		SeqID: 152,
		GeoPt: [23.0, -102.0],
		TelPref: "52",
		CountryCodes: {
			tld: "mx",
			iso3: "MEX",
			iso2: "MX",
			fips: "MX",
			isoN: 484,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/mx.html",
	},
	IL: {
		Name: "Israel",
		Capital: {
			DLST: 1.0,
			TD: 2.0,
			Flg: 2,
			Name: "Jerusalem",
			GeoPt: [31.46, 35.14],
		},
		GeoRectangle: {
			West: 34.2304420471,
			East: 35.8768081665,
			North: 33.3401412964,
			South: 29.496635437,
		},
		SeqID: 99,
		GeoPt: [31.3, 34.45],
		TelPref: "972",
		CountryCodes: {
			tld: "il",
			iso3: "ISR",
			iso2: "IL",
			fips: "IS",
			isoN: 376,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/il.html",
	},
	FR: {
		Name: "France",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Paris",
			GeoPt: [48.52, 2.2],
		},
		GeoRectangle: {
			West: -5.14222288132,
			East: 9.56155776978,
			North: 51.0928115845,
			South: 41.3715744019,
		},
		SeqID: 74,
		GeoPt: [46.0, 2.0],
		TelPref: "33",
		CountryCodes: {
			tld: "fr",
			iso3: "FRA",
			iso2: "FR",
			fips: "FR",
			isoN: 250,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/fr.html",
	},
	IO: {
		Name: "British Indian Ocean Territory",
		Capital: null,
		GeoRectangle: {
			West: 71.2599639893,
			East: 72.4931716919,
			North: -5.26833248138,
			South: -7.43802881241,
		},
		SeqID: 102,
		GeoPt: [-6.0, 71.3],
		TelPref: "",
		CountryCodes: {
			tld: "io",
			iso3: "IOT",
			iso2: "IO",
			fips: "IO",
			isoN: 86,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/io.html",
	},
	SH: {
		Name: "Saint Helena Ascension and Tristan da Cunha",
		Capital: {
			DLST: "null",
			TD: 0.0,
			Flg: 2,
			Name: "Jamestown",
			GeoPt: [-15.56, -5.44],
		},
		GeoRectangle: {
			West: -14.4212312698,
			East: -5.63875246048,
			North: -7.88781452179,
			South: -16.0195465088,
		},
		SeqID: 193,
		GeoPt: [-15.57, -5.42],
		TelPref: "290",
		CountryCodes: {
			tld: "sh",
			iso3: "SHN",
			iso2: "SH",
			fips: "SH",
			isoN: 654,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/sh.html",
	},
	FI: {
		Name: "Finland",
		Capital: {
			DLST: 1.0,
			TD: 2.0,
			Flg: 2,
			Name: "Helsinki",
			GeoPt: [60.1, 24.56],
		},
		GeoRectangle: {
			West: 19.5207176208,
			East: 31.5809459686,
			North: 70.0960617065,
			South: 59.8087730408,
		},
		SeqID: 69,
		GeoPt: [64.0, 26.0],
		TelPref: "358",
		CountryCodes: {
			tld: "fi",
			iso3: "FIN",
			iso2: "FI",
			fips: "FI",
			isoN: 246,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/fi.html",
	},
	FJ: {
		Name: "Fiji",
		Capital: {
			DLST: "null",
			TD: 12.0,
			Flg: 2,
			Name: "Suva",
			GeoPt: [-18.08, 178.25],
		},
		GeoRectangle: {
			West: 177.129364014,
			East: -178.424453735,
			North: -12.4801092148,
			South: -20.6759719849,
		},
		SeqID: 70,
		GeoPt: [-18.0, 175.0],
		TelPref: "679",
		CountryCodes: {
			tld: "fj",
			iso3: "FJI",
			iso2: "FJ",
			fips: "FJ",
			isoN: 242,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/fj.html",
	},
	FK: {
		Name: "Falkland Islands",
		Capital: {
			DLST: 1.0,
			TD: -4.0,
			Flg: 2,
			Name: "Stanley",
			GeoPt: [-51.42, -57.51],
		},
		GeoRectangle: {
			West: -61.345199585,
			East: -57.7124786377,
			North: -51.2406425476,
			South: -52.3605194092,
		},
		SeqID: 71,
		GeoPt: [-51.45, -59.0],
		TelPref: "500",
		CountryCodes: {
			tld: "fk",
			iso3: "FLK",
			iso2: "FK",
			fips: "FK",
			isoN: 238,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/fk.html",
	},
	FM: {
		Name: "Micronesia",
		Capital: {
			DLST: "null",
			TD: 11.0,
			Flg: 2,
			Name: "Palikir",
			GeoPt: [6.55, 158.09],
		},
		GeoRectangle: {
			West: 138.052810669,
			East: 163.034912109,
			North: 9.63636207581,
			South: 5.26533222198,
		},
		SeqID: 72,
		GeoPt: [6.55, 158.15],
		TelPref: "691",
		CountryCodes: {
			tld: "fm",
			iso3: "FSM",
			iso2: "FM",
			fips: "FM",
			isoN: 583,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/fm.html",
	},
	FO: {
		Name: "Faroe Islands",
		Capital: {
			DLST: 1.0,
			TD: 0.0,
			Flg: 2,
			Name: "Torshavn",
			GeoPt: [62.01, -6.46],
		},
		GeoRectangle: {
			West: -7.45800065994,
			East: -6.39958238602,
			North: 62.4007530212,
			South: 61.3949356079,
		},
		SeqID: 73,
		GeoPt: [62.0, -7.0],
		TelPref: "298",
		CountryCodes: {
			tld: "fo",
			iso3: "FRO",
			iso2: "FO",
			fips: "FO",
			isoN: 234,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/fo.html",
	},
	NI: {
		Name: "Nicaragua",
		Capital: {
			DLST: "null",
			TD: -6.0,
			Flg: 2,
			Name: "Managua",
			GeoPt: [12.09, -86.17],
		},
		GeoRectangle: {
			West: -87.690322876,
			East: -82.73828125,
			North: 15.0259103775,
			South: 10.7075414658,
		},
		SeqID: 160,
		GeoPt: [13.0, -85.0],
		TelPref: "505",
		CountryCodes: {
			tld: "ni",
			iso3: "NIC",
			iso2: "NI",
			fips: "NU",
			isoN: 558,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ni.html",
	},
	NL: {
		Name: "Netherlands",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Amsterdam",
			GeoPt: [52.23, 4.54],
		},
		GeoRectangle: {
			West: 3.36255574226,
			East: 7.22794485092,
			North: 53.5122032166,
			South: 50.7539138794,
		},
		SeqID: 161,
		GeoPt: [52.3, 5.45],
		TelPref: "31",
		CountryCodes: {
			tld: "nl",
			iso3: "NLD",
			iso2: "NL",
			fips: "NL",
			isoN: 528,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/nl.html",
	},
	NO: {
		Name: "Norway",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Oslo",
			GeoPt: [59.55, 10.45],
		},
		GeoRectangle: {
			West: 4.65016651154,
			East: 30.945558548,
			North: 71.188117981,
			South: 57.9779129028,
		},
		SeqID: 162,
		GeoPt: [62.0, 10.0],
		TelPref: "47",
		CountryCodes: {
			tld: "no",
			iso3: "NOR",
			iso2: "NO",
			fips: "NO",
			isoN: 578,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/no.html",
	},
	NA: {
		Name: "Namibia",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Windhoek",
			GeoPt: [-22.34, 17.05],
		},
		GeoRectangle: {
			West: 11.715628624,
			East: 25.2567043304,
			North: -16.9598903656,
			South: -28.9714336395,
		},
		SeqID: 155,
		GeoPt: [-22.0, 17.0],
		TelPref: "264",
		CountryCodes: {
			tld: "na",
			iso3: "NAM",
			iso2: "NA",
			fips: "WA",
			isoN: 516,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/na.html",
	},
	VU: {
		Name: "Vanuatu",
		Capital: {
			DLST: "null",
			TD: 11.0,
			Flg: 2,
			Name: "Port-Vila",
			GeoPt: [-17.44, 168.19],
		},
		GeoRectangle: {
			West: 166.524963379,
			East: 169.904800415,
			North: -13.0734424591,
			South: -20.2489471436,
		},
		SeqID: 233,
		GeoPt: [-16.0, 167.0],
		TelPref: "678",
		CountryCodes: {
			tld: "vu",
			iso3: "VUT",
			iso2: "VU",
			fips: "NH",
			isoN: 548,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/vu.html",
	},
	NC: {
		Name: "New Caledonia",
		Capital: {
			DLST: "null",
			TD: 11.0,
			Flg: 2,
			Name: "Noumea",
			GeoPt: [-22.16, 166.27],
		},
		GeoRectangle: {
			West: 163.564651489,
			East: 168.129150391,
			North: -19.5497760773,
			South: -22.6980037689,
		},
		SeqID: 156,
		GeoPt: [-21.3, 165.3],
		TelPref: "687",
		CountryCodes: {
			tld: "nc",
			iso3: "NCL",
			iso2: "NC",
			fips: "NC",
			isoN: 540,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/nc.html",
	},
	NE: {
		Name: "Niger",
		Capital: {
			DLST: "null",
			TD: 1.0,
			Flg: 2,
			Name: "Niamey",
			GeoPt: [13.31, 2.07],
		},
		GeoRectangle: {
			West: 0.166249975562,
			East: 15.9956445694,
			North: 23.5250282288,
			South: 11.6969738007,
		},
		SeqID: 157,
		GeoPt: [16.0, 8.0],
		TelPref: "227",
		CountryCodes: {
			tld: "ne",
			iso3: "NER",
			iso2: "NE",
			fips: "NG",
			isoN: 562,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ne.html",
	},
	NF: {
		Name: "Norfolk Island",
		Capital: {
			DLST: "null",
			TD: 11.5,
			Flg: 2,
			Name: "Kingston",
			GeoPt: [-29.03, 167.58],
		},
		GeoRectangle: {
			West: 167.916381836,
			East: 167.999984741,
			North: -28.9923877716,
			South: -29.0527267456,
		},
		SeqID: 158,
		GeoPt: [-29.02, 167.57],
		TelPref: "672",
		CountryCodes: {
			tld: "nf",
			iso3: "NFK",
			iso2: "NF",
			fips: "NF",
			isoN: 574,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/nf.html",
	},
	NG: {
		Name: "Nigeria",
		Capital: {
			DLST: "null",
			TD: 1.0,
			Flg: 2,
			Name: "Abuja",
			GeoPt: [9.05, 7.32],
		},
		GeoRectangle: {
			West: 2.66843175888,
			East: 14.6800746918,
			North: 13.8920087814,
			South: 4.27714347839,
		},
		SeqID: 159,
		GeoPt: [10.0, 8.0],
		TelPref: "234",
		CountryCodes: {
			tld: "ng",
			iso3: "NGA",
			iso2: "NG",
			fips: "NI",
			isoN: 566,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ng.html",
	},
	NZ: {
		Name: "New Zealand",
		Capital: {
			DLST: 1.0,
			TD: 12.0,
			Flg: 2,
			Name: "Wellington",
			GeoPt: [-41.28, 174.51],
		},
		GeoRectangle: {
			West: 165.996170044,
			East: -176.275848389,
			North: -29.2410945892,
			South: -52.607585907,
		},
		SeqID: 166,
		GeoPt: [-41.0, 174.0],
		TelPref: "64",
		CountryCodes: {
			tld: "nz",
			iso3: "NZL",
			iso2: "NZ",
			fips: "NZ",
			isoN: 554,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/nz.html",
	},
	NP: {
		Name: "Nepal",
		Capital: {
			DLST: "null",
			TD: 5.75,
			Flg: 2,
			Name: "Kathmandu",
			GeoPt: [27.43, 85.19],
		},
		GeoRectangle: {
			West: 80.0562591553,
			East: 88.1993408203,
			North: 30.4333934784,
			South: 26.3567199707,
		},
		SeqID: 163,
		GeoPt: [28.0, 84.0],
		TelPref: "977",
		CountryCodes: {
			tld: "np",
			iso3: "NPL",
			iso2: "NP",
			fips: "NP",
			isoN: 524,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/np.html",
	},
	NR: {
		Name: "Nauru",
		Capital: null,
		GeoRectangle: {
			West: 166.899017334,
			East: 166.9453125,
			North: -0.504305899143,
			South: -0.552333116531,
		},
		SeqID: 164,
		GeoPt: [-0.32, 166.55],
		TelPref: "674",
		CountryCodes: {
			tld: "nr",
			iso3: "NRU",
			iso2: "NR",
			fips: "NR",
			isoN: 520,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/nr.html",
	},
	NU: {
		Name: "Niue",
		Capital: {
			DLST: "null",
			TD: -11.0,
			Flg: 2,
			Name: "Alofi",
			GeoPt: [-19.01, -169.55],
		},
		GeoRectangle: {
			West: -169.953079224,
			East: -169.781387329,
			North: -18.9633312225,
			South: -19.1455593109,
		},
		SeqID: 165,
		GeoPt: [-19.02, -169.52],
		TelPref: "683",
		CountryCodes: {
			tld: "nu",
			iso3: "NIU",
			iso2: "NU",
			fips: "NE",
			isoN: 570,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/nu.html",
	},
	CK: {
		Name: "Cook Islands",
		Capital: {
			DLST: "null",
			TD: -10.0,
			Flg: 2,
			Name: "Avarua",
			GeoPt: [-21.12, -159.46],
		},
		GeoRectangle: {
			West: -161.093673706,
			East: -157.31211853,
			North: -10.0231122971,
			South: -21.9441661835,
		},
		SeqID: 44,
		GeoPt: [-21.14, -159.46],
		TelPref: "682",
		CountryCodes: {
			tld: "ck",
			iso3: "COK",
			iso2: "CK",
			fips: "CW",
			isoN: 184,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ck.html",
	},
	CI: {
		Name: "Ivory Coast",
		Capital: {
			DLST: "null",
			TD: 0.0,
			Flg: 2,
			Name: "Yamoussoukro",
			GeoPt: [6.49, -5.17],
		},
		GeoRectangle: {
			West: -8.59930324554,
			East: -2.49489665031,
			North: 10.7366437912,
			South: 4.35706615448,
		},
		SeqID: 43,
		GeoPt: [8.0, -5.0],
		TelPref: "225",
		CountryCodes: {
			tld: "ci",
			iso3: "CIV",
			iso2: "CI",
			fips: "IV",
			isoN: 384,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ci.html",
	},
	CH: {
		Name: "Switzerland",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Bern",
			GeoPt: [46.57, 7.26],
		},
		GeoRectangle: {
			West: 5.9574713707,
			East: 10.4914731979,
			North: 47.8053359985,
			South: 45.8256874084,
		},
		SeqID: 42,
		GeoPt: [47.0, 8.0],
		TelPref: "41",
		CountryCodes: {
			tld: "ch",
			iso3: "CHE",
			iso2: "CH",
			fips: "SZ",
			isoN: 756,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ch.html",
	},
	CO: {
		Name: "Colombia",
		Capital: {
			DLST: "null",
			TD: -5.0,
			Flg: 2,
			Name: "Bogota",
			GeoPt: [4.36, -74.05],
		},
		GeoRectangle: {
			West: -81.7281188965,
			East: -66.8698272705,
			North: 13.3805036545,
			South: -4.22586965561,
		},
		SeqID: 48,
		GeoPt: [4.0, -72.0],
		TelPref: "57",
		CountryCodes: {
			tld: "co",
			iso3: "COL",
			iso2: "CO",
			fips: "CO",
			isoN: 170,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/co.html",
	},
	CN: {
		Name: "China",
		Capital: {
			DLST: "null",
			TD: 8.0,
			Flg: 2,
			Name: "Beijing",
			GeoPt: [39.55, 116.23],
		},
		GeoRectangle: {
			West: 73.5576782227,
			East: 134.77394104,
			North: 53.5608673096,
			South: 15.7754144669,
		},
		SeqID: 47,
		GeoPt: [35.0, 105.0],
		TelPref: "86",
		CountryCodes: {
			tld: "cn",
			iso3: "CHN",
			iso2: "CN",
			fips: "CH",
			isoN: 156,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/cn.html",
	},
	CM: {
		Name: "Cameroon",
		Capital: {
			DLST: "null",
			TD: 1.0,
			Flg: 2,
			Name: "Yaounde",
			GeoPt: [3.52, 11.31],
		},
		GeoRectangle: {
			West: 8.49476146698,
			East: 16.1921195984,
			North: 13.0780572891,
			South: 1.6525478363,
		},
		SeqID: 46,
		GeoPt: [6.0, 12.0],
		TelPref: "237",
		CountryCodes: {
			tld: "cm",
			iso3: "CMR",
			iso2: "CM",
			fips: "CM",
			isoN: 120,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/cm.html",
	},
	CL: {
		Name: "Chile",
		Capital: {
			DLST: 1.0,
			TD: -4.0,
			Flg: 2,
			Name: "Santiago",
			GeoPt: [-33.27, -70.4],
		},
		GeoRectangle: {
			West: -109.4559021,
			East: -66.4175491333,
			North: -17.5075492859,
			South: -55.9163551331,
		},
		SeqID: 45,
		GeoPt: [-30.0, -71.0],
		TelPref: "56",
		CountryCodes: {
			tld: "cl",
			iso3: "CHL",
			iso2: "CL",
			fips: "CI",
			isoN: 152,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/cl.html",
	},
	CC: {
		Name: "Cocos Islands",
		Capital: {
			DLST: "null",
			TD: 6.5,
			Flg: 2,
			Name: "West Island",
			GeoPt: [-12.1, 96.5],
		},
		GeoRectangle: {
			West: 96.8212661743,
			East: 96.9360961914,
			North: -12.1148033142,
			South: -12.1854171753,
		},
		SeqID: 38,
		GeoPt: [-12.3, 96.5],
		TelPref: "61",
		CountryCodes: {
			tld: "cc",
			iso3: "CCK",
			iso2: "CC",
			fips: "CK",
			isoN: 166,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/cc.html",
	},
	CA: {
		Name: "Canada",
		Capital: {
			DLST: 1.0,
			TD: -5.0,
			Flg: 2,
			Name: "Ottawa",
			GeoPt: [45.25, -75.42],
		},
		GeoRectangle: {
			West: -141.000015259,
			East: -52.6362838745,
			North: 83.1106338501,
			South: 41.6759757996,
		},
		SeqID: 37,
		GeoPt: [60.0, -95.0],
		TelPref: "1",
		CountryCodes: {
			tld: "ca",
			iso3: "CAN",
			iso2: "CA",
			fips: "CA",
			isoN: 124,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ca.html",
	},
	CG: {
		Name: "Congo Republic",
		Capital: {
			DLST: "null",
			TD: 1.0,
			Flg: 2,
			Name: "Brazzaville",
			GeoPt: [-4.15, 15.17],
		},
		GeoRectangle: {
			West: 11.2050075531,
			East: 18.6498413086,
			North: 3.70308232307,
			South: -5.02722358704,
		},
		SeqID: 41,
		GeoPt: [-1.0, 15.0],
		TelPref: "242",
		CountryCodes: {
			tld: "cg",
			iso3: "COG",
			iso2: "CG",
			fips: "CF",
			isoN: 178,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/cg.html",
	},
	CF: {
		Name: "Central African Republic",
		Capital: {
			DLST: "null",
			TD: 1.0,
			Flg: 2,
			Name: "Bangui",
			GeoPt: [4.22, 18.35],
		},
		GeoRectangle: {
			West: 14.4200954437,
			East: 27.4634246826,
			North: 11.0075702667,
			South: 2.22051358223,
		},
		SeqID: 40,
		GeoPt: [7.0, 21.0],
		TelPref: "236",
		CountryCodes: {
			tld: "cf",
			iso3: "CAF",
			iso2: "CF",
			fips: "CT",
			isoN: 140,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/cf.html",
	},
	CD: {
		Name: "Congo Democratic Republic",
		Capital: {
			DLST: "null",
			TD: 1.0,
			Flg: 2,
			Name: "Kinshasa",
			GeoPt: [-4.19, 15.18],
		},
		GeoRectangle: {
			West: 12.2041425705,
			East: 31.3059139252,
			North: 5.38609886169,
			South: -13.4556760788,
		},
		SeqID: 39,
		GeoPt: [0.0, 25.0],
		TelPref: "243",
		CountryCodes: {
			tld: "cd",
			iso3: "COD",
			iso2: "CD",
			fips: "CG",
			isoN: 180,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/cd.html",
	},
	CZ: {
		Name: "Czech Republic",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Prague",
			GeoPt: [50.05, 14.28],
		},
		GeoRectangle: {
			West: 12.09370327,
			East: 18.8522205353,
			North: 51.0536079407,
			South: 48.5813751221,
		},
		SeqID: 54,
		GeoPt: [49.45, 15.3],
		TelPref: "420",
		CountryCodes: {
			tld: "cz",
			iso3: "CZE",
			iso2: "CZ",
			fips: "EZ",
			isoN: 203,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/cz.html",
	},
	CY: {
		Name: "Cyprus",
		Capital: {
			DLST: 1.0,
			TD: 2.0,
			Flg: 2,
			Name: "Nicosia",
			GeoPt: [35.1, 33.22],
		},
		GeoRectangle: {
			West: 32.2730789185,
			East: 34.5979232788,
			North: 35.7015342712,
			South: 34.5634918213,
		},
		SeqID: 53,
		GeoPt: [35.0, 33.0],
		TelPref: "357",
		CountryCodes: {
			tld: "cy",
			iso3: "CYP",
			iso2: "CY",
			fips: "CY",
			isoN: 196,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/cy.html",
	},
	CX: {
		Name: "Christmas Island",
		Capital: {
			DLST: "null",
			TD: 7.0,
			Flg: 2,
			Name: "The Settlement",
			GeoPt: [-10.25, 105.43],
		},
		GeoRectangle: {
			West: 105.5389328,
			East: 105.719596863,
			North: -10.4157762527,
			South: -10.5757246017,
		},
		SeqID: 52,
		GeoPt: [-10.3, 105.4],
		TelPref: "61",
		CountryCodes: {
			tld: "cx",
			iso3: "CXR",
			iso2: "CX",
			fips: "KT",
			isoN: 162,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/cx.html",
	},
	CR: {
		Name: "Costa Rica",
		Capital: {
			DLST: "null",
			TD: -6.0,
			Flg: 2,
			Name: "San Jose",
			GeoPt: [9.56, -84.05],
		},
		GeoRectangle: {
			West: -85.9506378174,
			East: -82.5559768677,
			North: 11.2168207169,
			South: 8.03297328949,
		},
		SeqID: 49,
		GeoPt: [10.0, -84.0],
		TelPref: "506",
		CountryCodes: {
			tld: "cr",
			iso3: "CRI",
			iso2: "CR",
			fips: "CS",
			isoN: 188,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/cr.html",
	},
	CV: {
		Name: "Cape Verde",
		Capital: {
			DLST: "null",
			TD: -1.0,
			Flg: 2,
			Name: "Praia",
			GeoPt: [14.55, -23.31],
		},
		GeoRectangle: {
			West: -25.3587493896,
			East: -22.6694393158,
			North: 17.1971817017,
			South: 14.8080205917,
		},
		SeqID: 51,
		GeoPt: [16.0, -24.0],
		TelPref: "238",
		CountryCodes: {
			tld: "cv",
			iso3: "CPV",
			iso2: "CV",
			fips: "CV",
			isoN: 132,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/cv.html",
	},
	CU: {
		Name: "Cuba",
		Capital: {
			DLST: 1.0,
			TD: -5.0,
			Flg: 2,
			Name: "Havana",
			GeoPt: [23.07, -82.21],
		},
		GeoRectangle: {
			West: -84.9574432373,
			East: -74.1317672729,
			North: 23.2260456085,
			South: 19.8280792236,
		},
		SeqID: 50,
		GeoPt: [21.3, -80.0],
		TelPref: "53",
		CountryCodes: {
			tld: "cu",
			iso3: "CUB",
			iso2: "CU",
			fips: "CU",
			isoN: 192,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/cu.html",
	},
	SZ: {
		Name: "Swaziland",
		Capital: {
			DLST: "null",
			TD: 2.0,
			Flg: 2,
			Name: "Mbabane",
			GeoPt: [-26.18, 31.06],
		},
		GeoRectangle: {
			West: 30.7941036224,
			East: 32.1372642517,
			North: -25.7196445465,
			South: -27.3171043396,
		},
		SeqID: 205,
		GeoPt: [-26.3, 31.3],
		TelPref: "268",
		CountryCodes: {
			tld: "sz",
			iso3: "SWZ",
			iso2: "SZ",
			fips: "WZ",
			isoN: 748,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/sz.html",
	},
	SY: {
		Name: "Syria",
		Capital: {
			DLST: 1.0,
			TD: 2.0,
			Flg: 2,
			Name: "Damascus",
			GeoPt: [33.3, 36.18],
		},
		GeoRectangle: {
			West: 35.7272148132,
			East: 42.3850364685,
			North: 37.3191452026,
			South: 32.3106575012,
		},
		SeqID: 204,
		GeoPt: [35.0, 38.0],
		TelPref: "963",
		CountryCodes: {
			tld: "sy",
			iso3: "SYR",
			iso2: "SY",
			fips: "SY",
			isoN: 760,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/sy.html",
	},
	KG: {
		Name: "Kyrgyzstan",
		Capital: {
			DLST: "null",
			TD: 6.0,
			Flg: 2,
			Name: "Bishkek",
			GeoPt: [42.52, 74.36],
		},
		GeoRectangle: {
			West: 69.2765960693,
			East: 80.2831802368,
			North: 43.2382278442,
			South: 39.1728248596,
		},
		SeqID: 112,
		GeoPt: [41.0, 75.0],
		TelPref: "996",
		CountryCodes: {
			tld: "kg",
			iso3: "KGZ",
			iso2: "KG",
			fips: "KG",
			isoN: 417,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/kg.html",
	},
	KE: {
		Name: "Kenya",
		Capital: {
			DLST: "null",
			TD: 3.0,
			Flg: 2,
			Name: "Nairobi",
			GeoPt: [-1.17, 36.49],
		},
		GeoRectangle: {
			West: 33.9088516235,
			East: 41.8990821838,
			North: 5.01993894577,
			South: -4.67804765701,
		},
		SeqID: 111,
		GeoPt: [1.0, 38.0],
		TelPref: "254",
		CountryCodes: {
			tld: "ke",
			iso3: "KEN",
			iso2: "KE",
			fips: "KE",
			isoN: 404,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ke.html",
	},
	SR: {
		Name: "Suriname",
		Capital: {
			DLST: "null",
			TD: -3.0,
			Flg: 2,
			Name: "Paramaribo",
			GeoPt: [5.5, -55.1],
		},
		GeoRectangle: {
			West: -58.086566925,
			East: -53.9774856567,
			North: 6.0045466423,
			South: 1.83114480972,
		},
		SeqID: 201,
		GeoPt: [4.0, -56.0],
		TelPref: "597",
		CountryCodes: {
			tld: "sr",
			iso3: "SUR",
			iso2: "SR",
			fips: "NS",
			isoN: 740,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/sr.html",
	},
	KI: {
		Name: "Kiribati",
		Capital: {
			DLST: "null",
			TD: 12.0,
			Flg: 2,
			Name: "Tarawa",
			GeoPt: [1.19, 172.58],
		},
		GeoRectangle: {
			West: 172.955215454,
			East: -151.803863525,
			North: 1.94877815247,
			South: -11.4370393753,
		},
		SeqID: 114,
		GeoPt: [1.25, 173.0],
		TelPref: "686",
		CountryCodes: {
			tld: "ki",
			iso3: "KIR",
			iso2: "KI",
			fips: "KR",
			isoN: 296,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ki.html",
	},
	KH: {
		Name: "Cambodia",
		Capital: {
			DLST: "null",
			TD: 7.0,
			Flg: 2,
			Name: "Phnom Penh",
			GeoPt: [11.33, 104.55],
		},
		GeoRectangle: {
			West: 102.339981079,
			East: 107.627738953,
			North: 14.6864185333,
			South: 10.409081459,
		},
		SeqID: 113,
		GeoPt: [13.0, 105.0],
		TelPref: "855",
		CountryCodes: {
			tld: "kh",
			iso3: "KHM",
			iso2: "KH",
			fips: "CB",
			isoN: 116,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/kh.html",
	},
	KN: {
		Name: "Saint Kitts and Nevis",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "Basseterre",
			GeoPt: [17.18, -62.43],
		},
		GeoRectangle: {
			West: -62.8695640564,
			East: -62.543258667,
			North: 17.4201202393,
			South: 17.0953407288,
		},
		SeqID: 116,
		GeoPt: [17.2, -62.45],
		TelPref: "1 869",
		CountryCodes: {
			tld: "kn",
			iso3: "KNA",
			iso2: "KN",
			fips: "SC",
			isoN: 659,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/kn.html",
	},
	KM: {
		Name: "Comoros",
		Capital: {
			DLST: "null",
			TD: 3.0,
			Flg: 2,
			Name: "Moroni",
			GeoPt: [-11.42, 43.14],
		},
		GeoRectangle: {
			West: 43.2157859802,
			East: 44.5382270813,
			North: -11.3623800278,
			South: -12.3878583908,
		},
		SeqID: 115,
		GeoPt: [-12.1, 44.15],
		TelPref: "269",
		CountryCodes: {
			tld: "km",
			iso3: "COM",
			iso2: "KM",
			fips: "CN",
			isoN: 174,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/km.html",
	},
	ST: {
		Name: "Sao Tome and Principe",
		Capital: {
			DLST: "null",
			TD: 0.0,
			Flg: 2,
			Name: "Sao Tome",
			GeoPt: [0.12, 6.39],
		},
		GeoRectangle: {
			West: 6.47016906738,
			East: 7.46637487411,
			North: 1.70132315159,
			South: 0.0247659981251,
		},
		SeqID: 202,
		GeoPt: [1.0, 7.0],
		TelPref: "239",
		CountryCodes: {
			tld: "st",
			iso3: "STP",
			iso2: "ST",
			fips: "TP",
			isoN: 678,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/st.html",
	},
	SK: {
		Name: "Slovakia",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Bratislava",
			GeoPt: [48.09, 17.07],
		},
		GeoRectangle: {
			West: 16.8477478027,
			East: 22.5704460144,
			North: 49.6031723022,
			South: 47.7281036377,
		},
		SeqID: 196,
		GeoPt: [48.4, 19.3],
		TelPref: "421",
		CountryCodes: {
			tld: "sk",
			iso3: "SVK",
			iso2: "SK",
			fips: "LO",
			isoN: 703,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/sk.html",
	},
	KR: {
		Name: "Korea South",
		Capital: {
			DLST: "null",
			TD: 9.0,
			Flg: 2,
			Name: "Seoul",
			GeoPt: [37.33, 126.59],
		},
		GeoRectangle: {
			West: 125.88710022,
			East: 129.584686279,
			North: 38.6124534607,
			South: 33.1909408569,
		},
		SeqID: 118,
		GeoPt: [37.0, 127.3],
		TelPref: "82",
		CountryCodes: {
			tld: "kr",
			iso3: "KOR",
			iso2: "KR",
			fips: "KS",
			isoN: 410,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/kr.html",
	},
	SI: {
		Name: "Slovenia",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Ljubljana",
			GeoPt: [46.03, 14.31],
		},
		GeoRectangle: {
			West: 13.3830814362,
			East: 16.5660037994,
			North: 46.8779220581,
			South: 45.4131317139,
		},
		SeqID: 194,
		GeoPt: [46.07, 14.49],
		TelPref: "386",
		CountryCodes: {
			tld: "si",
			iso3: "SVN",
			iso2: "SI",
			fips: "SI",
			isoN: 705,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/si.html",
	},
	KP: {
		Name: "Korea North",
		Capital: {
			DLST: "null",
			TD: 9.0,
			Flg: 2,
			Name: "Pyongyang",
			GeoPt: [39.01, 125.45],
		},
		GeoRectangle: {
			West: 124.315872192,
			East: 130.67489624,
			North: 43.006061554,
			South: 37.673324585,
		},
		SeqID: 117,
		GeoPt: [40.0, 127.0],
		TelPref: "850",
		CountryCodes: {
			tld: "kp",
			iso3: "PRK",
			iso2: "KP",
			fips: "KN",
			isoN: 408,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/kp.html",
	},
	KW: {
		Name: "Kuwait",
		Capital: {
			DLST: "null",
			TD: 3.0,
			Flg: 2,
			Name: "Kuwait City",
			GeoPt: [29.22, 47.58],
		},
		GeoRectangle: {
			West: 46.5555496216,
			East: 48.4314804077,
			North: 30.0959472656,
			South: 28.5246086121,
		},
		SeqID: 119,
		GeoPt: [29.3, 45.45],
		TelPref: "965",
		CountryCodes: {
			tld: "kw",
			iso3: "KWT",
			iso2: "KW",
			fips: "KU",
			isoN: 414,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/kw.html",
	},
	SN: {
		Name: "Senegal",
		Capital: {
			DLST: "null",
			TD: 0.0,
			Flg: 2,
			Name: "Dakar",
			GeoPt: [14.4, -17.26],
		},
		GeoRectangle: {
			West: -17.535238266,
			East: -11.3558855057,
			North: 16.6916351318,
			South: 12.3072738647,
		},
		SeqID: 199,
		GeoPt: [14.0, -14.0],
		TelPref: "221",
		CountryCodes: {
			tld: "sn",
			iso3: "SEN",
			iso2: "SN",
			fips: "SG",
			isoN: 686,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/sn.html",
	},
	SM: {
		Name: "San Marino",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "San Marino",
			GeoPt: [43.56, 12.25],
		},
		GeoRectangle: {
			West: 12.4018592834,
			East: 12.5155563354,
			North: 43.9998092651,
			South: 43.8979110718,
		},
		SeqID: 198,
		GeoPt: [43.46, 12.25],
		TelPref: "378",
		CountryCodes: {
			tld: "sm",
			iso3: "SMR",
			iso2: "SM",
			fips: "SM",
			isoN: 674,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/sm.html",
	},
	SL: {
		Name: "Sierra Leone",
		Capital: {
			DLST: "null",
			TD: 0.0,
			Flg: 2,
			Name: "Freetown",
			GeoPt: [8.3, -13.15],
		},
		GeoRectangle: {
			West: -13.3076324463,
			East: -10.284236908,
			North: 10.0000009537,
			South: 6.92961025238,
		},
		SeqID: 197,
		GeoPt: [8.3, -11.3],
		TelPref: "232",
		CountryCodes: {
			tld: "sl",
			iso3: "SLE",
			iso2: "SL",
			fips: "SL",
			isoN: 694,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/sl.html",
	},
	SC: {
		Name: "Seychelles",
		Capital: {
			DLST: "null",
			TD: 4.0,
			Flg: 2,
			Name: "Victoria",
			GeoPt: [-4.38, 55.27],
		},
		GeoRectangle: {
			West: 46.2047615051,
			East: 56.2795143127,
			North: -4.28371620178,
			South: -9.75386810303,
		},
		SeqID: 189,
		GeoPt: [-4.35, 55.4],
		TelPref: "248",
		CountryCodes: {
			tld: "sc",
			iso3: "SYC",
			iso2: "SC",
			fips: "SE",
			isoN: 690,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/sc.html",
	},
	KZ: {
		Name: "Kazakhstan",
		Capital: {
			DLST: "null",
			TD: 6.0,
			Flg: 2,
			Name: "Astana",
			GeoPt: [51.1, 71.25],
		},
		GeoRectangle: {
			West: 46.4918518066,
			East: 87.3126831055,
			North: 55.4512023926,
			South: 40.9363288879,
		},
		SeqID: 121,
		GeoPt: [48.0, 68.0],
		TelPref: "7",
		CountryCodes: {
			tld: "kz",
			iso3: "KAZ",
			iso2: "KZ",
			fips: "KZ",
			isoN: 398,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/kz.html",
	},
	KY: {
		Name: "Cayman Islands",
		Capital: {
			DLST: "null",
			TD: -5.0,
			Flg: 2,
			Name: "George Town",
			GeoPt: [19.18, -81.23],
		},
		GeoRectangle: {
			West: -81.4327850342,
			East: -79.7272567749,
			North: 19.7617034912,
			South: 19.2630252838,
		},
		SeqID: 120,
		GeoPt: [19.3, -80.3],
		TelPref: "1 345",
		CountryCodes: {
			tld: "ky",
			iso3: "CYM",
			iso2: "KY",
			fips: "CJ",
			isoN: 136,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ky.html",
	},
	SG: {
		Name: "Singapore",
		Capital: {
			DLST: "null",
			TD: 8.0,
			Flg: 2,
			Name: "Singapore",
			GeoPt: [1.17, 103.51],
		},
		GeoRectangle: {
			West: 103.638259888,
			East: 104.007476807,
			North: 1.47127819061,
			South: 1.25855576992,
		},
		SeqID: 192,
		GeoPt: [1.22, 103.48],
		TelPref: "65",
		CountryCodes: {
			tld: "sg",
			iso3: "SGP",
			iso2: "SG",
			fips: "SN",
			isoN: 702,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/sg.html",
	},
	SE: {
		Name: "Sweden",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Stockholm",
			GeoPt: [59.2, 18.03],
		},
		GeoRectangle: {
			West: 11.1186923981,
			East: 24.1608924866,
			North: 69.0625076294,
			South: 55.3371047974,
		},
		SeqID: 191,
		GeoPt: [62.0, 15.0],
		TelPref: "46",
		CountryCodes: {
			tld: "se",
			iso3: "SWE",
			iso2: "SE",
			fips: "SW",
			isoN: 752,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/se.html",
	},
	SD: {
		Name: "Sudan",
		Capital: {
			DLST: "null",
			TD: 3.0,
			Flg: 2,
			Name: "Khartoum",
			GeoPt: [15.36, 32.32],
		},
		GeoRectangle: {
			West: 21.8389434814,
			East: 38.5800361633,
			North: 23.1468925476,
			South: 3.48638963699,
		},
		SeqID: 190,
		GeoPt: [15.0, 30.0],
		TelPref: "249",
		CountryCodes: {
			tld: "sd",
			iso3: "SDN",
			iso2: "SD",
			fips: "SU",
			isoN: 736,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/sd.html",
	},
	DO: {
		Name: "Dominican Republic",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "Santo Domingo",
			GeoPt: [18.28, -69.54],
		},
		GeoRectangle: {
			West: -72.0035018921,
			East: -68.3199920654,
			North: 19.9298610687,
			South: 17.5431556702,
		},
		SeqID: 59,
		GeoPt: [19.0, -70.4],
		TelPref: "1 809",
		CountryCodes: {
			tld: "do",
			iso3: "DOM",
			iso2: "DO",
			fips: "DR",
			isoN: 214,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/do.html",
	},
	DM: {
		Name: "Dominica",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "Roseau",
			GeoPt: [15.18, -61.24],
		},
		GeoRectangle: {
			West: -61.4841156006,
			East: -61.2441444397,
			North: 15.6318101883,
			South: 15.2016887665,
		},
		SeqID: 58,
		GeoPt: [15.25, -61.2],
		TelPref: "1 767",
		CountryCodes: {
			tld: "dm",
			iso3: "DMA",
			iso2: "DM",
			fips: "DO",
			isoN: 212,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/dm.html",
	},
	DJ: {
		Name: "Djibouti",
		Capital: {
			DLST: "null",
			TD: 3.0,
			Flg: 2,
			Name: "Djibouti",
			GeoPt: [11.35, 43.09],
		},
		GeoRectangle: {
			West: 41.7734680176,
			East: 43.4169769287,
			North: 12.7068347931,
			South: 10.9099159241,
		},
		SeqID: 56,
		GeoPt: [11.3, 43.0],
		TelPref: "253",
		CountryCodes: {
			tld: "dj",
			iso3: "DJI",
			iso2: "DJ",
			fips: "DJ",
			isoN: 262,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/dj.html",
	},
	DK: {
		Name: "Denmark",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Copenhagen",
			GeoPt: [55.4, 12.35],
		},
		GeoRectangle: {
			West: 8.07560920715,
			East: 15.1588354111,
			North: 57.74842453,
			South: 54.5623817444,
		},
		SeqID: 57,
		GeoPt: [56.0, 10.0],
		TelPref: "45",
		CountryCodes: {
			tld: "dk",
			iso3: "DNK",
			iso2: "DK",
			fips: "DA",
			isoN: 208,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/dk.html",
	},
	VG: {
		Name: "British Virgin Islands",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "Road Town",
			GeoPt: [18.27, -64.37],
		},
		GeoRectangle: {
			West: -64.7153778076,
			East: -64.2687530518,
			North: 18.7572231293,
			South: 18.3899765015,
		},
		SeqID: 230,
		GeoPt: [18.3, -64.3],
		TelPref: "1 284",
		CountryCodes: {
			tld: "vg",
			iso3: "VGB",
			iso2: "VG",
			fips: "VI",
			isoN: 92,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/vg.html",
	},
	DE: {
		Name: "Germany",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Berlin",
			GeoPt: [52.31, 13.24],
		},
		GeoRectangle: {
			West: 5.86563825607,
			East: 15.0398902893,
			North: 55.0556411743,
			South: 47.2757720947,
		},
		SeqID: 55,
		GeoPt: [51.0, 9.0],
		TelPref: "49",
		CountryCodes: {
			tld: "de",
			iso3: "DEU",
			iso2: "DE",
			fips: "GM",
			isoN: 276,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/de.html",
	},
	YE: {
		Name: "Yemen",
		Capital: {
			DLST: "null",
			TD: 3.0,
			Flg: 2,
			Name: "Sanaa",
			GeoPt: [15.21, 44.12],
		},
		GeoRectangle: {
			West: 42.5325241089,
			East: 54.5305328369,
			North: 19.0023365021,
			South: 12.1110811234,
		},
		SeqID: 236,
		GeoPt: [15.0, 48.0],
		TelPref: "967",
		CountryCodes: {
			tld: "ye",
			iso3: "YEM",
			iso2: "YE",
			fips: "YM",
			isoN: 887,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ye.html",
	},
	DZ: {
		Name: "Algeria",
		Capital: {
			DLST: "null",
			TD: 1.0,
			Flg: 2,
			Name: "Algiers",
			GeoPt: [36.45, 3.03],
		},
		GeoRectangle: {
			West: -8.673869133,
			East: 11.979549408,
			North: 37.0937271118,
			South: 18.9600257874,
		},
		SeqID: 60,
		GeoPt: [28.0, 3.0],
		TelPref: "213",
		CountryCodes: {
			tld: "dz",
			iso3: "DZA",
			iso2: "DZ",
			fips: "AG",
			isoN: 12,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/dz.html",
	},
	US: {
		Name: "United States",
		Capital: {
			DLST: 1.0,
			TD: -5.0,
			Flg: 2,
			Name: "Washington, DC",
			GeoPt: [38.53, -77.02],
		},
		GeoRectangle: {
			West: -124.733261108,
			East: -66.9547958374,
			North: 49.3886184692,
			South: 24.5442428589,
		},
		SeqID: 224,
		GeoPt: [38.0, -97.0],
		TelPref: "1",
		CountryCodes: {
			tld: "us",
			iso3: "USA",
			iso2: "US",
			fips: "US",
			isoN: 840,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/us.html",
	},
	UY: {
		Name: "Uruguay",
		Capital: {
			DLST: 1.0,
			TD: -3.0,
			Flg: 2,
			Name: "Montevideo",
			GeoPt: [-34.53, -56.11],
		},
		GeoRectangle: {
			West: -58.4427261353,
			East: -53.073928833,
			North: -30.0822219849,
			South: -34.9808235168,
		},
		SeqID: 225,
		GeoPt: [-33.0, -56.0],
		TelPref: "598",
		CountryCodes: {
			tld: "uy",
			iso3: "URY",
			iso2: "UY",
			fips: "UY",
			isoN: 858,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/uy.html",
	},
	YT: {
		Name: "Mayotte",
		Capital: {
			DLST: "null",
			TD: 3.0,
			Flg: 2,
			Name: "Mamoudzou",
			GeoPt: [-12.46, 45.13],
		},
		GeoRectangle: {
			West: 45.0379524231,
			East: 45.2929573059,
			North: -12.6488895416,
			South: -13.0001335144,
		},
		SeqID: 237,
		GeoPt: [-12.5, 45.1],
		TelPref: "262",
		CountryCodes: {
			tld: "yt",
			iso3: "MYT",
			iso2: "YT",
			fips: "MF",
			isoN: 175,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/yt.html",
	},
	LB: {
		Name: "Lebanon",
		Capital: {
			DLST: 1.0,
			TD: 2.0,
			Flg: 2,
			Name: "Beirut",
			GeoPt: [33.52, 35.3],
		},
		GeoRectangle: {
			West: 35.1142730713,
			East: 36.6391983032,
			North: 34.6914253235,
			South: 33.053855896,
		},
		SeqID: 123,
		GeoPt: [33.5, 35.5],
		TelPref: "961",
		CountryCodes: {
			tld: "lb",
			iso3: "LBN",
			iso2: "LB",
			fips: "LE",
			isoN: 422,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/lb.html",
	},
	LC: {
		Name: "Saint Lucia",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "Castries",
			GeoPt: [14.01, -61.0],
		},
		GeoRectangle: {
			West: -61.0741539001,
			East: -60.8741989136,
			North: 14.1032466888,
			South: 13.7047767639,
		},
		SeqID: 124,
		GeoPt: [13.53, -60.58],
		TelPref: "1 758",
		CountryCodes: {
			tld: "lc",
			iso3: "LCA",
			iso2: "LC",
			fips: "ST",
			isoN: 662,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/lc.html",
	},
	LA: {
		Name: "Laos",
		Capital: {
			DLST: "null",
			TD: 7.0,
			Flg: 2,
			Name: "Vientiane",
			GeoPt: [17.58, 102.36],
		},
		GeoRectangle: {
			West: 100.093048096,
			East: 107.697036743,
			North: 22.5003910065,
			South: 13.9100255966,
		},
		SeqID: 122,
		GeoPt: [18.0, 105.0],
		TelPref: "856",
		CountryCodes: {
			tld: "la",
			iso3: "LAO",
			iso2: "LA",
			fips: "LA",
			isoN: 418,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/la.html",
	},
	TV: {
		Name: "Tuvalu",
		Capital: {
			DLST: "null",
			TD: 12.0,
			Flg: 2,
			Name: "Funafuti",
			GeoPt: [-8.3, 179.12],
		},
		GeoRectangle: {
			West: 176.118972778,
			East: 178.699478149,
			North: -5.66874933243,
			South: -7.49436187744,
		},
		SeqID: 219,
		GeoPt: [-8.0, 178.0],
		TelPref: "688",
		CountryCodes: {
			tld: "tv",
			iso3: "TUV",
			iso2: "TV",
			fips: "TV",
			isoN: 798,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/tv.html",
	},
	TW: {
		Name: "Taiwan",
		Capital: {
			DLST: "null",
			TD: 8.0,
			Flg: 2,
			Name: "Taipei",
			GeoPt: [25.03, 121.3],
		},
		GeoRectangle: {
			West: 119.534683228,
			East: 122.000457764,
			North: 25.2982521057,
			South: 21.9018039703,
		},
		SeqID: 220,
		GeoPt: [23.3, 121.0],
		TelPref: "886",
		CountryCodes: {
			tld: "tw",
			iso3: "TWN",
			iso2: "TW",
			fips: "TW",
			isoN: 158,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/tw.html",
	},
	TT: {
		Name: "Trinidad and Tobago",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "Port-of-Spain",
			GeoPt: [10.39, -61.31],
		},
		GeoRectangle: {
			West: -61.9237785339,
			East: -60.5179290771,
			North: 11.3383436203,
			South: 10.0361032486,
		},
		SeqID: 218,
		GeoPt: [11.0, -61.0],
		TelPref: "1 868",
		CountryCodes: {
			tld: "tt",
			iso3: "TTO",
			iso2: "TT",
			fips: "TD",
			isoN: 780,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/tt.html",
	},
	TR: {
		Name: "Turkey",
		Capital: {
			DLST: 1.0,
			TD: 2.0,
			Flg: 2,
			Name: "Ankara",
			GeoPt: [39.56, 32.52],
		},
		GeoRectangle: {
			West: 25.6684989929,
			East: 44.8350028992,
			North: 42.1076202393,
			South: 35.815410614,
		},
		SeqID: 217,
		GeoPt: [39.0, 35.0],
		TelPref: "90",
		CountryCodes: {
			tld: "tr",
			iso3: "TUR",
			iso2: "TR",
			fips: "TU",
			isoN: 792,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/tr.html",
	},
	LK: {
		Name: "Sri Lanka",
		Capital: {
			DLST: "null",
			TD: 5.5,
			Flg: 2,
			Name: "Colombo",
			GeoPt: [6.56, 79.51],
		},
		GeoRectangle: {
			West: 79.6529083252,
			East: 81.8812942505,
			North: 9.8313627243,
			South: 5.91683244705,
		},
		SeqID: 126,
		GeoPt: [7.0, 81.0],
		TelPref: "94",
		CountryCodes: {
			tld: "lk",
			iso3: "LKA",
			iso2: "LK",
			fips: "CE",
			isoN: 144,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/lk.html",
	},
	LI: {
		Name: "Liechtenstein",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Vaduz",
			GeoPt: [47.08, 9.31],
		},
		GeoRectangle: {
			West: 9.47780323029,
			East: 9.63219642639,
			North: 47.2735328674,
			South: 47.0558547974,
		},
		SeqID: 125,
		GeoPt: [47.16, 9.32],
		TelPref: "423",
		CountryCodes: {
			tld: "li",
			iso3: "LIE",
			iso2: "LI",
			fips: "LS",
			isoN: 438,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/li.html",
	},
	LV: {
		Name: "Latvia",
		Capital: {
			DLST: 1.0,
			TD: 2.0,
			Flg: 2,
			Name: "Riga",
			GeoPt: [56.57, 24.06],
		},
		GeoRectangle: {
			West: 20.9742736816,
			East: 28.2411689758,
			North: 58.0823135376,
			South: 55.6688537598,
		},
		SeqID: 131,
		GeoPt: [57.0, 25.0],
		TelPref: "371",
		CountryCodes: {
			tld: "lv",
			iso3: "LVA",
			iso2: "LV",
			fips: "LG",
			isoN: 428,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/lv.html",
	},
	TO: {
		Name: "Tonga",
		Capital: {
			DLST: "null",
			TD: 13.0,
			Flg: 2,
			Name: "Nuku'alofa",
			GeoPt: [-21.08, -175.12],
		},
		GeoRectangle: {
			West: -175.682281494,
			East: -173.907562256,
			North: -15.5629863739,
			South: -21.4550590515,
		},
		SeqID: 216,
		GeoPt: [-20.0, -175.0],
		TelPref: "676",
		CountryCodes: {
			tld: "to",
			iso3: "TON",
			iso2: "TO",
			fips: "TN",
			isoN: 776,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/to.html",
	},
	LT: {
		Name: "Lithuania",
		Capital: {
			DLST: 1.0,
			TD: 2.0,
			Flg: 2,
			Name: "Vilnius",
			GeoPt: [54.41, 25.19],
		},
		GeoRectangle: {
			West: 20.9415245056,
			East: 26.8719463348,
			North: 56.4469223022,
			South: 53.9012985229,
		},
		SeqID: 129,
		GeoPt: [56.0, 24.0],
		TelPref: "370",
		CountryCodes: {
			tld: "lt",
			iso3: "LTU",
			iso2: "LT",
			fips: "LH",
			isoN: 440,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/lt.html",
	},
	LU: {
		Name: "Luxembourg",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Luxembourg",
			GeoPt: [49.36, 6.07],
		},
		GeoRectangle: {
			West: 5.73455524445,
			East: 6.52847290039,
			North: 50.1849479675,
			South: 49.4465789795,
		},
		SeqID: 130,
		GeoPt: [49.45, 6.1],
		TelPref: "352",
		CountryCodes: {
			tld: "lu",
			iso3: "LUX",
			iso2: "LU",
			fips: "LU",
			isoN: 442,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/lu.html",
	},
	LR: {
		Name: "Liberia",
		Capital: {
			DLST: "null",
			TD: 0.0,
			Flg: 2,
			Name: "Monrovia",
			GeoPt: [6.18, -10.48],
		},
		GeoRectangle: {
			West: -11.4920845032,
			East: -7.36511230469,
			North: 8.55179214478,
			South: 4.35305643082,
		},
		SeqID: 127,
		GeoPt: [6.3, -9.3],
		TelPref: "231",
		CountryCodes: {
			tld: "lr",
			iso3: "LBR",
			iso2: "LR",
			fips: "LI",
			isoN: 430,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/lr.html",
	},
	LS: {
		Name: "Lesotho",
		Capital: {
			DLST: "null",
			TD: 2.0,
			Flg: 2,
			Name: "Maseru",
			GeoPt: [-29.19, 27.29],
		},
		GeoRectangle: {
			West: 27.0290660858,
			East: 29.465763092,
			North: -28.5720558167,
			South: -30.6689662933,
		},
		SeqID: 128,
		GeoPt: [-29.3, 28.3],
		TelPref: "266",
		CountryCodes: {
			tld: "ls",
			iso3: "LSO",
			iso2: "LS",
			fips: "LT",
			isoN: 426,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ls.html",
	},
	TH: {
		Name: "Thailand",
		Capital: {
			DLST: "null",
			TD: 7.0,
			Flg: 2,
			Name: "Bangkok",
			GeoPt: [13.45, 100.31],
		},
		GeoRectangle: {
			West: 97.3456268311,
			East: 105.639396667,
			North: 20.4631977081,
			South: 5.60999917984,
		},
		SeqID: 210,
		GeoPt: [15.0, 100.0],
		TelPref: "66",
		CountryCodes: {
			tld: "th",
			iso3: "THA",
			iso2: "TH",
			fips: "TH",
			isoN: 764,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/th.html",
	},
	TF: {
		Name: "French Southern Territories",
		Capital: null,
		GeoRectangle: {
			West: 50.1702537537,
			East: 77.598815918,
			North: -37.7907180786,
			South: -49.7351913452,
		},
		SeqID: 208,
		GeoPt: [-37.5, 77.32],
		TelPref: "569",
		CountryCodes: {
			tld: "tf",
			iso3: "ATF",
			iso2: "TF",
			fips: "FS",
			isoN: 260,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/tf.html",
	},
	TG: {
		Name: "Togo",
		Capital: {
			DLST: "null",
			TD: 0.0,
			Flg: 2,
			Name: "Lome",
			GeoPt: [6.08, 1.13],
		},
		GeoRectangle: {
			West: -0.147324025631,
			East: 1.8066931963,
			North: 11.1389780045,
			South: 6.10441637039,
		},
		SeqID: 209,
		GeoPt: [8.0, 1.1],
		TelPref: "228",
		CountryCodes: {
			tld: "tg",
			iso3: "TGO",
			iso2: "TG",
			fips: "TO",
			isoN: 768,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/tg.html",
	},
	TD: {
		Name: "Chad",
		Capital: {
			DLST: "null",
			TD: 1.0,
			Flg: 2,
			Name: "N'Djamena",
			GeoPt: [12.06, 15.02],
		},
		GeoRectangle: {
			West: 13.4734735489,
			East: 24.002664566,
			North: 23.4503726959,
			South: 7.44106721878,
		},
		SeqID: 207,
		GeoPt: [15.0, 19.0],
		TelPref: "235",
		CountryCodes: {
			tld: "td",
			iso3: "TCD",
			iso2: "TD",
			fips: "CD",
			isoN: 148,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/td.html",
	},
	TC: {
		Name: "Turks and Caicos Islands",
		Capital: {
			DLST: 1.0,
			TD: -5.0,
			Flg: 2,
			Name: "Grand Turk",
			GeoPt: [21.28, -71.08],
		},
		GeoRectangle: {
			West: -72.4838790894,
			East: -71.1236343384,
			North: 21.9618816376,
			South: 21.4226226807,
		},
		SeqID: 206,
		GeoPt: [21.45, -71.35],
		TelPref: "1 649",
		CountryCodes: {
			tld: "tc",
			iso3: "TCA",
			iso2: "TC",
			fips: "TK",
			isoN: 796,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/tc.html",
	},
	LY: {
		Name: "Libya",
		Capital: {
			DLST: "null",
			TD: 2.0,
			Flg: 2,
			Name: "Tripoli",
			GeoPt: [32.53, 13.1],
		},
		GeoRectangle: {
			West: 9.38701820374,
			East: 25.1506156921,
			North: 33.1690063477,
			South: 19.5080413818,
		},
		SeqID: 132,
		GeoPt: [25.0, 17.0],
		TelPref: "218",
		CountryCodes: {
			tld: "ly",
			iso3: "LBY",
			iso2: "LY",
			fips: "LY",
			isoN: 434,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ly.html",
	},
	VA: {
		Name: "Holy See",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Vatican City",
			GeoPt: [41.54, 12.27],
		},
		GeoRectangle: {
			West: 12.4457054138,
			East: 12.4583768845,
			North: 41.9074440002,
			South: 41.9002723694,
		},
		SeqID: 227,
		GeoPt: [41.54, 12.27],
		TelPref: "39",
		CountryCodes: {
			tld: "va",
			iso3: "VAT",
			iso2: "VA",
			fips: "VT",
			isoN: 336,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/va.html",
	},
	VC: {
		Name: "Saint Vincent and the Grenadines",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "Kingstown",
			GeoPt: [13.09, -61.14],
		},
		GeoRectangle: {
			West: -61.4592590332,
			East: -61.1138725281,
			North: 13.3778352737,
			South: 12.5810098648,
		},
		SeqID: 228,
		GeoPt: [13.15, -61.12],
		TelPref: "1 784",
		CountryCodes: {
			tld: "vc",
			iso3: "VCT",
			iso2: "VC",
			fips: "VC",
			isoN: 670,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/vc.html",
	},
	AE: {
		Name: "United Arab Emirates",
		Capital: {
			DLST: "null",
			TD: 4.0,
			Flg: 2,
			Name: "Abu Dhabi",
			GeoPt: [24.28, 54.22],
		},
		GeoRectangle: {
			West: 51.5833244324,
			East: 56.3816642761,
			North: 26.0841617584,
			South: 22.6333274841,
		},
		SeqID: 1,
		GeoPt: [24.0, 54.0],
		TelPref: "971",
		CountryCodes: {
			tld: "ae",
			iso3: "ARE",
			iso2: "AE",
			fips: "AE",
			isoN: 784,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ae.html",
	},
	AD: {
		Name: "Andorra",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Andorra la Vella",
			GeoPt: [42.3, 1.31],
		},
		GeoRectangle: {
			West: 1.42211079597,
			East: 1.78038918972,
			North: 42.6586990356,
			South: 42.4350738525,
		},
		SeqID: 0,
		GeoPt: [42.3, 1.3],
		TelPref: "376",
		CountryCodes: {
			tld: "ad",
			iso3: "AND",
			iso2: "AD",
			fips: "AN",
			isoN: 20,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ad.html",
	},
	AG: {
		Name: "Antigua and Barbuda",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "Saint John's",
			GeoPt: [17.07, -61.51],
		},
		GeoRectangle: {
			West: -61.9064292908,
			East: -61.672416687,
			North: 17.7293891907,
			South: 16.9969768524,
		},
		SeqID: 3,
		GeoPt: [17.03, -61.48],
		TelPref: "1 268",
		CountryCodes: {
			tld: "ag",
			iso3: "ATG",
			iso2: "AG",
			fips: "AC",
			isoN: 28,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ag.html",
	},
	AF: {
		Name: "Afghanistan",
		Capital: {
			DLST: "null",
			TD: 4.5,
			Flg: 2,
			Name: "Kabul",
			GeoPt: [34.31, 69.11],
		},
		GeoRectangle: {
			West: 60.4784355164,
			East: 74.8794631958,
			North: 38.4834251404,
			South: 29.3774700165,
		},
		SeqID: 2,
		GeoPt: [33.0, 65.0],
		TelPref: "93",
		CountryCodes: { tld: "af", iso3: "AFG", iso2: "AF", fips: "AF", isoN: 4 },
		CountryInfo: "http://www.geognos.com/geo/en/cc/af.html",
	},
	AI: {
		Name: "Anguilla",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "The Valley",
			GeoPt: [18.13, -63.03],
		},
		GeoRectangle: {
			West: -63.1729049683,
			East: -62.9713516235,
			North: 18.2834262848,
			South: 18.1668128967,
		},
		SeqID: 4,
		GeoPt: [18.15, -63.1],
		TelPref: "1 264",
		CountryCodes: {
			tld: "ai",
			iso3: "AIA",
			iso2: "AI",
			fips: "AV",
			isoN: 660,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ai.html",
	},
	VI: {
		Name: "Virgin Islands",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "Charlotte Amalie",
			GeoPt: [18.21, -64.56],
		},
		GeoRectangle: {
			West: -65.0382461548,
			East: -64.5651702881,
			North: 18.3917503357,
			South: 17.681722641,
		},
		SeqID: 231,
		GeoPt: [18.2, -64.5],
		TelPref: "1 340",
		CountryCodes: {
			tld: "vi",
			iso3: "VIR",
			iso2: "VI",
			fips: "VQ",
			isoN: 850,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/vi.html",
	},
	IS: {
		Name: "Iceland",
		Capital: {
			DLST: "null",
			TD: 0.0,
			Flg: 2,
			Name: "Reykjavik",
			GeoPt: [64.09, -21.57],
		},
		GeoRectangle: {
			West: -24.5465259552,
			East: -13.4958133698,
			North: 66.5346450806,
			South: 63.393245697,
		},
		SeqID: 105,
		GeoPt: [65.0, -18.0],
		TelPref: "354",
		CountryCodes: {
			tld: "is",
			iso3: "ISL",
			iso2: "IS",
			fips: "IC",
			isoN: 352,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/is.html",
	},
	IR: {
		Name: "Iran",
		Capital: {
			DLST: "null",
			TD: 3.5,
			Flg: 2,
			Name: "Tehran",
			GeoPt: [35.4, 51.25],
		},
		GeoRectangle: {
			West: 44.0472717285,
			East: 63.3174781799,
			North: 39.7772293091,
			South: 25.0640792847,
		},
		SeqID: 104,
		GeoPt: [32.0, 53.0],
		TelPref: "98",
		CountryCodes: {
			tld: "ir",
			iso3: "IRN",
			iso2: "IR",
			fips: "IR",
			isoN: 364,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ir.html",
	},
	AM: {
		Name: "Armenia",
		Capital: {
			DLST: 1.0,
			TD: 4.0,
			Flg: 2,
			Name: "Yerevan",
			GeoPt: [40.1, 44.3],
		},
		GeoRectangle: {
			West: 43.4497756958,
			East: 49.4783973694,
			North: 41.3018379211,
			South: 38.3970527649,
		},
		SeqID: 6,
		GeoPt: [40.0, 45.0],
		TelPref: "374",
		CountryCodes: {
			tld: "am",
			iso3: "ARM",
			iso2: "AM",
			fips: "AM",
			isoN: 51,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/am.html",
	},
	AL: {
		Name: "Albania",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Tirana",
			GeoPt: [41.19, 19.49],
		},
		GeoRectangle: {
			West: 19.2939682007,
			East: 21.0684757233,
			North: 42.6656150818,
			South: 39.6483535767,
		},
		SeqID: 5,
		GeoPt: [41.0, 20.0],
		TelPref: "355",
		CountryCodes: { tld: "al", iso3: "ALB", iso2: "AL", fips: "AL", isoN: 8 },
		CountryInfo: "http://www.geognos.com/geo/en/cc/al.html",
	},
	AO: {
		Name: "Angola",
		Capital: {
			DLST: "null",
			TD: 1.0,
			Flg: 2,
			Name: "Luanda",
			GeoPt: [-8.5, 13.14],
		},
		GeoRectangle: {
			West: 11.6792173386,
			East: 24.0821228027,
			North: -4.37682533264,
			South: -18.0420780182,
		},
		SeqID: 8,
		GeoPt: [-12.3, 18.3],
		TelPref: "244",
		CountryCodes: {
			tld: "ao",
			iso3: "AGO",
			iso2: "AO",
			fips: "AO",
			isoN: 24,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ao.html",
	},
	AN: {
		Name: "Netherlands Antilles",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "Willemstad",
			GeoPt: [12.06, -68.56],
		},
		GeoRectangle: {
			West: -69.1572189331,
			East: -68.1922988892,
			North: 12.3856735229,
			South: 12.0171480179,
		},
		SeqID: 7,
		GeoPt: [12.12, -68.15],
		TelPref: "599",
		CountryCodes: {
			tld: "an",
			iso3: "ANT",
			iso2: "AN",
			fips: "NT",
			isoN: 530,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/an.html",
	},
	AQ: {
		Name: "Antarctica",
		Capital: null,
		GeoRectangle: {
			West: -179.999923706,
			East: 179.999923706,
			North: -60.5155258179,
			South: -89.9999084473,
		},
		SeqID: 10,
		GeoPt: [-90.0, 0.0],
		TelPref: "672",
		CountryCodes: {
			tld: "aq",
			iso3: "ATA",
			iso2: "AQ",
			fips: "AY",
			isoN: 10,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/aq.html",
	},
	AP: {
		Name: "Asia & Pacific",
		Capital: null,
		GeoRectangle: { West: 0.0, East: 0.0, North: 0.0, South: 0.0 },
		SeqID: 9,
		GeoPt: [0.0, 0.0],
		TelPref: null,
		CountryCodes: {
			tld: "asia",
			iso3: "N/A",
			iso2: "AP",
			fips: "N/A",
			isoN: 0,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ap.html",
	},
	AS: {
		Name: "American Samoa",
		Capital: {
			DLST: "null",
			TD: -11.0,
			Flg: 2,
			Name: "Pago Pago",
			GeoPt: [-14.16, -170.42],
		},
		GeoRectangle: {
			West: -170.841339111,
			East: -169.416061401,
			North: -14.162115097,
			South: -14.3824796677,
		},
		SeqID: 12,
		GeoPt: [-14.2, -170.0],
		TelPref: "1 684",
		CountryCodes: {
			tld: "as",
			iso3: "ASM",
			iso2: "AS",
			fips: "AQ",
			isoN: 16,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/as.html",
	},
	AR: {
		Name: "Argentina",
		Capital: {
			DLST: 1.0,
			TD: -3.0,
			Flg: 2,
			Name: "Buenos Aires",
			GeoPt: [-34.36, -58.4],
		},
		GeoRectangle: {
			West: -73.5829849243,
			East: -53.5918273926,
			North: -21.7812747955,
			South: -55.0613212585,
		},
		SeqID: 11,
		GeoPt: [-34.0, -64.0],
		TelPref: "54",
		CountryCodes: {
			tld: "ar",
			iso3: "ARG",
			iso2: "AR",
			fips: "AR",
			isoN: 32,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ar.html",
	},
	AU: {
		Name: "Australia",
		Capital: {
			DLST: 1.0,
			TD: 10.0,
			Flg: 2,
			Name: "Canberra",
			GeoPt: [-35.17, 149.13],
		},
		GeoRectangle: {
			West: 112.911048889,
			East: 153.639282227,
			North: -10.0628032684,
			South: -43.6439743042,
		},
		SeqID: 14,
		GeoPt: [-27.0, 133.0],
		TelPref: "61",
		CountryCodes: {
			tld: "au",
			iso3: "AUS",
			iso2: "AU",
			fips: "AS",
			isoN: 36,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/au.html",
	},
	AT: {
		Name: "Austria",
		Capital: {
			DLST: 1.0,
			TD: 1.0,
			Flg: 2,
			Name: "Vienna",
			GeoPt: [48.12, 16.22],
		},
		GeoRectangle: {
			West: 9.53591442108,
			East: 17.1627254486,
			North: 49.0170631409,
			South: 46.3780250549,
		},
		SeqID: 13,
		GeoPt: [47.2, 13.2],
		TelPref: "43",
		CountryCodes: {
			tld: "at",
			iso3: "AUT",
			iso2: "AT",
			fips: "AU",
			isoN: 40,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/at.html",
	},
	AW: {
		Name: "Aruba",
		Capital: {
			DLST: "null",
			TD: -4.0,
			Flg: 2,
			Name: "Oranjestad",
			GeoPt: [12.31, -70.02],
		},
		GeoRectangle: {
			West: -70.0611419678,
			East: -69.8668518066,
			North: 12.6306190491,
			South: 12.4060916901,
		},
		SeqID: 15,
		GeoPt: [12.3, -69.58],
		TelPref: "297",
		CountryCodes: {
			tld: "aw",
			iso3: "ABW",
			iso2: "AW",
			fips: "AA",
			isoN: 533,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/aw.html",
	},
	IN: {
		Name: "India",
		Capital: {
			DLST: "null",
			TD: 5.5,
			Flg: 2,
			Name: "New Delhi",
			GeoPt: [28.36, 77.12],
		},
		GeoRectangle: {
			West: 68.1866760254,
			East: 97.4033126831,
			North: 35.5042304993,
			South: 6.74713850021,
		},
		SeqID: 101,
		GeoPt: [20.0, 77.0],
		TelPref: "91",
		CountryCodes: {
			tld: "in",
			iso3: "IND",
			iso2: "IN",
			fips: "IN",
			isoN: 356,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/in.html",
	},
	TZ: {
		Name: "Tanzania",
		Capital: {
			DLST: "null",
			TD: 3.0,
			Flg: 2,
			Name: "Dar es Salaam",
			GeoPt: [-6.48, 39.17],
		},
		GeoRectangle: {
			West: 29.3271656036,
			East: 40.4432258606,
			North: -0.990735888481,
			South: -11.7456970215,
		},
		SeqID: 221,
		GeoPt: [-6.0, 35.0],
		TelPref: "255",
		CountryCodes: {
			tld: "tz",
			iso3: "TZA",
			iso2: "TZ",
			fips: "TZ",
			isoN: 834,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/tz.html",
	},
	AZ: {
		Name: "Azerbaijan",
		Capital: {
			DLST: 1.0,
			TD: 4.0,
			Flg: 2,
			Name: "Baku",
			GeoPt: [40.23, 49.52],
		},
		GeoRectangle: {
			West: 44.7741088867,
			East: 50.3700904846,
			North: 41.9056472778,
			South: 38.820186615,
		},
		SeqID: 16,
		GeoPt: [40.3, 47.3],
		TelPref: "994",
		CountryCodes: {
			tld: "az",
			iso3: "AZE",
			iso2: "AZ",
			fips: "AJ",
			isoN: 31,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/az.html",
	},
	IE: {
		Name: "Ireland",
		Capital: {
			DLST: 1.0,
			TD: 0.0,
			Flg: 2,
			Name: "Dublin",
			GeoPt: [53.19, -6.14],
		},
		GeoRectangle: {
			West: -10.4785575867,
			East: -6.00238847733,
			North: 55.3879241943,
			South: 51.4515800476,
		},
		SeqID: 98,
		GeoPt: [53.0, -8.0],
		TelPref: "353",
		CountryCodes: {
			tld: "ie",
			iso3: "IRL",
			iso2: "IE",
			fips: "EI",
			isoN: 372,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ie.html",
	},
	ID: {
		Name: "Indonesia",
		Capital: {
			DLST: "null",
			TD: 7.0,
			Flg: 2,
			Name: "Jakarta",
			GeoPt: [-6.1, 106.49],
		},
		GeoRectangle: {
			West: 95.0093231201,
			East: 141.021835327,
			North: 5.9044175148,
			South: -10.9418621063,
		},
		SeqID: 97,
		GeoPt: [-5.0, 120.0],
		TelPref: "62",
		CountryCodes: {
			tld: "id",
			iso3: "IDN",
			iso2: "ID",
			fips: "ID",
			isoN: 360,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/id.html",
	},
	UA: {
		Name: "Ukraine",
		Capital: null,
		GeoRectangle: {
			West: 22.1288852692,
			East: 40.2073974609,
			North: 52.3693695068,
			South: 44.3904075623,
		},
		SeqID: 222,
		GeoPt: [49.0, 32.0],
		TelPref: "380",
		CountryCodes: {
			tld: "ua",
			iso3: "UKR",
			iso2: "UA",
			fips: "UP",
			isoN: 804,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/ua.html",
	},
	QA: {
		Name: "Qatar",
		Capital: {
			DLST: "null",
			TD: 3.0,
			Flg: 2,
			Name: "Doha",
			GeoPt: [25.17, 51.32],
		},
		GeoRectangle: {
			West: 50.7572135925,
			East: 51.6366462708,
			North: 26.1547241211,
			South: 24.4829406738,
		},
		SeqID: 182,
		GeoPt: [25.3, 51.15],
		TelPref: "974",
		CountryCodes: {
			tld: "qa",
			iso3: "QAT",
			iso2: "QA",
			fips: "QA",
			isoN: 634,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/qa.html",
	},
	MZ: {
		Name: "Mozambique",
		Capital: {
			DLST: "null",
			TD: 2.0,
			Flg: 2,
			Name: "Maputo",
			GeoPt: [-25.57, 32.35],
		},
		GeoRectangle: {
			West: 30.2173156738,
			East: 40.8430023193,
			North: -10.4718818665,
			South: -26.8686885834,
		},
		SeqID: 154,
		GeoPt: [-18.15, 35.0],
		TelPref: "258",
		CountryCodes: {
			tld: "mz",
			iso3: "MOZ",
			iso2: "MZ",
			fips: "MZ",
			isoN: 508,
		},
		CountryInfo: "http://www.geognos.com/geo/en/cc/mz.html",
	},
};

export default countryList;
