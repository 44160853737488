import { apiSlice } from "../../apiSlice";

const organizationApi = apiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getOrganizationsInfo: builder.query({
			query: () => "/org-info",
			providesTags: ["org-info"],
		}),
		acceptOrganization: builder.mutation({
			query: (data) => ({
				url: "/accept-org",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["org-info"],
		}),
		rejectOrganization: builder.mutation({
			query: (data) => ({
				url: "/reject-org",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["org-info"],
		}),
	}),
});

export const {
	useGetOrganizationsInfoQuery,
	useAcceptOrganizationMutation,
	useRejectOrganizationMutation,
} = organizationApi;
