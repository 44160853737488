// icons
import { HiCursorClick } from "react-icons/hi";
import { Link } from "react-router-dom";
export default function ProcessType({ type, icon, title, description, href }) {
	return (
		<Link
			to={href}
			className="flex flex-col border rounded-xl transition-all duration-300
        cursor-pointer relative overflow-hidden"
		>
			<div
				className="absolute z-50 bg-gray-200/10 glassmorphism top-0 left-0 w-full h-full transition-all duration-300
            hover:opacity-100 flex items-center justify-center flex-col gap-4 text-center px-8 md:px-6 lg:px-4 opacity-0"
			>
				<HiCursorClick className="animate-pulse duration-500 absolute top-4 left-4" />
				<span className="text-[6rem] text-primary">{icon}</span>
				<h2 className="text-lg font-semibold text-primary">{title}</h2>
			</div>

			<aside
				className="bg-gray-100 p-4 flex flex-col items-center justify-center 
                "
			>
				<p className="text-2xl font-bold text-gray-500 uppercase">{type}</p>
				<span className="text-[6rem] text-gray-300 mt-6">{icon}</span>
			</aside>
			<div className="flex flex-col gap-2 py-6 px-2">
				<div className="text-center leading-8">
					<h2 className="text-xl font-semibold text-primary">{title}</h2>
					<p className="text-sm font-semibold text-blue-400">{description}</p>
				</div>
			</div>
		</Link>
	);
}
