const SecondaryInput = ({ id, name, label, type, value, setter, formik }) => {
	return (
		<div className="w-full flex flex-col">
			<label htmlFor={id} className="text-sm font-slate-900 mb-2 ml-1">
				{label}
			</label>
			<input
				name={name}
				value={value}
				onChange={setter}
				id={id}
				type={type || "text"}
				className="outline-none border rounded-lg w-full px-4 py-2 bg-transparent focus-within:border-slate-600
                            transition-all duration-300 appearance-none"
			/>
			{formik && formik?.touched[name] && formik?.errors[name] ? (
				<span className="text-xs font-semibold text-system-error ml-2">
					{formik?.errors[name]}
				</span>
			) : null}
		</div>
	);
};


export default SecondaryInput;