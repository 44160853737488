import { useMemo, useEffect, useState } from "react";

import { AnimatePresence, motion } from "framer-motion";
import { BiChevronDown } from "react-icons/bi";

import countryList from "../Data/countryList";

export default function CountryDropDown({ name, value, setter }) {
	const [search, setSearch] = useState("");
	const [data, setData] = useState(null);
	const [optionIsOpen, setOptionIsOpen] = useState(false);

	useEffect(() => {
		setData(Object.entries(countryList));
	}, []);

	useMemo(() => {
		const result = Object.entries(countryList).filter((item) =>
			item[1].Name.toLowerCase().includes(search.trim().toLowerCase()),
		);
		setData(result);
	}, [search]);

	return (
		<div>
			<p className="text-sm mb-1 ml-1 text-slate-900">Country</p>
			<div
				onClick={() => setOptionIsOpen((prev) => !prev)}
				className="px-4 py-2 border-2 bg-white rounded-lg flex items-center justify-between gap-12 relative"
			>
				<p>{value}</p>
				<BiChevronDown
					className={`text-2xl order-2 ${
						optionIsOpen && "rotate-180"
					} transition-all duration-500`}
				/>

				<AnimatePresence>
					{optionIsOpen && (
						<motion.div
							initial={{ opacity: 0.4, scale: 0.9 }}
							animate={{ opacity: 1, scale: 1 }}
							exit={{ opacity: 0, scale: 0.9 }}
							className="absolute left-0 right-0 top-12 z-50 h-64 overflow-y-scroll flex flex-col gap-1 
                            bg-slate-300 rounded-lg"
						>
							<input
								autoFocus
								onClick={(e) => e.stopPropagation()}
								value={search}
								onChange={(e) => {
									setSearch(e.target.value);
								}}
								type="text"
								className="mx-1 my-2 self-center w-[95%] px-4 py-2 rounded-lg outline-none border-2 border-transparent
                                glassmorphism bg-slate-100/80 placeholder:text-slate-400 focus:border-slate-300 
                                transition-all duration-300"
								placeholder="search country ..."
							/>
							{data.map((country) => (
								<p
									key={country[1].SeqID}
									onClick={(e) => {
										setter(name, e.target.innerText);
									}}
									className="py-2 px-4 hover:bg-slate-200 cursor-pointer transition-all duration-300"
								>
									{country[1].Name}
								</p>
							))}
						</motion.div>
					)}
				</AnimatePresence>
			</div>
		</div>
	);
}
