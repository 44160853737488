const userSales = [
	{
		id: 1,
		username: "Admin",
		roles: "SusaAdmin",
		count: 400,
	},
	{
		id: 2,
		username: "UserTest1",
		roles: "Admin",
		count: 700,
	},
	{
		id: 3,
		username: "UserTest2",
		roles: "User",
		count: 600,
	},
	{
		id: 4,
		username: "UserTest3",
		roles: "User",
		count: 800,
	},
	{
		id: 5,
		username: "Admin",
		roles: "User",
		count: 340,
	},
	{
		id: 6,
		username: "Admin",
		roles: "User",
		count: 290,
	},
	{
		id: 7,
		username: "Admin",
		roles: "User",
		count: 550,
	},
	{
		id: 8,
		username: "Admin",
		roles: "Admin",
		count: 720,
	},
];

export default userSales;
