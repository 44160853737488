import { evoteApiSlice } from "../../evoteApiSlice";

const evoteApi = evoteApiSlice.injectEndpoints({
	endpoints: (builder) => ({
		getVotableItems: builder.query({
			query: () => "/getvotableItem",
			providesTags: ["votableItem"],
			transformResponse: (res) => res.reverse(),
		}),
		startSingleElection: builder.mutation({
			query: (data) => ({
				url: "/single-election",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["elections"],
		}),
		startMultipleElection: builder.mutation({
			query: (data) => ({
				url: "/multiple-elections",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["elections"],
		}),
		resultRevealSingle: builder.mutation({
			query: (data) => ({
				url: "/result-reveal-single-election",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["elections"],
		}),
		resultRevealMultiple: builder.mutation({
			query: (data) => ({
				url: "/result-reveal-multiple-election",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["elections"],
		}),
		addVotableItem: builder.mutation({
			query: (data) => ({
				url: "/addvotableItem",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["votableItem"],
		}),
		getElections: builder.query({
			query: () => "/getelections",
			providesTags: ["elections"],
			transformResponse: (res) => res.reverse(),
		}),
	}),
});

export const {
	useStartSingleElectionMutation,
	useStartMultipleElectionMutation,
	useResultRevealSingleMutation,
	useResultRevealMultipleMutation,
	useAddVotableItemMutation,
	useGetVotableItemsQuery,
	useGetElectionsQuery,
} = evoteApi;
