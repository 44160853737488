import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { logOut, setCredentials } from "./authSlice";
import Cookies from "js-cookie";

const baseQuery = fetchBaseQuery({
	baseUrl: "https://susa6.com/api",
	credentials: "include",
	prepareHeaders: (headers) => {
		const token = Cookies.get("accessToken");
		if (token) {
			headers.set("authorization", `Bearer ${token}`);
		}
		return headers;
	},
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
	let result = await baseQuery(args, api, extraOptions);
	if (result?.error?.originalStatus === 403) {
		const refreshResult = await baseQuery("/refresh", api, extraOptions);
		if (refreshResult?.data) {
			Cookies.set("accessToken", refreshResult.data.accessToken);
			api.dispatch(setCredentials({ ...refreshResult.data }));
			result = await baseQuery(args, api, extraOptions);
		} else {
			api.dispatch(logOut());
		}
	}

	return result;
};

export const apiSlice = createApi({
	reducerPath: "api",
	baseQuery: baseQueryWithReauth,
	tagTypes: ["Balance", "retired"],
	endpoints: () => ({}),
});
