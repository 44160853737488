import { useSelector } from "react-redux";
import { useGetCreditsQuery } from "../../app/services/api/credit";
import { useGetBalanceQuery } from "../../app/services/api/governance/balance";
import CardBadge from "../UiElements/CardBadge";

import { CiWallet } from "react-icons/ci";
import { AiOutlineUser } from "react-icons/ai";
import { currentUser } from "../../app/services/authSlice";

// tooltip
import { Tooltip } from "react-tooltip";

export default function BadgeCards() {
	const user = useSelector(currentUser);

	const { data, isLoading, error } = useGetBalanceQuery(user.username);

	const { data: credits, isLoading: creditLoading } = useGetCreditsQuery(
		undefined,
		{
			refetchOnMountOrArgChange: true,
		},
	);

	return (
		<div className="grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 md:gap-4 gap-2">
			{user.userKyc === "" && (
				<p
					data-tooltip-id={"KYC"}
					data-tooltip-content={
						"you need to kyc your account to get more access like Issue"
					}
					data-tooltip-variant="success"
					className="fixed bottom-4 right-4 bg-secondary w-12 h-12 flex items-center justify-center
				 rounded-full text-green-100 cursor-pointer shadow-xl shadow-secondary/30"
				>
					KYC
				</p>
			)}
			{user?.roles === "Admin" || user?.roles === "SusaAdmin" ? (
				<CardBadge
					colorTheme="green"
					title="Governance Token Balance"
					count={data?.balance}
					type="GT"
					loading={isLoading}
					error={error}
					icon={<CiWallet />}
				/>
			) : user.roles === "User" ? (
				user.userKyc !== "" ? (
					<CardBadge
						colorTheme="green"
						title="Total Forsale Offset"
						count={
							credits?.filter((credit) => credit.status === "FORSALE").length
						}
						type="forsale"
						loading={creditLoading}
						icon={<CiWallet />}
					/>
				) : null
			) : null}

			{user.roles === "User" ? (
				user.userKyc !== "" ? (
					<CardBadge
						title="Total Issued Offset"
						count={
							credits?.filter(
								(credit) =>
									credit.status === "ISSUED" || credit.status === "FORSALE",
							).length
						}
						type="issued"
						loading={creditLoading}
						icon={<CiWallet />}
					/>
				) : null
			) : null}

			<CardBadge
				colorTheme="yellow"
				title="Total Purchased Offset"
				count={
					credits?.filter((credit) => credit.stauts === "PURCHASED").length
				}
				type="issued"
				loading={creditLoading}
				icon={<CiWallet />}
			/>

			<CardBadge
				colorTheme="red"
				title="Total Retired Offset"
				count={credits?.filter((credit) => credit.status === "RETIRED").length}
				type="issued"
				loading={creditLoading}
				icon={<CiWallet />}
			/>

			{user?.roles === "SusaAdmin" ? (
				<>
					<CardBadge
						colorTheme="transparent"
						title="Maximum Retired Offset"
						count={
							credits?.filter((credit) => credit.status === "RETIRED").length
						}
						type="issued"
						loading={creditLoading}
						icon={<CiWallet />}
					/>

					<CardBadge
						colorTheme="transparent"
						title="Maximum Issue Offset"
						count={
							credits?.filter((credit) => credit.status === "RETIRED").length
						}
						type="issued"
						loading={creditLoading}
						icon={<CiWallet />}
					/>

					<CardBadge
						colorTheme="transparent"
						title="Maximum User Sales"
						count={"userTest71"}
						type=""
						loading={creditLoading}
						icon={<AiOutlineUser />}
					/>

					<CardBadge
						colorTheme="transparent"
						title="Maximum User Purchase"
						count={"Admin"}
						type=""
						loading={creditLoading}
						icon={<AiOutlineUser />}
					/>
				</>
			) : null}
			<Tooltip
				id={"KYC"}
				place="top"
				style={{
					borderRadius: "10px",
					zIndex: 500,
					backgroundColor: "#474747",
				}}
			/>
		</div>
	);
}
